import { Box, Fab, IconButton, Icon } from "@mui/material";
import React, { useRef } from "react";
import UploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";


function FileInput({ onImageSelected, chooseImage, reference }) {
  // const inputRef = useRef();

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  // const onChooseImg = () => {
  //   inputRef.current.click();
  // };

  return (
    <div>
      <input
        type="file"
        accept="image/*,video/mp4,video/x-m4v,video/*"
        ref={reference}
        onChange={handleOnChange}
        style={{ display: "none" }}
     //   multiple={ismultiple}
      />
  <Box
                    sx={{ mr: 2, ml: 2, zIndex: 1, position:"relative" }}
                    // onClick={handleUploadClick}
                    onClick={chooseImage}
                    
                  >
                    <Fab size="small" component="span" aria-label="add">
                      <UploadOutlinedIcon />
                      
                    </Fab>
                   
                  </Box>
      {/* <button className="btn" onClick={chooseImage}>
        Choose Image
      </button> */}
    </div>
  );
}

export default FileInput;