import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as Icon from "@mui/icons-material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

const sideBarItems = [
  {
    link: "/dashboard",
    label: "Dashboard",
    icon: <Icon.Dashboard />,
  },
  {
    link: "/business",
    label: "Business Page",
    icon: <Icon.PhoneAndroid />,
  },
  {
    link: "",
    label: "QR Code",
    icon: <Icon.QrCode />,
  },
  {
    link: "",
    label: "What’App Marketing",
    icon: <Icon.QuestionAnswerOutlined />,
  },
  {
    link: "",
    label: "Email Marketing",
    icon: <Icon.MailOutlineOutlined />,
  },
  {
    link: "",
    label: "Tutorials",
    icon: <Icon.InfoOutlined />,
  },
  {
    link: "",
    label: "Support ",
    icon: <Icon.HeadsetMicOutlined />,
  },
];
const SidebarItems = ({ currentItem }) => {
  return (
    <React.Fragment>
      <List>
        <ListItem style={{ width: "100%" }}>
          <Link to="/getstarted" style={{ width: "100%" }}>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#000",
                color: "#fff",
                width: "100%",
                margin: "48px 0",
              }}
            >
              Get Started
            </Button>
          </Link>
        </ListItem>
      </List>

      <List>
        {sideBarItems.map((item, index) => {
          currentItem =
            currentItem === "/" ? currentItem + "dashboard" : currentItem;

          return (
            <Link
              to={item.link}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
              key={"sidebar_" + index}
            >
              <ListItemButton selected={currentItem === item.link}>
                <ListItemIcon sx={{ minWidth: "unset", mr: 2 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </Link>
          );
        })}
        <Divider variant="middle" />
      </List>
    </React.Fragment>
  );
};
export default SidebarItems;
