import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import googleIcon from "../../../../assets/images/google_icon.svg";
import fbIcon from "../../../../assets/images/fb_icon.svg";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import VerefiedYellowTick from "../../../../assets/images/verified_yellow.svg";

const Signin = ({ openSignIn, setOpenSignin, setOpenSignup }) => {
  const [signInOpen, setOpen] = React.useState(false);
  const [forgotPasswordOpen, setForgotPassword] = React.useState(false);
  const [flag, setFlag] = React.useState(0);

  const handleSigninForgotPassword = () => {
    setOpenSignin(true);
    setForgotPassword(false);
  };

  const handleSignupForgotPassword = () => {
    setOpenSignup(true);
    setForgotPassword(false);
  };

  const handleForgotPasswordClick = () => {
    setOpen(false); // Close the current modal
    setForgotPassword(true); // Open the "Forgot Password" modal
    setOpenSignin(false); //Close the sign in modal
  };

  const handleClose = () => {
    setOpenSignin(false);
  };

  const handleForgotPasswordClose = () => {
    setForgotPassword(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleSigninForgotPassword}>
        Sign In
      </Button> */}
      <Dialog
        open={openSignIn}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1" textAlign="center">
            Sign In
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              style={{
                marginBottom: 20,
                marginTop: 20,
                paddingInline: 0,
              }}
            >
              <Container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img src={googleIcon} alt="Google Icon"></img>
                <Typography variant="subtitle1" style={{ margin: "auto" }}>
                  Sign up with Google
                </Typography>
              </Container>
            </Button>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              style={{
                marginBottom: 20,
                paddingInline: 0,
              }}
            >
              <Container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img src={fbIcon} alt="Facebook Icon"></img>
                <Typography variant="subtitle1" style={{ margin: "auto" }}>
                  Sign up with Facebook
                </Typography>
              </Container>
            </Button>
            <Typography
              variant="subtitle1"
              textAlign="center"
              style={{
                marginBottom: 20,
              }}
            >
              or
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              style={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              style={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />
            <Link
              href="#"
              underline="none"
              color="inherit"
              textAlign="right"
              onClick={handleForgotPasswordClick}
            >
              <Typography
                variant="body1"
                style={{
                  marginBottom: 17,
                }}
              >
                Forgot Password?
              </Typography>
            </Link>
            <Button
              fullWidth
              size="large"
              sx={{
                color: "#fff",
                background: "#000",
                marginBottom: "24px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid black",
                  color: "black",
                },
              }}
            >
              <Typography variant="subtitle1">Sign In</Typography>
            </Button>
            <Link
              href="#"
              underline="none"
              color="inherit"
              textAlign="center"
              sx={{
                marginBottom: "32px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  marginBottom: 32,
                }}
              >
                Already have an account?
              </Typography>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            // onClick={(handleForgotPasswordClose, setFlag(0))}
            onClick={() => {
              handleForgotPasswordClose();
              setFlag(0);
            }}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1" textAlign="center">
            Forgot Password
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          {flag === 0 ? (
            <Box
              dividers
              sx={{
                width: 500,
              }}
            >
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginTop: 0,
                    marginBottom: 20,
                    borderRadius: 8,
                  }}
                />
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    color: "#fff",
                    background: "#000",
                    marginBottom: "24px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={() => {
                    setFlag(1);
                  }}
                >
                  <Typography variant="subtitle1">Send Code</Typography>
                </Button>

                <Container
                  sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                >
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSignupForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                  |
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSigninForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign In
                    </Typography>
                  </Link>
                </Container>
              </div>
            </Box>
          ) : flag === 1 ? (
            <Box
              dividers
              sx={{
                width: 500,
              }}
            >
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="verificationcode"
                  label="Verification Code"
                  type="number"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginTop: 0,
                    marginBottom: 20,
                    borderRadius: 8,
                  }}
                />
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    color: "#fff",
                    background: "#000",
                    marginBottom: "24px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={() => {
                    setFlag(2);
                  }}
                >
                  <Typography variant="subtitle1">Submit</Typography>
                </Button>
                <Container
                  sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                >
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSignupForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                  |
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSigninForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign In
                    </Typography>
                  </Link>
                </Container>
              </div>
            </Box>
          ) : flag === 2 ? (
            <Box
              dividers
              sx={{
                width: 500,
              }}
            >
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="newpassword"
                  label="New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginTop: 0,
                    marginBottom: 20,
                    borderRadius: 8,
                  }}
                />
                <TextField
                  margin="dense"
                  id="confirmpassword"
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginTop: 0,
                    marginBottom: 20,
                    borderRadius: 8,
                  }}
                />
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    color: "#fff",
                    background: "#000",
                    marginBottom: "24px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={() => {
                    setFlag(3);
                  }}
                >
                  <Typography variant="subtitle1">Update</Typography>
                </Button>
                <Container
                  sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                >
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSignupForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                  |
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSigninForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign In
                    </Typography>
                  </Link>
                </Container>
              </div>
            </Box>
          ) : flag === 3 ? (
            <Box
              dividers
              sx={{
                width: 500,
              }}
            >
              <div>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    marginBottom: "60px",
                  }}
                >
                  <img
                    src={VerefiedYellowTick}
                    alt="Verfied Yellow Tick"
                    style={{ width: 60, height: "auto", marginBottom: "20px" }}
                  />
                  <Typography variant="h5">
                    Password changed successfully!
                  </Typography>
                </Container>
                <Container
                  sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                >
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSignupForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                  |
                  <Link
                    href="#"
                    underline="none"
                    color="inherit"
                    textAlign="center"
                    onClick={() => {
                      handleSigninForgotPassword();
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: 23,
                      }}
                    >
                      Sign In
                    </Typography>
                  </Link>
                </Container>
              </div>
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Signin;
