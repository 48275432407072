import { Box,  Divider, Grid, IconButton, Paper, Switch, TextField, Typography } from "@mui/material";

import * as Icon from '@mui/icons-material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const ContactUs = () => {
    return (
        <>

            <Paper sx={{  mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{xs:2, md:3}}>
                    <Box className="pagecomp_header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ mr: 2 }} >
                                <Icon.ArrowBack />
                            </IconButton>
                            Contact Us
                        </Box>
                        <Box>
                            {/* <Icon.MoreVert sx={{ ml: 2 }} /> */}
                            <Switch {...label} defaultChecked />
                            <IconButton sx={{ ml: 2 }} >
                                <Icon.DeleteOutline />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="pagecomp_content">

                        <TextField fullWidth id="contact_heading" label="Heading" variant="outlined" sx={{ mb: 4, background: '#fff !important' }} />
                        <TextField fullWidth id="contact_des" label="Description" variant="outlined" sx={{ mb: 4, background: '#fff !important' }} />

                        <Divider sx={{ mb: 4 }} />

                        <Typography variant='h6'>Contact Details</Typography>


                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Box sx={{ mr: 2 }}>
                                    <IconButton>
                                            <Icon.LocalPhoneOutlined />
                                        </IconButton>
                                    </Box>
                                    <TextField fullWidth id="mobilenumber" label="mobile number" variant="outlined" sx={{ background: '#fff !important' }} />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Box sx={{ mr: 2 }}>
                                    <IconButton>
                                            <Icon.EmailOutlined />
                                        </IconButton>
                                    </Box>
                                    <TextField fullWidth id="emailid" label="Email ID" variant="outlined" sx={{ background: '#fff !important' }} />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Divider sx={{ mt: 4, mb: 4 }} />

                        <Typography variant='h6' sx={{ mb: 2 }}>Google Map</Typography>
                        <TextField fullWidth id="googlemaplink" label="Google Map Link" variant="outlined" sx={{ background: '#fff !important' }} />

                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt:2, mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Box sx={{ mr: 2 }}>
                                        <IconButton>
                                            <Icon.MapOutlined />
                                        </IconButton>
                                    </Box>
                                    <TextField fullWidth id="directionlink" label="Direction link" variant="outlined" sx={{ background: '#fff !important' }} />
                                </Grid>
                            </Grid>
                        </Paper>


                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>

        </>
    );
};


export default ContactUs;