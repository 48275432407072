import { UserAuth } from "../store/context/user";
import { Navigate } from "react-router";

export const ProtectedRoute = ({ children }) => {
    const { user } = UserAuth;
    return children;
    if (!user) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return children;
  };