import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

// routing
import Routes from './routes';

// defaultTheme
import theme from './theme';

// project imports
import NavigationScroll from './layouts/NavigationScroll';
import { CssBaseline } from '@mui/material';


// ==============================|| APP ||============================== //

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;