import * as React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Slide, Slider, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, Tab, Tabs } from "@mui/material";


import * as Icon from '@mui/icons-material'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const Offer = () => {
    const [open, setOpen] = React.useState(false);
    const [addoffer, setAddOffer] = React.useState(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenAddOffer = () => {
        setAddOffer(true);
    };

    const handleCloseAddOffer = () => {
        setAddOffer(false);
    };
    return (
        <>

            <Paper sx={{ mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{ xs: 2, md: 3 }}>
                    <Box className="pagecomp_header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ mr: 2 }} >
                                <Icon.ArrowBack />
                            </IconButton>
                            Offers
                        </Box>
                        <Box>
                            {/* <Icon.MoreVert sx={{ ml: 2 }} /> */}
                            <Switch {...label} defaultChecked />
                            <IconButton sx={{ ml: 2 }} >
                                <Icon.DeleteOutline />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="pagecomp_content">

                        <Grid container sx={{ alignItems: 'center', display: "flex", alignItems: "center", mb: 4 }}>
                            <Grid item md={9} sx={{ alignItems: 'center', display: 'flex' }}>
                                <Typography sx={{ mt: 1, mb: 2 }} variant='subtitle'> Total average daily customers</Typography>
                            </Grid>
                            <Grid item md={3} sx={{ alignItems: 'center', display: 'flex' }}>
                                <TextField fullWidth type="number" id="offer_heading" label="" variant="outlined" sx={{ background: '#fff !important' }} />
                                <Icon.Group sx={{ ml: 2 }} />
                            </Grid>
                        </Grid>
                        <Typography>Offers Contributions</Typography>

                        <Paper sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ mr: 2 }}>
                                            <img src="/assets/images/dummybuilder/offer_product_1.png" />
                                        </Box>
                                        <Box>
                                            Offer name (Free item)
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                        <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" sx={{ mr: 2 }} />
                                        <TextField fullWidth type="number" id="offer_heading" label="" variant="outlined" sx={{ background: '#fff !important' }} />

                                        <IconButton sx={{ ml: 2 }} >
                                            <Icon.KeyboardArrowDownOutlined />
                                        </IconButton>

                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="offer_des_more">
                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                    <Chip label="Products" variant="outlined" />
                                    <Chip label="All customers" variant="outlined" />
                                    <Chip label="Min purchase : Rs.120" variant="outlined" />
                                </Box>
                                <Box sx={{ display: 'flex', gap: '8px', mt: 3 }}>
                                    <Box>Display <Switch {...label} defaultChecked /></Box>
                                    <IconButton onClick={handleClickOpen}>
                                        <Icon.Edit />
                                    </IconButton>
                                    <IconButton>
                                        <Icon.DeleteOutlineOutlined />
                                    </IconButton>
                                    <IconButton>
                                        <Icon.DateRange />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Paper>

                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition} >

                            <DialogTitle>
                                <IconButton sx={{ mr: 2 }} onClick={handleClose}>
                                    <Icon.Close />
                                </IconButton>
                                Free Item Offer</DialogTitle>
                            <DialogContent dividers sx={{ background: '#F7F8F3' }}>
                                <Grid container>
                                    <Grid item xs={12} md={7}>
                                        <Paper sx={{ p: 3 }}>
                                            <Typography variant='h6' sx={{ mb: 3 }}>Free item scratch card</Typography>
                                            <TextField fullWidth id="contact_heading" label="Heading" variant="outlined" sx={{ background: '#fff !important' }} />
                                            <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 4, mb: 4 }}>
                                                <Grid container sx={{ alignItems: 'center' }}>
                                                    <Grid item md={3} xs={12} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} mb={{ md: '0px', xs: 3 }}>
                                                        <Box className="img_thumb img_thumb_circle active">
                                                            <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                                            <IconButton style={{ position: "absolute", bottom: '-8px', right: '-8px', background: '#000', color: '#fff' }} >
                                                                <Icon.CameraAltOutlined />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={9} xs={12}>
                                                        <TextField fullWidth id="ftr_c2a_text_1" label="Heading" variant="outlined" sx={{ background: '#fff !important' }} />
                                                        <TextField fullWidth id="ftr_c2a_offerlink" label="Descriptions" variant="outlined" sx={{ background: '#fff !important', mt: 3 }} />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                            <Grid container>
                                                <Grid md={12} sx={{ display: 'flex', gap: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Applicable for</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"

                                                            label="Applicable for"
                                                        >
                                                            <MenuItem value={10}>All Customers</MenuItem>
                                                            <MenuItem value={20}>New Customers</MenuItem>
                                                            <MenuItem value={30}>Existing Custoemrs</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    <TextField fullWidth id="ftr_c2a_offerlink" label="Descriptions" variant="outlined" sx={{ background: '#fff !important' }} />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <FormControlLabel
                                            value="collectdata"
                                            control={<Checkbox />}
                                            label="Collect customer data"
                                            labelPlacement="collectdata"
                                            sx={{ mt: 4 }}
                                        />
                                        <Paper sx={{ p: 3, mt: 3 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                                <Typography variant='h6'>Form</Typography>
                                                <IconButton>
                                                    <Icon.ArrowForwardOutlined />
                                                </IconButton>
                                            </Box>
                                        </Paper>
                                        <Paper sx={{ p: 3, mt: 3 }}>
                                            <Box>
                                                <Typography variant='h6'>Offer customer flow</Typography>
                                                <Box sx={{ display: 'flex', gap: '30px', mt: 3 }}>
                                                    <Box className="PictureButton selected">
                                                    Scratch > Form > Reward > Payment
                                                    </Box>
                                                    <Box className="PictureButton">
                                                    Scratch > Form > Payment > Rewards
                                                    </Box>

                                                </Box>
                                            </Box>

                                        </Paper>
                                        <Paper sx={{ p: 3, mt: 4 }}>
                                            <Box>
                                                <Typography variant='h6'>Footer action</Typography>
                                                <TextField fullWidth id="offer_btn_text" label="button text" variant="outlined" sx={{ background: '#fff !important' }} />

                                            </Box>

                                        </Paper>
                                        <Paper sx={{ p: 3, mt: 4 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                                <Typography variant='h6'>Design and Style</Typography>
                                                <IconButton>
                                                    <Icon.ArrowForwardOutlined />
                                                </IconButton>
                                            </Box>

                                        </Paper>
                                    </Grid>
                                    <Grid className="mobile_col" item xs={12} sm={12} md={5} sx={{ textAlign: 'center' }} display={{ md: 'block', xs: 'none' }}>
                                        {/* mobile */}

                                        <Box display={{ md: 'none', xs: 'block', }} sx={{ margin: 'auto', position: 'fixed', bottom: '20px', left: '30%' }}>
                                            <Button variant="contained" sx={{ color: '#fff' }} >
                                                <Icon.RemoveRedEye sx={{ mr: 2 }} /> Preview
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography>Preview</Typography>
                                            </Box>
                                        </Box>

                                        <Box className="mobile_preview_wrapper" sx={{ top: "0px !important" }}>

                                            <Box className="mob_border">
                                                <Box className="mob_pg_wrapper" sx={{ position: '', background: '#333', minHeight: '100%' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                                        <IconButton sx={{ background: '#fff' }}>
                                                            <Icon.Close />
                                                        </IconButton>
                                                    </Box>

                                                    <Typography variant='h5' sx={{ color: '#fff', mt: 4, mb: 4 }}>Heading</Typography>
                                                    <Box className="prev_scard">
                                                        <img src='/assets/images/dummybuilder/offer_product_1.png' width={140} />
                                                        <Typography variant='h5' sx={{ fontWeight: '600' }}>Congratulations!</Typography>
                                                        <Typography> Your WIN “PRODUCT NAME” </Typography>
                                                    </Box>
                                                    <Typography variant='caption' sx={{ color: '#fff' }}>Minimum order value : Rs.120 *</Typography>
                                                    <Box sx={{ mt: 3 }}>
                                                        <Button variant="contained" sx={{ color: '#fff' }}>
                                                            Pay Bill & Get your Reward
                                                        </Button>

                                                    </Box>



                                                </Box>
                                            </Box>
                                        </Box>



                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={addoffer}
                            onClose={handleCloseAddOffer}
                            TransitionComponent={Transition} >

                            <DialogTitle>
                                <IconButton sx={{ mr: 2 }} onClick={handleCloseAddOffer}>
                                    <Icon.Close />
                                </IconButton>
                                Offers</DialogTitle>
                            <DialogContent dividers sx={{ background: '#F7F8F3' }}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Tabs value={value} onChange={handleChange} centered>
                                            <Tab label="Free Item" icon={<Icon.Redeem />} iconPosition="start" />
                                            <Tab label="Discount" icon={<Icon.Percent />} iconPosition="start" />
                                        </Tabs>
                                        {value == 0 &&
                                            <Box sx={{ mt: 4 }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <Box className="boder1" sx={{ p: 3, display: "flex", gap: "24px", flexWrap: 'wrap', textAlign: "left" }}>
                                                            <div>
                                                                <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                                            </div>
                                                            <div>
                                                                <Typography variant='h6' sx={{ mb: 1 }}>Item scratch card</Typography>
                                                                <Typography variant='title' sx={{ mb: 1 }}>Grow your business with an offer</Typography>
                                                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                                    <Chip label="Item" variant="outlined" />
                                                                    <Chip label="All customers" variant="outlined" />
                                                                    <Chip label="Min purchase : Rs.120" variant="outlined" />
                                                                </Box>

                                                                <Button size='large' color="primary" variant="contained" sx={{ color: '#fff', mt: 3, fontWeight: "bold" }} onClick={handleClickOpenAddOffer}>Edit & Add Offer</Button>


                                                            </div>

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box className="boder1" sx={{ p: 3, display: "flex", gap: "24px", flexWrap: 'wrap', textAlign: "left" }}>
                                                            <div>
                                                                <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                                            </div>
                                                            <div>
                                                                <Typography variant='h6' sx={{ mb: 1 }}>Item scratch card</Typography>
                                                                <Typography variant='title' sx={{ mb: 1 }}>Grow your business with an offer</Typography>
                                                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                                    <Chip label="Item" variant="outlined" />
                                                                    <Chip label="All customers" variant="outlined" />
                                                                    <Chip label="Min purchase : NA" variant="outlined" />
                                                                </Box>

                                                                <Button size='large' color="primary" variant="contained" sx={{ color: '#fff', mt: 3, fontWeight: "bold" }} onClick={handleClickOpenAddOffer}>Edit & Add Offer</Button>


                                                            </div>

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box className="boder1" sx={{ p: 3, display: "flex", gap: "24px", flexWrap: 'wrap', textAlign: "left" }}>
                                                            <div>
                                                                <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                                            </div>
                                                            <div>
                                                                <Typography variant='h6' sx={{ mb: 1 }}>Item scratch card</Typography>
                                                                <Typography variant='title' sx={{ mb: 1 }}>Grow your business with an offer</Typography>
                                                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                                    <Chip label="Item" variant="outlined" />
                                                                    <Chip label="All customers" variant="outlined" />
                                                                    <Chip label="Min purchase : Rs.120" variant="outlined" />
                                                                </Box>

                                                                <Button size='large' color="primary" variant="contained" sx={{ color: '#fff', mt: 3, fontWeight: "bold" }} onClick={handleClickOpenAddOffer}>Edit & Add Offer</Button>


                                                            </div>

                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }

                                        {value == 1 &&
                                            <Box sx={{ mt: 4 }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <Box className="boder1" sx={{ p: 3, display: "flex", gap: "24px", flexWrap: 'wrap', textAlign: "left" }}>

                                                            <Box sx={{background:'#DDEF17', width:'136px', height:'136px', borderRadius: '16px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                <Typography variant='h6'>10% OFF</Typography>
                                                            </Box>

                                                            <div>
                                                                <Typography variant='h6' sx={{ mb: 1 }}>Scratch card</Typography>
                                                                <Typography variant='title' sx={{ mb: 1 }}>Grow your business with an offer</Typography>
                                                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                                    <Chip label="Discount" variant="outlined" />

                                                                </Box>

                                                                <Box sx={{ mt: 2 }}>In <strong>120</strong> scratch <strong>40</strong> customers will get <strong>10% off</strong></Box>

                                                                <Button size='large' color="primary" variant="contained" sx={{ color: '#fff', mt: 2, fontWeight: "bold" }} onClick={handleClickOpenAddOffer}>Edit & Add Offer</Button>


                                                            </div>

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>

                                                    </Grid>

                                                </Grid>
                                            </Box>
                                        }

                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <Paper sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ mr: 2 }}>
                                            <img src="/assets/images/dummybuilder/offer_discount_1.png" />
                                        </Box>
                                        <Box>
                                            10% OFF for new customer
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                        <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" sx={{ mr: 2 }} />
                                        <TextField fullWidth type="number" id="offer_heading" label="" variant="outlined" sx={{ background: '#fff !important' }} />

                                        <IconButton sx={{ ml: 2 }} >
                                            <Icon.KeyboardArrowDownOutlined />
                                        </IconButton>

                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="offer_des_more">
                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                    <Chip label="Products" variant="outlined" />
                                    <Chip label="All customers" variant="outlined" />
                                    <Chip label="Min purchase : Rs.120" variant="outlined" />
                                </Box>
                                <Box sx={{ display: 'flex', gap: '8px', mt: 3 }}>
                                    <Box>Display <Switch {...label} defaultChecked /></Box>
                                    <IconButton>
                                        <Icon.Edit />
                                    </IconButton>
                                    <IconButton>
                                        <Icon.DeleteOutlineOutlined />
                                    </IconButton>
                                    <IconButton>
                                        <Icon.DateRange />
                                    </IconButton>
                                </Box>
                            </Box>



                        </Paper>
                        <Paper sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ mr: 2 }}>
                                            <img src="/assets/images/dummybuilder/offer_betterluck_1.png" />
                                        </Box>
                                        <Box>
                                            Better Luck
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                        <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" sx={{ mr: 2 }} />
                                        <TextField fullWidth type="number" id="offer_heading" label="" variant="outlined" sx={{ background: '#fff !important' }} />

                                        <IconButton sx={{ ml: 2 }} >
                                            <Icon.KeyboardArrowDownOutlined />
                                        </IconButton>

                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="offer_des_more">
                                <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                    <Chip label="Products" variant="outlined" />
                                    <Chip label="All customers" variant="outlined" />
                                    <Chip label="Min purchase : Rs.120" variant="outlined" />
                                </Box>
                                <Box sx={{ display: 'flex', gap: '8px', mt: 3 }}>

                                    <IconButton>
                                        <Icon.Edit />
                                    </IconButton>

                                </Box>
                            </Box>



                        </Paper>

                        <Button color="primary" variant="contained" sx={{ color: '#fff' }} onClick={handleClickOpenAddOffer}>+  Add More Offer</Button>



                        <Divider sx={{ mb: 4, mt: 4 }}></Divider>
                        <TextField fullWidth id="offer_heading" label="Heading" variant="outlined" sx={{ background: '#fff !important', mb: 4 }} />
                        <TextField fullWidth id="offer_description" label="Description" variant="outlined" sx={{ background: '#fff !important', mb: 4 }} />
                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Typography sx={{ mt: 1, mb: 2 }} variant='subtitle'> Offer image</Typography>
                                </Grid>
                            </Grid>
                            <Box className="img_thumb active">
                                <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                <IconButton style={{ position: "absolute", bottom: '-8px', right: '-8px', background: '#000', color: '#fff' }} >
                                    <Icon.CameraAltOutlined />
                                </IconButton>
                            </Box>
                            <Grid container sx={{ alignItems: 'center', mb: 4 }}>
                                <Grid item md={4} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <div style={{ position: "relative", padding: "0px 12px 12px 0px" }}>

                                        <div >


                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Box className="img_thumb">
                                    <img src='/assets/images/dummybuilder/scratchcard_2.png' />
                                </Box>
                                <Box className="img_thumb">
                                    <img src='/assets/images/dummybuilder/scratchcard_3.png' />
                                </Box>
                                <Box className="img_thumb active">
                                    <img src='/assets/images/dummybuilder/scratchcard_1.png' />
                                </Box>
                                <Box className="img_thumb">
                                    <img src='/assets/images/dummybuilder/scratchcard_4.png' />
                                </Box>
                            </Box>

                        </Paper>

                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Typography variant='h6'>Format</Typography>
                        <Box sx={{ display: 'flex', gap: '30px' }}>
                            <Box className="PictureButton selected">
                                <img src='/assets/images/dummybuilder/offer_format.svg' />
                            </Box>
                            <Box className="PictureButton">
                                <img src='/assets/images/dummybuilder/offer_format_2.svg' />
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Typography variant='h6'>Animation</Typography>
                        <Box sx={{ display: 'flex', gap: '30px' }}>
                            <Box className="PictureButton selected">
                                <img src='/assets/images/dummybuilder/offer_animation_1.svg' />
                            </Box>
                            <Box className="PictureButton">
                                <img src='/assets/images/dummybuilder/offer_animation_2.svg' />
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'space-between' }}>
                            Card size
                            <ToggleButtonGroup>
                                <ToggleButton value="left" aria-label="left aligned">
                                    1:2
                                </ToggleButton>
                                <ToggleButton value="center" aria-label="centered">
                                    3:2
                                </ToggleButton>
                                <ToggleButton value="right" aria-label="right aligned">
                                    2:3
                                </ToggleButton>
                                <ToggleButton className="selected" value="right" aria-label="right aligned">
                                    16:9
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </Box>




                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>

        </>
    );
};


export default Offer;