import { Box, Container, Grid, Link, Typography } from "@mui/material"



const Footer = () => {
    return (
        <Box className="sph_ftr">
           <Grid container className="maxwidthlg" component="main" sx={{m:'auto'}}>

                <Box className="sph_ftr_logo_social" sx={{ p: 2 }}>
                    <Grid container maxWidth={'lg'} sx={{ m: 'auto' }}>
                        <Grid item xs={6} md={6}>
                            <img className='sph_ftr_splogo' src='/assets/images/homepage/spurtme_logo_white.png' />
                        </Grid>
                        <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Box className="sph_ftr_sociallinks">
                                <a href='#'><img src='/assets/images/homepage/fb.svg' /></a>
                                <a href='#'><img src='/assets/images/homepage/insta.svg' /></a>
                                <a href='#'><img src='/assets/images/homepage/linkedin.svg' /></a>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container className="maxwidthlg" sx={{ m: 'auto', p: 2 }}>
                    <Grid item xs={6} md={3}>
                        <Typography className='sph_ftr_heading'>
                            Create
                        </Typography>
                        <ul className='sph_ftr_links'>
                            <li><a href='#'>Business page</a></li>
                            <li><a href='#'>Business QR Code</a></li>
                            <li><a href='#'>Sell online products</a></li>
                            <li><a href='#'>Personal page</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography className='sph_ftr_heading'>
                            Learn More
                        </Typography>
                        <ul className='sph_ftr_links'>
                            <li><a href='#'>Blog</a></li>
                            <li><a href='#'>Pricing</a></li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography className='sph_ftr_heading'>
                            SpurtMe
                        </Typography>
                        <ul className='sph_ftr_links'>
                            <li><Link href='/aboutus'>About Us</Link></li>
                            <li><Link href='/contactus'>Contact Us</Link></li>
                        </ul>
                    </Grid>
                    <Grid item sx={6} md={5} className='sph_ftr_right'>
                        <Typography className='sph_ftr_heading'>
                            Legal
                        </Typography>
                        <ul className='sph_ftr_links'>
                            <li><a href='#'>Terms of service</a></li>
                            <li><a href='#'>Privacy Policy</a></li>
                            <li><a href='#'>Cookie Notice</a></li>
                        </ul>
                        <Typography variant='caption'>
                            Copyright © 2023 SpurtMe. All rights reserved.
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    )
}

export default Footer;