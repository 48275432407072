import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import googleIcon from "../../../../assets/images/google_icon.svg";
import fbIcon from "../../../../assets/images/fb_icon.svg";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

const Signup = ({ openSignUp, setOpenSignup }) => {
  const handleClose = () => {
    setOpenSignup(false);
  };

  return (
    <>
      <Dialog
        open={openSignUp}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1" textAlign="center">
            Sign Up
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              style={{
                marginBottom: 20,
                marginTop: 20,
                paddingInline: 0,
              }}
            >
              <Container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img src={googleIcon} alt="Google Icon"></img>
                <Typography variant="subtitle1" style={{ margin: "auto" }}>
                  Sign up with Google
                </Typography>
              </Container>
            </Button>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              style={{
                marginBottom: 20,
                paddingInline: 0,
              }}
            >
              <Container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img src={fbIcon} alt="Facebook Icon"></img>
                <Typography variant="subtitle1" style={{ margin: "auto" }}>
                  Sign up with Facebook
                </Typography>
              </Container>
            </Button>
            <Typography
              variant="subtitle1"
              textAlign="center"
              style={{
                marginBottom: 20,
              }}
            >
              or
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              style={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />
            <TextField
              required
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              style={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              style={{
                marginTop: 0,
                marginBottom: 20,
              }}
            />
            <FormControlLabel
              style={{
                marginBottom: 17,
              }}
              control={
                <Checkbox
                  defaultChecked
                  size="small"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
              }
              label={
                <>
                  <span>Agree with </span>
                  <Link href="" color="inherit">
                    Terms & Condition
                  </Link>
                </>
              }
            />
            <Button
              fullWidth
              size="large"
              sx={{
                color: "#fff",
                background: "#000",
                marginBottom: "24px",
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid black",
                  color: "black",
                },
              }}
            >
              <Typography variant="subtitle1">Sign Up</Typography>
            </Button>
            <Link href="#" underline="none" color="inherit" textAlign="center">
              <Typography
                variant="body1"
                style={{
                  marginBottom: 32,
                }}
              >
                Already have an account?
              </Typography>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Signup;
