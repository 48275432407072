export function deepCopy(aObject) {
    if (!aObject) {
        return aObject;
    }
    if (typeof aObject == "object") //the type of array too is object, but just in case
    {
        let v;
        let bObject = Array.isArray(aObject) ? [] : {};
        for (const k in aObject) {
            if (aObject.hasOwnProperty(k)) {
                v = aObject[k];
                bObject[k] = (typeof v === "object") ? deepCopy(v) : v;
            }
        }
        return bObject;
    }
    return aObject;
}

export function extractDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
    var data = defaultRetVal;
    try {
        if (!isEmpty(dataArr) && !isUndefined(paramsArr)) {
            data = dataArr;
            var len = paramsArr.length;
            for (var i = 0; i < len; ++i) {
                if (!isUndefined(paramsArr[i])) {
                    data = data[paramsArr[i]];
                }
            }
        }
        if (isUndefined(data)) {
            data = defaultRetVal;
        }
    }
    catch (e) {
        data = defaultRetVal;
    }
    return data;
}

// function extractEscapeHtmlDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
//     return escapeHTML(extractDataFromArray(dataArr, paramsArr, defaultRetVal));
// }

// function extractUnescapeHtmlDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
//     return unescapeHTML(extractDataFromArray(dataArr, paramsArr, defaultRetVal));
// }

export function setValueIfEmpty(variable, value) {
    if (empty(variable)) {
        variable = value
    }
    return variable;
}

export function isEmpty(str) {
    if (isUndefined(str)) return true;

    if (!empty(str)) {
        str += '';
        if (str === 'undefined') return true;
        str.replace(/^\s+|\s+$/g, '');
        return (str === '' || str === 0);
    }
    return true;
}

export function isUndefined(mixed_var) {
    if (typeof mixed_var == "undefined" || mixed_var === undefined || mixed_var === null) {
        return true;
    }
    mixed_var += '';
    if (mixed_var === 'undef' || mixed_var === 'undefined') return true;
    return false;
}

export function isDefined(mixed_var) {
    return !isUndefined(mixed_var);
}

export function empty(mixed_var) {
    // Checks if the argument variable is empty
    // undefined, null, false, number 0, empty string,
    // string "0", objects without properties and empty arrays
    // are considered empty
    var undef, key, i, len;
    var emptyValues = [undef, undefined, null, false, 0, "", "0"];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixed_var === emptyValues[i]) {
            return true;
        }
    }

    if (typeof mixed_var === "object") {
        for (key in mixed_var) {
            // TODO: should we check for own properties only?
            //if (mixed_var.hasOwnProperty(key)) {
            return false; //non-empty object or array
            //}
        }
        return true;
    }

    return false;
}

export function parseResponse(response){
    if (response === undefined || response == null || typeof response != "object") //beware null typeof is object
    {
        response = { errorCode: "-1", errorMsg: "Unknown Error, malformed response received.", html: "" };
    }

    if(extractDataFromArray(response, ['errorMsg'], '') === "RCP"){
        //Handle RCP
    }
    return response;
}

export function parseResponseAxios(response){
    if (response === undefined || response == null || typeof response != "object") //beware null typeof is object
    {
        response = { errorCode: "-1", errorMsg: "Unknown Error, malformed response received.", html: "" };
    }
    response = extractDataFromArray(response, ['data'], [])


    if(extractDataFromArray(response, ['errorMsg'], '') === "RCP"){
        //Handle RCP
    }
    return response;
}

export function getUrlParamByName(name){
    const queryParameters = new URLSearchParams(window.location.search)
    return queryParameters.get(name)
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}