import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {Tab, Tabs}  from "@mui/material";
import { Link } from "react-router-dom";
import * as Icon from "@mui/icons-material"

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - 0px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props) => {

  // const [value, setValue] = React.useState(0);

  //   const handleChange = (event, newValue) => {
  //       setValue(newValue);
  //   };
  const navItems = [{title:"Content", url:"/builder/content"}, {title:"Design",  url:"/builder/design"}, {title:"Analytics", url:"/builder/analytics"}, {title:"Settings", url:"/builder/settings"}]
  return (
    <AppBar
      sx={{ background: "#fff" }}
      position="absolute"
      open={props.openSignUp}
    >
      <Toolbar
        sx={{
          pr: "0px",
         
          justifyContent: "space-between"
           // keep right padding when drawer closed
        }}
      >
        {" "}
        <Box sx={{display:"flex", alignItems:"center"}}>
        <Link to="/">
          <Box sx={{ display: "flex", alignItems: "center" }}>
                <Icon.ArrowBack />
          </Box>
        </Link>
        
        <Box sx={{ ml : 2}}>
        <Tabs 
         value={props.value} 
         onChange={props.handleChange} 
        centered>
          {navItems.map((item) => {
         
            return (
              <Tab label={item.title}>
                <Button variant="text" sx={{ color: "#000" }}>{item.title}</Button>
              </Tab>
              
             
            );
          })}
          </Tabs>
         
        </Box>
        </Box>
        <Box >
        <Button variant="contained">Sign in</Button>
        </Box>
        
      </Toolbar>
    </AppBar>
  );
};

export default Header;
