import { Box, Divider, TextField, Button, ButtonGroup, Grid, IconButton, Paper, Switch, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { extractDataFromArray } from "../../../utils/common";
import { useState, useEffect, useRef } from "react";
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FileInput from "./fileInput";
import ImageCropper from "./imageCropper";

let croppedImage = "";
let setCroppedImage = "";

const img = {
    display: 'block',
    width: 48,
    height: 48,
    borderRadius: 4,
};

const PhotoGallery = ({ data, updateData, props }) => {

    
    const [componentDetails, setComponentDetails] = useState(data)
    const updateComponentDetails = (data) => {
        setComponentDetails({ ...componentDetails, ...data })
        updateData({ ...componentDetails, ...data })
    }

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const inputRef = useRef();
    const [isFileInputOpen, setFileInputOpen] = useState(false);
    const [isImageCropperOpen, setImageCropperOpen] = useState(false);
    const [croppedImage2, setCroppedImage2] = useState(null);
    croppedImage = croppedImage2;
    setCroppedImage = setCroppedImage2;
    const [files, setFiles] = useState([]);
    

    const handleDrop = (droppedItem) => {
       
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...componentDetails.files];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setFiles(updatedList);
        setComponentDetails({ files: updatedList });
        updateComponentDetails({ ...componentDetails, files: updatedList });

    };

    const handleImageSelected = (imageData) => {

       
        setSelectedImage(imageData);

        if (imageData) {
            console.log("Creating URL for Image Data");
            try {
                const imageUrl = URL.createObjectURL(imageData);
                console.log("Image URL:", imageUrl);
                setImageUrl(imageUrl);

            } catch (error) {
                console.error("Error creating URL for Image Data:", error);
            }
        }

        setFileInputOpen(false); 
        setImageCropperOpen(true);

    };

    const onChooseImg = () => {
        inputRef.current.click();
    };

    const onCropDone = (croppedImageData) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = croppedImageData.width;
        canvasEle.height = croppedImageData.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = selectedImage;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                croppedImageData.x,
                croppedImageData.y,
                croppedImageData.width,
                croppedImageData.height,
                0,
                0,
                croppedImageData.width,
                croppedImageData.height
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");

            const uploadedImage = { preview: dataURL }
            console.log("uploadedImage", uploadedImage);
            const thumbFiles = componentDetails.files;
            thumbFiles.push(uploadedImage);
           
            const backUpTempFiles = []
            backUpTempFiles.push(uploadedImage);

            setCroppedImage(dataURL);
        

            updateComponentDetails({ ...componentDetails, files: thumbFiles });

            setImageCropperOpen(false);
            

        };
    };



    const deleteLink = (index) => {
        const updatedData = [...componentDetails.files];
        updatedData.splice(index, 1);
        updateComponentDetails({ ...componentDetails, files: updatedData });
        setFiles([]);
    };


    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (<>

        <section className="container">
            <div >
               

                <Box className="file_uplaoder" sx={{ mb: 3 }}>
                    {/* <IconButton>
                                <Icon.FileUploadOutlined />
                            </IconButton> */}
                    <FileInput
                        onImageSelected={handleImageSelected}
                        chooseImage={onChooseImg}
                        reference={inputRef}
                    //ismultiple={true}
                    />

                    {isImageCropperOpen && (
                        <ImageCropper
                            image={selectedImage}
                            onCropDone={onCropDone}
                            onCropCancel={() => setImageCropperOpen(false)}
                        />
                    )}
                </Box>


            </div>

        </section>
        <Paper sx={{ mt: 2, mb: 2 }}>
            <Box className="pagecomp_detail" p={{ xs: 2, md: 3 }}>

                <Box className="pagecomp_content">

                    {/* {thumbs} */}


                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div
                                    className="list-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {componentDetails.files.map((file, index) => (
                                        <Draggable key={"component_" + index} draggableId={"component_" + index} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            <Grid item sm={12} md={10} fullWidth sx={{ alignItems: 'center', display: 'flex' }}>
                                                                <IconButton>
                                                                    <Icon.DragIndicatorOutlined />
                                                                </IconButton>
                                                                <Box sx={{ borderRadius: '8px', display: "flex", alignItems: 'center', marginLeft: "8px" }} key={file.name}>

                                                                    <img
                                                                        src={file.preview}
                                                                        style={img}
                                                                        // Revoke data uri after image is loaded
                                                                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                    />
                                                                    <Typography sx={{ ml: 2 }}>{file.name}</Typography>

                                                                </Box>
                                                            </Grid>





                                                            <Grid item sm={12} md={1} sx={{ ml: 'auto' }}>
                                                                <IconButton sx={{ mr: 2, ml: 2 }} onClick={() => deleteLink(index)} >
                                                                    <Icon.DeleteOutline />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>




                    <Divider sx={{ mt: 4, mb: 4 }} />
                    <Typography variant='h6'>Format</Typography>
                    <Box sx={{ display: 'flex', gap: '30px' }}>
                        <Box className={data.format == "1" ? "PictureButton selected" : "PictureButton"} onClick={() => updateComponentDetails({ format: "1" })}>
                            <img src='/assets/images/dummybuilder/photo_gallery_format_1.svg' />
                        </Box>
                        <Box className={data.format == "2" ? "PictureButton selected" : "PictureButton"} onClick={() => updateComponentDetails({ format: "2" })}>
                            <img src='/assets/images/dummybuilder/photo_gallery_format_2.svg' />
                        </Box>
                    </Box>



                </Box>
                <Box className="pagecomp_ftr"></Box>
            </Box>
        </Paper>




    </ >
    )
}

const PhotoGalleryPreview = ({ data, theme, component, customfont, blockstyles }) => {

   
    return (
        <Box>
        {!component.disable ?
        <Box className="mob_prev_hdr" sx={{mb:3}}>
            <Box className="mob_prev_hdr_top" 
             // sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "100%" }}
            >
            <Typography className="brand_name" sx={{ mb: 1 , color: theme.colors.heading.text_color}}>
                        {extractDataFromArray(data, ['title'], '')}
                    </Typography>
                <Box className="mob_prev_hdr_logo" sx={{ display: 'flex', alignItems: 'center', gap: "16px", height: "100%", flexDirection: data.format == "1" ? "column" : "row", overflowX: data.format == "2" ? "auto" : "" }}>

                    {data.files && data.files.map((file) => (
                        <img src={file.preview} style={{ width: data.format == "1" ? "100%" : "85%", height: data.format == "1" ? "100%" : "85%", borderRadius: blockstyles.type == 1 ? 0 : blockstyles.type == 2 ? 16 : blockstyles.type == 3 ? 0 : blockstyles.corner_radius  , objectFit: "cover" }}></img>
                    ))}

                </Box>

            </Box>


        </Box>
        : null }
        </Box>

    )
}

export { PhotoGallery, PhotoGalleryPreview };