import { Header, HeaderPreview } from "./Header";
import { PhotoGallery, PhotoGalleryPreview } from "./PhotoGallery";
import { TextDesc, TextDescPreview } from "./TextDesc";
import { Offers, OffersPreview } from "./Offers";
import { AboutUs, AboutUsPreview } from "./AboutUs"
import { Photos, PhotosPreview } from "./Photos"
import { FooterButtons, FooterButtonsPreview } from "./FooterButtons";
import { Links, LinksPreview } from "./Links"

const Components = {
    header: {
        label: 'Header',
        type: 'header',
        builderComponent: (data, updateComponent) => {
            return <Header data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <HeaderPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            en_logo: 1,
            type: 'header',
            brand_logo: '/assets/images/builder/cake_shop_logo_1.png',
            brand_name: 'Sweet Cakes',
            logo_shape: 'circle',
            format: 'inline',
            en_shortcuts: 1,
            shortcuts: [
                {
                    id: 1,
                    shortcut_link: "",
                    img_link: "/assets/images/builder/phone_outline_img.svg",
                    type: 'email',
                    value: '9876543210',
                },
                {
                    id: 2,
                    shortcut_link: "",
                    img_link: "/assets/images/builder/direction_outline_img.svg",
                    type: 'instagram',
                    value: 'contact@cakeshop.com',
                },
                {
                    id: 3,
                    shortcut_link: "",
                    img_link: "/assets/images/builder/direction_outline_img.svg",
                    type: 'twitter',
                    value: 'some location',
                },
                
            ],
            size:"M"

        },
        disable: false,
    },
    text_desc: {
        label: 'Header Text',
        type: 'text_desc',
        builderComponent: (data, updateComponent, setAddSection, backHandle) => {
            return <TextDesc data={data} updateData={updateComponent} setAddSection={setAddSection} backHandle={backHandle} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <TextDescPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'text_desc',
            title: 'Welcome to our cake shop!',
            desc: 'Customized cakes Available',
            format: "left",
        },
        disable: false,
    },
    photo_gallery: {
        label: 'Photo Gallery',
        type: 'photo_gallery',
        builderComponent: (data, updateComponent) => {
            return <PhotoGallery data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <PhotoGalleryPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'photo_gallery',
            title: '',
            files: [{ preview: "/assets/images/builder/cakeshop.jpeg" }],
            format: "1",
        },
        disable: false,
    },
    photos: {
        label: 'Photos',
        type: 'photos',
        builderComponent: (data, updateComponent) => {
            return <Photos data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <PhotosPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'photos',
            title: 'Menu',
            desc: 'Desc',
            files: [{ preview: "/assets/images/builder/cake_image_1.png" }, { preview: "/assets/images/builder/cake_image_2.png" }, { preview: "/assets/images/builder/cake_image_2.png" }, { preview: "/assets/images/builder/cake_image_1.png" }],
            format: "1",
        },
        disable: false,
    },
    offer: {
        label: 'Offers',
        type: 'offer',
        builderComponent: (data, updateComponent) => {
            return <Offers data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <OffersPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'offer',
            title: 'Todays Special Offers',
            desc: 'Apply and Enjoy!',
            // offers: [
            //     {
            //         id: 1,
            //         discount: 10,
            //         max_discount: 100,
            //         applicable_for_id: 1,
            //         start_date: null,
            //         end_date: null,
            //         is_active: true,
            //         button_text: "Tap to Apply",
            //         heading:null,
            //         description:null, 
            //         image:null,
            //     },
            //     {
            //         id: 2,
            //         discount: 15,
            //         max_discount: 150,
            //         applicable_for_id: 1,
            //         start_date: null,
            //         end_date: null,
            //         is_active: true,
            //         button_text: "Tap to Apply",
            //         heading:null,
            //         description:null, 
            //         image:null,
            //     }
            // ],
            offers: [
                {
                    id: 1,
                    type:"discount",
                    discount: 10,
                    heading:null,
                    discount_description:"Free delivery", 
                    item_description:null,
                    image:'/assets/images/builder/percentage_icon.svg',
                    start_date: null,
                    end_date: null,
                    is_active: true,  
                    is_scheduled: false,   
                },
                {
                    id: 2,
                    type:"item",
                    discount: null,
                    heading:"2 Gulab Jamun & 1 Lassi Glass",
                    discount_description:null,
                    item_description:"On order above Rs. 599", 
                    image:"/assets/images/builder/item_img.png",
                    start_date: null,
                    end_date: null,
                    is_active: true,  
                    is_scheduled: false,  
                },
            ],
            format: "1",

        },
        disable: false,
    },
    about_us: {
        label: 'About us',
        type: 'about_us',
        builderComponent: (data, updateComponent) => {
            return <AboutUs data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <AboutUsPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'about_us',
            title: 'About us',
            desc: 'Welcome to our cake shop! We take pride in creating delicious and beautiful cakes for every occasion.',
            format: "left",
        },
        disable: false,
    },
    footer_button: {
        label: 'Footer buttons',
        type: 'footer_button',
        builderComponent: (data, updateComponent) => {
            return <FooterButtons data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <FooterButtonsPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            type: 'footer_button',
            title: 'About us',
            action_button:
            {
                label: "OFFERS",
                url: "link",
            },
            Payment_Button: {
                label: "PAY BILL",
                upi: "34343434",
            }

        },
        disable: false,
    },
    link: {
        label: 'Links',
        type: 'links',
        builderComponent: (data, updateComponent) => {
            return <Links data={data} updateData={updateComponent} />
        },
        previewComponent: (data, theme, component, customfont, blockstyles) => {
            return <LinksPreview data={data} theme={theme} component={component} customfont={customfont} blockstyles={blockstyles} />
        },
        default: {
            customLink: [
                {
                    id: 1,
                    heading: "Follow Us on Instagram",
                    description: "100K Followers",
                    account_link: "https://instagram.com/spurtmeapp?igshid=MzRlODBiNWFlZA==",
                    img_link: "/assets/images/dummybuilder/instagram.svg"
                },
                {
                    id: 2,
                    heading: "Like us on Facebook",
                    description: "120K Likes",
                    account_link: "https://instagram.com/spurtmeapp?igshid=MzRlODBiNWFlZA==",
                    img_link: "/assets/images/builder/facebook_icon.png"
                }
            ],
            type: 'link',

        },
        disable: false,
    },
    button: {},

}



const Themes = [
    {
        id: 1,
        colors: {
            main: {
                background_color: "#fff"
            },
            heading: {
                text_color: "#000",
            },
            link: {
                text_color: "#fff",
                background_color: "#000",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#fff",
                button_background_color: "#000",
                shadow_color: "#fff",
                outline_color: "#000",
            },
        }
    },
    {
        id: 2,
        colors: {
            main: {
                background_color: "#000"
            },
            heading: {
                text_color: "#fff",
            },
            link: {
                text_color: "#000",
                background_color: "#fff",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#000",
                button_background_color: "#fff",
                shadow_color: "#fff",
                outline_color: "#000",
            },
        }
    },
    {
        id: 3,
        colors: {
            main: {
                background_color: "#F74C4C"
            },
            heading: {
                text_color: "#fff",
            },
            link: {
                text_color: "#fff",
                background_color: "#F74C4C",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#F74C4C",
                button_background_color: "#fff",
                shadow_color: "#F74C4C",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 4,
        colors: {
            main: {
                background_color: "#F6934C"
            },
            heading: {
                text_color: "#fff",
            },
            link: {
                text_color: "#fff",
                background_color: "#F6934C",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#F6934C",
                button_background_color: "#fff",
                shadow_color: "#F6934C",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 5,
        colors: {
            main: {
                background_color: "#4CCDF6"
            },
            heading: {
                text_color: "#fff",
            },
            link: {
                text_color: "#fff",
                background_color: "#4CCDF6",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#4CCDF6",
                button_background_color: "#fff",
                shadow_color: "#4CCDF6",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 6,
        colors: {
            main: {
                background_color: "#4C5DF6"
            },
            heading: {
                text_color: "#ffdb21",
            },
            link: {
                text_color: "#fff",
                background_color: "#4C5DF6",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#4C5DF6",
                button_background_color: "#fff",
                shadow_color: "#4C5DF6",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 7,
        colors: {
            main: {
                background_color: "#824CF6"
            },
            heading: {
                text_color: "#fff",
            },
            link: {
                text_color: "#fff",
                background_color: "#824CF6",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#824CF6",
                button_background_color: "#fff",
                shadow_color: "#824CF6",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 8,
        colors: {
            main: {
                background_color: "#FFDB21"
            },
            heading: {
                text_color: "#000",
            },
            link: {
                text_color: "#fff",
                background_color: "#FFDB21",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#FFDB21",
                button_background_color: "#fff",
                shadow_color: "#FFDB21",
                outline_color: "#fff",
            },
        }
    },
    {
        id: 9,
        colors: {
            main: {
                background_color: "#FFD0E1"
            },
            heading: {
                text_color: "#501fc9",
            },
            link: {
                text_color: "#fff",
                background_color: "#FFD0E1",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#FFD0E1",
                button_background_color: "#fff",
                shadow_color: "#FFD0E1",
                outline_color: "#fff",
            },
        }
    },
    {
        id: "custom",
        colors: {
            main: {
                background_color: "#FFD0E1"
            },
            heading: {
                text_color: "#501fc9",
            },
            link: {
                text_color: "#fff",
                background_color: "#FFD0E1",
            },
            block: {
                text_color: "#000",
                background_color: "#fff",
                button_text_color: "#FFD0E1",
                button_background_color: "#fff",
                shadow_color: "#FFD0E1",
                outline_color: "#fff",
            },
        }
    },
]

const BlockStyle = {
    type:1,
    corner_radius:0,
    block_shadow: false,
    outline: false,
}

const CustomFonts = [
    {
        id:1,
        font_family:"Poppins, sans-serif"
    },
    {
        id:2,
        font_family:"Lemon, sans-serif"
    },
    {
        id:3,
        font_family:"Inter, sans-serif"
    },
    {
        id:4,
        font_family:"Comic Neue, sans-serif"
    }

]





export { Components, Themes, BlockStyle, CustomFonts };