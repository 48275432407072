import { Box, Button, Chip, Container, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import CommonTemplates from "../../common/components/templates";
const Templates = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 2, px:5 }}
      >
      <Box sx={{textAlign:'center', mb:12}}>
     
      <CommonTemplates/>
      </Box>
      

      </Container>
    </>
  );
};

export default Templates;
