import { Outlet } from 'react-router-dom';
import Header from './Header';
import { Box, Toolbar } from '@mui/material';
import Footer from './Footer';

const LandingPageLayout = () => {
    return (<>
        <Header />
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Outlet />
            <Footer />
        </Box>
    </>)
}

export default LandingPageLayout;