import * as React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box, Button, ButtonGroup, Checkbox, Chip, Dialog, Popover, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Slide, Slider, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, Tab, Tabs, OutlinedInput, ListItemText } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { extractDataFromArray } from "../../../utils/common";
import { useState, useEffect, useRef } from "react";
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BasicDateTimePicker } from "../../../utils/common";
import FileInput from "./fileInput";
import ImageCropper from "./imageCropper";

const label = { inputProps: { 'aria-label': 'Size switch demo' } };
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let croppedImage = "";
let setCroppedImage = "";

const Currencies = [
    { value: "indian", name: 'Indian' },
    { value: "us", name: "US" }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const img = {
    display: 'block',
    width: "100%",
    height: "100%",
    borderRadius: 8,
};

function valuetext(value) {
    return `${value}°C`;
}

const Offers = ({ data, updateData, props }) => {
    const currentDate = dayjs();
    console.log("currentDate", currentDate);
    const [startDateValue, setStartDateValue] = React.useState(null);
    const [endDateValue, setEndDateValue] = React.useState(null);
    // const [endDateValue, setEndDateValue] = React.useState(dayjs());
    const [componentDetails, setComponentDetails] = useState(data)
    const updateComponentDetails = (data) => {
        setComponentDetails({ ...componentDetails, ...data })
        updateData({ ...componentDetails, ...data })
    }



    const [editoffer, setEditOffer] = React.useState(false);


    const handleSwitchChange = (index, offer) => {
        const updatedData = [...componentDetails.offers];
        updatedData[index]["is_active"] = !offer.is_active;
        updateComponentDetails({ ...componentDetails, offers: updatedData });

    };



    const handleChange1 = (newValue, flag) => {
        if (flag == 1) {
            setNewOffer({ ...newOffer, ...newValue });
        } else {
            setSelectedOffer({ ...selectedOffer, ...newValue });

        }
    };

    const [currencies, setsocialIcon] = useState(null);



    const [open, setOpen] = React.useState(false);

    // const [value, setValue] = React.useState(0);

    const [selectedOffer, setSelectedOffer] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [moreOptionAnchorEl, setMoreOptionAnchorEl] = React.useState(null);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseEditOffer = () => {
        setEditOffer(false);
    };




    const handleClick = (event, offer) => {

        setSelectedOffer(offer);
        setStartDateValue(offer.start_date ? dayjs(offer.start_date) : null);
        setEndDateValue(offer.end_date ? dayjs(offer.end_date) : null);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const scheduleOffer = (selectedOffer) => {
        const updatedData = [...componentDetails.offers];
        const currentOfferIndex = updatedData.findIndex(offer => offer.id == selectedOffer.id);

        // Update the value for the specific item
        updatedData[currentOfferIndex]["is_scheduled"] = true;
        updatedData[currentOfferIndex]["start_date"] = dayjs(startDateValue).toISOString();
        updatedData[currentOfferIndex]["end_date"] = endDateValue ? dayjs(endDateValue).toISOString() : null;
        // Update the state with the new data
        updateComponentDetails({ ...componentDetails, offers: updatedData });
        handleClosePopover();
        setStartDateValue(null);
        setEndDateValue(null);
    }

    const cancelOfferSchedule = (selectedOffer) => {
        const updatedData = [...componentDetails.offers];
        const currentOfferIndex = updatedData.findIndex(offer => offer.id == selectedOffer.id);

        // Update the value for the specific item
        updatedData[currentOfferIndex]["is_scheduled"] = false;
        updatedData[currentOfferIndex]["start_date"] = null;
        updatedData[currentOfferIndex]["end_date"] = null;
        // Update the state with the new data
        updateComponentDetails({ ...componentDetails, offers: updatedData });
        handleClosePopover();
        setStartDateValue(null);
        setEndDateValue(null);
    }

    const editOffer = (selectedOffer) => {
        handleCloseMoreOptionPopover();
        setEditOffer(true);
        setSelectedOffer(selectedOffer);
        const updatedData = [...componentDetails.offers];
        const currentOfferIndex = updatedData.findIndex(offer => offer.id == selectedOffer.id);

        //updateComponentDetails({ ...componentDetails, offer: updatedData });
    };

    const deleteOffer = (selectedOffer) => {
        const updatedData = [...componentDetails.offers];
        const currentOfferIndex = updatedData.findIndex(offer => offer.id == selectedOffer.id);
        updatedData.splice(currentOfferIndex, 1);
        updateComponentDetails({ ...componentDetails, offers: updatedData });
        handleCloseMoreOptionPopover()
    };



    const handleMoreOptionClick = (event, offer) => {
        setSelectedOffer(offer);
        setMoreOptionAnchorEl(event.currentTarget);
    };

    const handleCloseMoreOptionPopover = () => {
        setMoreOptionAnchorEl(null);
    };


    const [format, setFormat] = useState(1);

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const openMoreOptionPopover = Boolean(moreOptionAnchorEl);
    const moreoption = openMoreOptionPopover ? 'simple-popover' : undefined;

    const tempOffer = {
        id: null,
        type: null,
        discount: 10,
        heading: "2 Gulab Jamun & 1 Lassi Glass",
        discount_description: "Free delivery",
        item_description: "On order above Rs. 599",
        image: "/assets/images/builder/photo_camera_black.svg",
        start_date: null,
        end_date: null,
        is_active: true,
        is_scheduled: false
    }

    const [newOffer, setNewOffer] = useState(tempOffer);
    const [offerType, setOfferType] = useState(null);
    const addOffer = () => {
        const { offers } = componentDetails;
        //   const temp = componentDetails.o
        offers.push(newOffer);
        const temp = offers;
        console.log("temp", temp);
        setOpen(false);
        updateComponentDetails({ offers: temp })
        setNewOffer(tempOffer);

    }
    const updateOffer = () => {
        const { offers } = componentDetails;
        //   const temp = componentDetails.o
        offers.push(newOffer);
        const temp = offers;
        console.log("temp", temp);
        setOpen(false);
        updateComponentDetails({ offers: temp })
        setNewOffer(tempOffer);

    }
    const [isFileInputOpen, setFileInputOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageCropperOpen, setImageCropperOpen] = useState(false);
    const [croppedImage2, setCroppedImage2] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const inputRef = useRef();
    const onChooseImg = () => {
        inputRef.current.click();
    };

    croppedImage = croppedImage2;
    setCroppedImage = setCroppedImage2;



    const handleImageSelected = (imageData) => {
        // setSelectedImage(imageData);
        // if (imageData) {
        //     setImageUrl(URL.createObjectURL(imageData));
        //   }
        console.log("Selected Image Data:", imageData);
        setSelectedImage(imageData);
        if (imageData) {
            console.log("Creating URL for Image Data");
            try {
                const imageUrl = URL.createObjectURL(imageData);
                console.log("Image URL:", imageUrl);
                setImageUrl(imageUrl);
            } catch (error) {
                console.error("Error creating URL for Image Data:", error);
            }
        }
        setFileInputOpen(false); // Close the file input dialog
        setImageCropperOpen(true);
    };

    const onCropDone = (croppedImageData) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = croppedImageData.width;
        canvasEle.height = croppedImageData.height;
        const context = canvasEle.getContext("2d");
        let imageObj1 = new Image();
        imageObj1.src = selectedImage;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                croppedImageData.x,
                croppedImageData.y,
                croppedImageData.width,
                croppedImageData.height,
                0,
                0,
                croppedImageData.width,
                croppedImageData.height
            );
            const dataURL = canvasEle.toDataURL("image/jpeg");
            setCroppedImage(dataURL);
            //   console.log("Image url",dataURL)
            setImageCropperOpen(false);
            //  setCurrentPage("img-cropped");
            handleChange1({ image: dataURL });
        };
    };

    const handleDrop = (droppedItem) => {
        console.log("css");
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...componentDetails.offers];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
       // setFiles(updatedList);
        setComponentDetails({ offers: updatedList });
        updateComponentDetails({ ...componentDetails, offers: updatedList });

    };


    return (
        <>
            <Paper sx={{ mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{ xs: 2, md: 3 }}>

                    <Box className="pagecomp_content">
                        <TextField
                            fullWidth
                            id="brandname"
                            label="Heading"
                            variant="outlined"
                            value={extractDataFromArray(componentDetails, ['title'], '')}
                            onInput={(e) => { updateComponentDetails({ title: e.target.value }) }}
                            sx={{ mb: 4, background: '#fff !important' }}
                        />

                        <TextField
                            fullWidth
                            id="brandname"
                            label="Brand Name"
                            variant="outlined"
                            value={extractDataFromArray(componentDetails, ['desc'], '')}
                            onInput={(e) => { updateComponentDetails({ desc: e.target.value }) }}
                            sx={{ mb: 4, background: '#fff !important' }}
                        />

                        <Typography variant='h6'>Offers</Typography>

                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                                {(provided) => (
                                    <div
                                        className="list-container"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                       
                                        {componentDetails.offers.map((offer, index) => (
                                             <Draggable key={"component_" + index} draggableId={"component_" + index} index={index}>
                                            {(provided) => (
                                             <div
                                             ref={provided.innerRef}
                                             {...provided.dragHandleProps}
                                             {...provided.draggableProps}
                                         >
                                            <Box key={offer.id} className="grey_bg" sx={{ display: 'flex', gap: '10px', alignItems: 'center', p: 3, mb: 2, borderRadius: '8px', justifyContent: "space-between" }}>
                                                <Box sx={{ display: 'flex', gap: '10px', }}>
                                                    <IconButton>
                                                        <Icon.DragIndicatorOutlined />
                                                    </IconButton>
                                                    <img src={offer.image} style={{ height: "48px", width: "48px" }} />
                                                    <Box>
                                                        {offer.type == "discount" &&
                                                            <Typography variant='h6' sx={{ fontWeight: "bold", lineHeight: 1 }}>{offer.discount}% OFF</Typography>
                                                        }
                                                        {offer.type == "item" &&
                                                            <Typography variant='h6' sx={{ fontWeight: "bold", lineHeight: 1 }}>{offer.heading}</Typography>
                                                        }

                                                        <Typography variant='caption'>{offer.type == "item" ? offer.item_description : offer.discount_description} </Typography>

                                                        <Box sx={{ mt: 1 }}>
                                                            <Chip label={offer.type} variant="outlined" sx={{ background: "#fff", border: "solid 1px" }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: "flex" }}>
                                                    <div style={{ background: offer.is_scheduled ? "#EAFF00" : "#fff", borderRadius: "50px" }}>
                                                        <IconButton onClick={(e) => handleClick(e, offer)}>
                                                            <Icon.CalendarMonthOutlined />
                                                        </IconButton>
                                                    </div>
                                                    <Switch

                                                        // defaultChecked
                                                        checked={offer.is_active}
                                                        onChange={(e) => handleSwitchChange(index, offer)}
                                                        value={offer.is_active}
                                                    />
                                                    <IconButton onClick={(e) => handleMoreOptionClick(e, offer)}>
                                                        <Icon.MoreVert />
                                                    </IconButton>
                                                </Box>

                                                <Popover
                                                    id={moreoption}
                                                    open={openMoreOptionPopover}
                                                    anchorEl={moreOptionAnchorEl}
                                                    onClose={handleCloseMoreOptionPopover}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >


                                                    <Box sx={{ padding: "16px" }}>

                                                        <Box sx={{ display: "flex", alignItems: "center", mb: 2, cursor: "pointer" }} >
                                                            <Icon.CopyAllOutlined />
                                                            <Typography variant='subtittle2' sx={{ ml: 2 }} onClick={() => editOffer(selectedOffer)}>Edit</Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => deleteOffer(selectedOffer)}>
                                                            <Icon.DriveFileRenameOutline />
                                                            <Typography variant='subtittle2' sx={{ ml: 2 }}>Delete</Typography>
                                                        </Box>


                                                    </Box>



                                                </Popover>
                                            </Box>
                                            </div>
                                             )}

                                        
                                        </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Popover
                            //  id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box sx={{ padding: "16px" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant='subtittle1' sx={{ fontWeight: "bold" }}>Schedule</Typography>
                                    <IconButton onClick={handleClosePopover}>
                                        <Icon.Close />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant='caption'>Schedule when your offer appears.</Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                        <Box sx={{ mb: 2 }}>
                                            <DateTimePicker
                                                label="Start date"
                                                // value={selectedOffer ? selectedOffer.start_date : startDateValue}
                                                value={startDateValue}

                                                onChange={(newValue) => setStartDateValue(newValue)}

                                            />
                                        </Box>
                                        <Box>
                                            <DateTimePicker
                                                label="End date"
                                                // value={selectedOffer ? selectedOffer.end_date : endDateValue}
                                                value={endDateValue}
                                                onChange={(newValue) => setEndDateValue(newValue)}

                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Box>

                                <Divider sx={{ mt: 2, mb: 2 }} />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    {selectedOffer && selectedOffer.is_scheduled &&
                                        <div>
                                            <Icon.DeleteOutline onClick={() => cancelOfferSchedule(selectedOffer)} />

                                        </div>

                                    }
                                    <Button variant="contained" sx={{ color: '#fff' }} onClick={() => scheduleOffer(selectedOffer)}>
                                        Schedule Offer
                                    </Button>

                                </Box>


                            </Box>


                        </Popover>


                        <Button color="primary" variant="contained" sx={{ color: '#fff' }} onClick={handleClickOpen}>+ Add New</Button>


                      
                        {/* <Typography variant='h6'>Format</Typography>
                        <Box sx={{ display: 'flex', gap: '30px', mb: 4 }}>
                            <Box className={data.format == "1" ? "PictureButton selected" : "PictureButton"} onClick={() => updateComponentDetails({ format: "1" })}>
                                <img src='/assets/images/dummybuilder/photo_gallery_format_1.svg' />
                            </Box>
                            <Box className={data.format == "2" ? "PictureButton selected" : "PictureButton"} onClick={() => updateComponentDetails({ format: "2" })}>
                                <img src='/assets/images/dummybuilder/photo_gallery_format_2.svg' />
                            </Box>
                        </Box> */}
                        
                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition} >

                            <DialogTitle>
                                <IconButton sx={{ mr: 2 }} onClick={handleClose}>
                                    <Icon.Close />
                                </IconButton>
                                Add offers</DialogTitle>
                            <DialogContent dividers sx={{ background: '#F7F8F3' }}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Paper sx={{ p: 3 }}>
                                            <Typography variant='h6' sx={{ mb: 2 }}>Offer type</Typography>
                                            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                <Chip label="Discount" variant="outlined" className={newOffer.type == "discount" ? 'selected_item' : ""} onClick={() => setNewOffer({ ...newOffer, type: "discount" })} />
                                                <Chip label="Item" variant="outlined" className={newOffer.type == "item" ? 'selected_item' : ""} onClick={() => setNewOffer({ ...newOffer, type: "item" })} />

                                            </Box>

                                            <Divider sx={{ mt: 4, mb: 4 }} />
                                            {newOffer.type == "discount" &&
                                                <Box>


                                                    <Paper
                                                        elevation={0}
                                                        sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
                                                    >
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid
                                                                item
                                                                md={12}
                                                                sx={{ alignItems: "center", display: "flex" }}
                                                            >
                                                                <IconButton>
                                                                    <Icon.DragIndicatorOutlined />
                                                                </IconButton>
                                                                <Box className="file-uploader-wrapper" sx={{ position: "relative", height: "40px", width: "40px", mr: 3 }}>
                                                                    {newOffer.image &&
                                                                        <Box className="uploaded-image" sx={{ position: "absolute", top: 0, left: 0, height: "40px", width: "40px", zIndex: 1 }}>
                                                                            <img style={{ height: "40px", width: "40px", borderRadius: "40px" }} src={newOffer.image}></img>
                                                                        </Box>
                                                                    }
                                                                    <Box className="uploader-image" sx={{ margin: "0px -16px" }}>
                                                                        <FileInput
                                                                            onImageSelected={handleImageSelected}
                                                                            chooseImage={onChooseImg}
                                                                            reference={inputRef}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                                {isImageCropperOpen && (
                                                                    <ImageCropper
                                                                        image={selectedImage}
                                                                        onCropDone={onCropDone}
                                                                        onCropCancel={() => setImageCropperOpen(false)}
                                                                    />
                                                                )}
                                                                <Box sx={{ width: "100%" }}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={12}>
                                                                            <Typography variant='subtitle1'>Select discount</Typography>
                                                                            <Slider
                                                                                getAriaLabel={() => 'Temperature range'}
                                                                                value={newOffer.discount}

                                                                                onChange={(e) =>
                                                                                    handleChange1({ discount: e.target.value }, 1)
                                                                                }
                                                                                valueLabelDisplay="auto"
                                                                                getAriaValueText={valuetext}
                                                                            />
                                                                            <Typography variant='subtitle1' sx={{ mb: 3, mt: "-4px", fontWeight: "bold" }}>{newOffer.discount}% OFF</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="description"
                                                                                label="Description"
                                                                                variant="outlined"
                                                                                value={newOffer.discount_description}
                                                                                // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                                                                                sx={{ background: "#fff !important" }}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ discount_description: e.target.value }, 1)
                                                                                }
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                    </Paper>
                                                </Box>
                                            }
                                            {newOffer.type == "item" &&
                                                <Box>
                                                    <Paper
                                                        elevation={0}
                                                        sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
                                                    >
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid
                                                                item
                                                                md={12}
                                                                sx={{ alignItems: "center", display: "flex" }}
                                                            >
                                                                <IconButton>
                                                                    <Icon.DragIndicatorOutlined />
                                                                </IconButton>
                                                                <Box className="file-uploader-wrapper" sx={{ position: "relative", height: "40px", width: "40px", mr: 3 }}>
                                                                    {newOffer.image &&
                                                                        <Box className="uploaded-image" sx={{ position: "absolute", top: 0, left: 0, height: "40px", width: "40px", zIndex: 1 }}>
                                                                            <img style={{ height: "40px", width: "40px", borderRadius: "40px" }} src={newOffer.image}></img>
                                                                        </Box>
                                                                    }
                                                                    <Box className="uploader-image" sx={{ margin: "0px -16px" }}>
                                                                        <FileInput
                                                                            onImageSelected={handleImageSelected}
                                                                            chooseImage={onChooseImg}
                                                                            reference={inputRef}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                                {isImageCropperOpen && (
                                                                    <ImageCropper
                                                                        image={selectedImage}
                                                                        onCropDone={onCropDone}
                                                                        onCropCancel={() => setImageCropperOpen(false)}
                                                                    />
                                                                )}
                                                                <Box sx={{ width: "100%" }}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                name="heading"
                                                                                id="heading"
                                                                                label="Heading"
                                                                                variant="outlined"
                                                                                value={newOffer.heading}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ heading: e.target.value }, 1)
                                                                                }
                                                                                sx={{ background: "#fff !important", mb: 2 }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="description"
                                                                                label="Description"
                                                                                variant="outlined"
                                                                                value={newOffer.item_description}
                                                                                // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                                                                                sx={{ background: "#fff !important" }}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ item_description: e.target.value }, 1)
                                                                                }
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                    </Paper>
                                                </Box>
                                            }

                                            <Box sx={{ width: "100%", mt: 3 }}>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    sx={{ color: '#fff', width: "100%" }}
                                                    onClick={() => addOffer()}

                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Paper>




                                    </Grid>
                                    <Grid className="mobile_col" item xs={12} sm={12} md={6} sx={{ textAlign: 'center' }} display={{ md: 'block', xs: 'none' }}>
                                        {/* mobile */}

                                        <Box display={{ md: 'none', xs: 'block', }} sx={{ margin: 'auto', position: 'fixed', bottom: '20px', left: '30%' }}>
                                            <Button variant="contained" sx={{ color: '#fff' }} >
                                                <Icon.RemoveRedEye sx={{ mr: 2 }} /> Preview
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography>Preview</Typography>
                                            </Box>
                                        </Box>

                                        <Box className="mobile_preview_wrapper" sx={{ top: "0px !important" }}>
                                            <Box>
                                                {newOffer.type &&
                                                    <Box className="grey_bg" sx={{ display: 'flex', gap: '10px', mb: 1, borderRadius: '8px', border: "solid 1px #000", justifyContent: "space-between", padding: "8px", width: "300px" }}>
                                                        <Box sx={{ display: 'flex', gap: '10px' }}>

                                                            <img src={newOffer.image} style={{ height: "48px", width: "48px", borderRadius: "48px", marginRight: 0 }} />
                                                            <Box>
                                                                {newOffer.type == "discount" &&
                                                                    <Typography variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, textAlign: "left" }}>{newOffer.discount}% OFF</Typography>
                                                                }
                                                                {newOffer.type == "item" &&
                                                                    <Typography variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, textAlign: "left" }}>{newOffer.heading}</Typography>
                                                                }

                                                                <Typography variant='subtitle2' sx={{ textAlign: "left" }}>{newOffer.type == "discount" ? newOffer.discount_description : newOffer.item_description}</Typography>

                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                }

                                            </Box>

                                        </Box>



                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={editoffer}
                            onClose={handleCloseEditOffer}
                            TransitionComponent={Transition} >

                            <DialogTitle>
                                <IconButton sx={{ mr: 2 }} onClick={handleCloseEditOffer}>
                                    <Icon.Close />
                                </IconButton>
                                Edit offer</DialogTitle>
                            <DialogContent dividers sx={{ background: '#F7F8F3' }}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Paper sx={{ p: 3 }}>



                                            {selectedOffer && selectedOffer.type == "discount" &&
                                                <Box>


                                                    <Paper
                                                        elevation={0}
                                                        sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
                                                    >
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid
                                                                item
                                                                md={12}
                                                                sx={{ alignItems: "center", display: "flex" }}
                                                            >
                                                                <IconButton>
                                                                    <Icon.DragIndicatorOutlined />
                                                                </IconButton>
                                                                <Box className="file-uploader-wrapper" sx={{ position: "relative", height: "40px", width: "40px", mr: 3 }}>
                                                                    {selectedOffer.image &&
                                                                        <Box className="uploaded-image" sx={{ position: "absolute", top: 0, left: 0, height: "40px", width: "40px", zIndex: 1 }}>
                                                                            <img style={{ height: "40px", width: "40px", borderRadius: "40px" }} src={selectedOffer.image}></img>
                                                                        </Box>
                                                                    }
                                                                    <Box className="uploader-image" sx={{ margin: "0px -16px" }}>
                                                                        <FileInput
                                                                            onImageSelected={handleImageSelected}
                                                                            chooseImage={onChooseImg}
                                                                            reference={inputRef}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                                {isImageCropperOpen && (
                                                                    <ImageCropper
                                                                        image={selectedImage}
                                                                        onCropDone={onCropDone}
                                                                        onCropCancel={() => setImageCropperOpen(false)}
                                                                    />
                                                                )}
                                                                <Box sx={{ width: "100%" }}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={12}>
                                                                            <Typography variant='subtitle1'>Select discount</Typography>
                                                                            <Slider
                                                                                getAriaLabel={() => 'Temperature range'}
                                                                                value={selectedOffer.discount}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ discount: e.target.value }, 2)
                                                                                }
                                                                                valueLabelDisplay="auto"
                                                                                getAriaValueText={valuetext}
                                                                            />
                                                                            <Typography variant='subtitle1' sx={{ mb: 3, mt: "-4px", fontWeight: "bold" }}>{selectedOffer.discount}% OFF</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="description"
                                                                                label="Description"
                                                                                variant="outlined"
                                                                                value={selectedOffer.discount_description}
                                                                                // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                                                                                sx={{ background: "#fff !important" }}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ discount_description: e.target.value }, 2)
                                                                                }
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                    </Paper>
                                                </Box>
                                            }
                                            {selectedOffer && selectedOffer.type == "item" &&
                                                <Box>
                                                    <Paper
                                                        elevation={0}
                                                        sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
                                                    >
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid
                                                                item
                                                                md={12}
                                                                sx={{ alignItems: "center", display: "flex" }}
                                                            >
                                                                <IconButton>
                                                                    <Icon.DragIndicatorOutlined />
                                                                </IconButton>
                                                                <Box className="file-uploader-wrapper" sx={{ position: "relative", height: "40px", width: "40px", mr: 3 }}>
                                                                    {selectedOffer.image &&
                                                                        <Box className="uploaded-image" sx={{ position: "absolute", top: 0, left: 0, height: "40px", width: "40px", zIndex: 1 }}>
                                                                            <img style={{ height: "40px", width: "40px", borderRadius: "40px" }} src={selectedOffer.image}></img>
                                                                        </Box>
                                                                    }
                                                                    <Box className="uploader-image" sx={{ margin: "0px -16px" }}>
                                                                        <FileInput
                                                                            onImageSelected={handleImageSelected}
                                                                            chooseImage={onChooseImg}
                                                                            reference={inputRef}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                                {isImageCropperOpen && (
                                                                    <ImageCropper
                                                                        image={selectedImage}
                                                                        onCropDone={onCropDone}
                                                                        onCropCancel={() => setImageCropperOpen(false)}
                                                                    />
                                                                )}
                                                                <Box sx={{ width: "100%" }}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                name="heading"
                                                                                id="heading"
                                                                                label="Heading"
                                                                                variant="outlined"
                                                                                value={selectedOffer.heading}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ heading: e.target.value }, 2)
                                                                                }
                                                                                sx={{ background: "#fff !important", mb: 2 }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="description"
                                                                                label="Description"
                                                                                variant="outlined"
                                                                                value={selectedOffer.item_description}
                                                                                // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                                                                                sx={{ background: "#fff !important" }}
                                                                                onChange={(e) =>
                                                                                    handleChange1({ item_description: e.target.value }, 2)
                                                                                }
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                    </Paper>
                                                </Box>
                                            }

                                            <Box sx={{ width: "100%", mt: 3 }}>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    sx={{ color: '#fff', width: "100%" }}
                                                // onClick={() => updateOffer()}

                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Paper>




                                    </Grid>
                                    <Grid className="mobile_col" item xs={12} sm={12} md={6} sx={{ textAlign: 'center' }} display={{ md: 'block', xs: 'none' }}>
                                        {/* mobile */}

                                        <Box display={{ md: 'none', xs: 'block', }} sx={{ margin: 'auto', position: 'fixed', bottom: '20px', left: '30%' }}>
                                            <Button variant="contained" sx={{ color: '#fff' }} >
                                                <Icon.RemoveRedEye sx={{ mr: 2 }} /> Preview
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography>Preview</Typography>
                                            </Box>
                                        </Box>

                                        <Box className="mobile_preview_wrapper" sx={{ top: "0px !important" }}>
                                            <Box>
                                                {newOffer.type &&
                                                    <Box className="grey_bg" sx={{ display: 'flex', gap: '10px', mb: 1, borderRadius: '8px', border: "solid 1px #000", justifyContent: "space-between", padding: "8px", width: "300px" }}>
                                                        <Box sx={{ display: 'flex', gap: '10px' }}>

                                                            <img src={newOffer.image} style={{ height: "48px", width: "48px", borderRadius: "48px", marginRight: 0 }} />
                                                            <Box>
                                                                {newOffer.type == "discount" &&
                                                                    <Typography variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, textAlign: "left" }}>{newOffer.discount}% OFF</Typography>
                                                                }
                                                                {newOffer.type == "item" &&
                                                                    <Typography variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, textAlign: "left" }}>{newOffer.heading}</Typography>
                                                                }

                                                                <Typography variant='subtitle2' sx={{ textAlign: "left" }}>{newOffer.type == "discount" ? newOffer.discount_description : newOffer.item_description}</Typography>

                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                }

                                            </Box>

                                        </Box>



                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>




                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>
        </ >
    )
}

const OffersPreview = ({ data, theme, component, customfont, blockstyles }) => {
    return (
        <Box>
            {!component.disable ?
                <Box>

                    <Box sx={{ mb: 3, mt: 3 }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography variant='h6' sx={{ fontWeight: "bold", textAlign: "center", color: theme.colors.heading.text_color, fontFamily: customfont.font_family }}>{data.title}</Typography>
                            <Typography variant='caption' sx={{ color: theme.colors.heading.text_color, fontFamily: customfont.font_family }} >{data.desc}</Typography>
                        </Box>
                    </Box>



                    {data.offers.map((offer) => (
                        offer.is_active &&
                        <Box sx={{ mb: 1 }}>
                            <Box key={offer.id} sx={{ display: 'flex', gap: '10px', alignItems: 'center', mb: 2, borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px`, border: "solid 1px", justifyContent: "space-between", padding: "8px", background: theme.colors.block.background_color, borderColor: theme.colors.block.outline_color }}>
                                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>

                                    <img src={offer.image} style={{ height: "40px", width: "40px", borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px`, }} />
                                    <Box>
                                        {offer.type == "discount" &&
                                            <p variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, marginTop: 0, textAlign: "left", color: theme.colors.block.text_color, fontFamily: customfont.font_family }}>{offer.discount}% OFF</p>
                                        }
                                        {offer.type == "item" &&
                                            <p variant='subtitle1' style={{ fontSize: 16, fontWeight: "bold", margin: 0, marginTop: 0, textAlign: "left", color: theme.colors.block.text_color, fontFamily: customfont.font_family }}>{offer.heading}</p>
                                        }
                                        <p variant='caption' style={{ fontSize: 12, margin: 0, marginTop: 0, color: theme.colors.block.text_color, fontFamily: customfont.font_family }}>{offer.type == "item" ? offer.item_description : offer.discount_description}</p>

                                    </Box>
                                </Box>

                            </Box>

                        </Box>

                    ))}

                </Box>
                : null}
        </Box>
    )
}

export { Offers, OffersPreview };