import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";

import Header from "./Header";
import SideBar from "./Sidebar";
import { useState } from "react";

const DashboardLayout = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header toggleDrawer={toggleDrawer} open={open} />
      <SideBar toggleDrawer={toggleDrawer} open={open} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          background: "#F7F8F3",
        }}
      >
        <Toolbar />
        <Grid container maxWidth="lg" sx={{ m: "auto" }}>
          <Grid item md={12} sm={12} xs={12} sx={{ p: 2, paddingTop: 5 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
