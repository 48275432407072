import * as React from 'react';

import { Box, Divider, Grid, IconButton, Switch, Typography, Button, Slide, TextField, Dialog, DialogContent, DialogTitle, Popover } from '@mui/material';
import * as Icon from '@mui/icons-material'
import {Components, Themes, BlockStyle, CustomFonts} from './config'
import { useState, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { deepCopy } from '../../../utils/common';
import "./index.style.css";
import Design from "./design";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const sampleComponent = [
    Components.header.default,
    Components.text_desc.default,
    Components.photo_gallery.default,
    Components.photos.default,
    Components.offer.default,
    Components.link.default,
    Components.about_us.default,
    Components.footer_button.default,
]


const themes = Themes;
const defaultblockstyles = BlockStyle;
const customfonts = CustomFonts;

const SideBar = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                position: "absolute",
                left: 20,
                top: 'calc(100vh - 65%  )'

            }}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <IconButton>
                    <Icon.Edit />
                </IconButton>
                <IconButton>
                    <Icon.FormatColorFill />
                </IconButton>
                <IconButton>
                    <Icon.TrendingUp />
                </IconButton>
                <IconButton>
                    <Icon.SettingsOutlined />
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <IconButton>
                    <Icon.ErrorOutlineOutlined />
                </IconButton>
                <IconButton>
                    <Icon.HeadsetMicOutlined />
                </IconButton>
            </Box>
        </Box>
    )
}

const ComponentCard = ({ label, editHandler, type, component, updateComponent, blocks, updateBlockDetails, setItemList, itemList1, getDraggableItems, disable }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_Rename, setRenameAnchoeEl] = React.useState(null);

    const [checked, setChecked] = React.useState(true);

    const handleSwitchChange = (component) => {
        console.log("switchcomponent", component);
        updateComponentDetails({ ...componentDetails, disable: !componentDetails.disable })
        let c = component;
        c.disable = !c.disable;
        updateComponent(c);
        let blocks1 = blocks;
        blocks1[component.type] = component;
       /// blocks1 = { ...blocks, [`${component.type}`]:component };
       
       
        updateBlockDetails(blocks1);
       
    };

    const duplicateBlock = (component) => {
        let blocks1 = blocks;
        blocks1 = { ...blocks, [`${component.type}`]:component };
        updateBlockDetails(blocks1);
        const dragitemlist = itemList1;
        const indextoadd = dragitemlist.length - 1;
        dragitemlist.splice(indextoadd, 0, component.default);
        setItemList(dragitemlist);
        getDraggableItems();
        handleClose();
    }

   
    const [componentDetails, setComponentDetails] = useState(component)
    const updateComponentDetails = (component) => {
        setComponentDetails({ ...componentDetails, ...component })
        updateComponent({ ...componentDetails, ...component })
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickRename = (event) => {
        setRenameAnchoeEl(event.currentTarget);
        handleClose();
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseRename = () => {
        setRenameAnchoeEl(null);
    };



    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openRename = Boolean(anchorEl_Rename);
    const id1 = openRename ? 'simple-popover' : undefined;




    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                padding: 2,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}

        >
            {type != "header" && type != "footer_button" &&
                <IconButton sx={{ cursor: 'grab' }}>
                    <Icon.DragIndicator />
                </IconButton>
            }
            <Box
                sx={{
                    flex: 1,
                    marginLeft: type != "header" && type != "footer_button" ? 2 : "56px",
                    cursor: 'pointer'
                }}
                onClick={editHandler}
            >
                <Typography variant='h6'>{componentDetails && componentDetails.label}</Typography>
            </Box>
            <Box
                sx={{

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }} >
                <Switch
                    aria-label=''
                    defaultChecked
                    checked={componentDetails && !componentDetails.disable}
                    onChange={() => handleSwitchChange(component)}
                    value={componentDetails && componentDetails.disable}
                />
                <Icon.MoreVert onClick={handleClick} sx={{ cursor: "pointer" }} />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ padding: "16px" }}>
                    {type != "header" && type != "offer" && type != "footer_button" &&
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2, cursor: "pointer" }} >
                            <Icon.CopyAllOutlined />
                            <Typography variant='subtittle2' sx={{ ml: 2 }} onClick={() => duplicateBlock(component)}>Duplicate Block</Typography>
                        </Box>
                    }
                    <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleClickRename}>
                        <Icon.DriveFileRenameOutline />
                        <Typography variant='subtittle2' sx={{ ml: 2 }}>Rename Block</Typography>
                    </Box>


                </Box>
            </Popover>
            <Popover
                id={id1}
                open={openRename}
                anchorEl={anchorEl_Rename}
                onClose={handleCloseRename}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >


                <Box sx={{ padding: "16px", display: 'flex' }}>
                    <TextField
                        fullWidth
                        value={ componentDetails && componentDetails.label}

                        onChange={(e) => { updateComponentDetails({ label: e.target.value }) }}
                        id="brandname" label="Heading" variant="outlined" sx={{ background: '#fff !important' }}
                        size='small'
                    />
                    <Button
                        variant="contained"
                        sx={{ fontWeight: "bold", borderRadius: "8px", marginLeft: "10px" }}
                        onClick={() => handleCloseRename()}
                    >
                        Ok
                    </Button>

                </Box>

            </Popover>


        </Box>
    )
}

const ComponentEditCard = ({ backHandle, component, updateComponent, setAddSection, blocks, setComponentDetails, editIndex, data, setComponents }) => {
    const deleteBlock = (component) => {
        
        let blocks1 = {...blocks};
        delete blocks1[`${component.type}`];
        setComponentDetails(blocks1);
       
        // const key = component.type;
        // console.log("key", key);
        // delete blocks[component]
        // // let blocks1 = blocks;
        // // blocks1 = { ...blocks, [`${component.type}`]:component };
       
       
        // updateBlockDetails(blocks);
        
        const itemlist = [...data];
       
        // only splice array when item is found
         itemlist.splice(editIndex, 1); // 2nd parameter means remove one item only
           console.log("itemlistedi", itemlist);
            setComponents(itemlist);
            backHandle();
        
       
        
        // const indextoadd = dragitemlist.length - 1;
        // dragitemlist.splice(indextoadd, 0, component.default);
        // // dragitemlist.push(component.default);
        // console.log("dragitemlist", dragitemlist);
        // 
        // getDraggableItems();
       
    }
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                padding: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <IconButton onClick={() => { backHandle(); setAddSection({ addSection: false, isEditCard: true, isAddButton: true }); }}>
                    <Icon.ArrowBack />
                </IconButton>
                <Box sx={{ flex: 1, ml: 1 }} >
                    <Typography variant='h6'>{Components[component.type].label}</Typography>
                </Box>
                <Box >
                    <IconButton onClick={() => deleteBlock(component)}>
                        <Icon.DeleteOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ p: 2 }}>
                <Box className="pagecomp_content">
                    {
                        Components[component.type].builderComponent(component, updateComponent, setAddSection, backHandle)
                    }
                </Box>
            </Box>
        </Box>
    )
}

const ComponentListing = ({ setEditComponent, data, updateComponent, setComponentDetails, blocks }) => {
    const [itemList1, setItemList] = useState(sampleComponent);
    console.log("listing itemList", data);
    console.log("itemList1", itemList1);

    const [dragableItemsList, setDragableItemList] = useState()
    console.log("dragableItemsList", dragableItemsList);

    //const [blocks, setComponentDetails] = useState(Components);
    console.log("blocks", blocks);




    const getDraggableItems = () => {
        const dragableItemsList = itemList1.filter((item) => item.type != "header" && item.type != "footer_button");
        setDragableItemList(dragableItemsList);
    }

    // 
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
       var updatedList = [...dragableItemsList];
    
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
       updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        setDragableItemList(updatedList);
        console.log("updatedList", updatedList);
        console.log("dragableItemsList", dragableItemsList);
    };

    useEffect(() => {
        getDraggableItems();
        setItemList(data);

    }, []);

    return (
        <div>

            {data && data.filter((comp) => comp.type == "header").map((component, index) => (


                <ComponentCard
                    label={Components[component.type].label}
                    editHandler={() => { setEditComponent(index) }}
                    type={Components[component.type].type}
                    component={blocks[component.type]}
                    blocks={blocks}
                    updateComponent={updateComponent}
                    updateBlockDetails={setComponentDetails}
                />


            ))}


            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}


                        >
                            {data && data.filter((comp) => comp.type !== "header").map((component, index) => (
                                <Draggable key={"component_" + index} draggableId={"component_" + index} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <ComponentCard
                                                label={Components[component.type].label}
                                                editHandler={() => { setEditComponent(index + 1) }}
                                                type={Components[component.type].type}
                                                component={blocks[component.type]}
                                              // component={component}
                                                blocks={blocks}
                                                itemList1={itemList1}
                                                updateBlockDetails={setComponentDetails}
                                                setItemList={setItemList}
                                                getDraggableItems={getDraggableItems}
                                                updateComponent={updateComponent}
                                                disable={Components[component.type].disable}
                                            //updateData={updateData}

                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}


                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {data && data.filter((comp) => comp.type == "footer_button").map((component, index) => (


                <ComponentCard
                    label={Components[component.type].label}
                    editHandler={() => { setEditComponent(itemList1.length - 1) }}
                    type={Components[component.type].type}
                    blocks={blocks}
                    component={blocks[component.type]}
                    updateComponent={updateComponent}
                    updateBlockDetails={setComponentDetails}
                />


            ))}

        </div>
    )
}

const ComponentPreview = ({ components, theme, blocks, customfont, blockstyles }) => {

    // const [blocks, setComponentDetails] = useState(Components);
    // console.log("prevcomponents", components);

    return (
        <Box className="mobile_preview_wrapper">
            <Box className="mob_border">
                <Box className="mob_pg_wrapper" sx={{background:theme.colors.main.background_color, height:"100%"}}>
                   <Box sx={{height:"90%", overflowY:"auto"}}>
                    {
                        components.filter((comp) => comp.type != "footer_button").map((component, index) => {
                            return (
                                <Box key={'preview_' + index}>
                                    {Components[component.type].previewComponent(component, theme, blocks[component.type], customfont, blockstyles)}
                                </Box>
                            )
                        })
                    }
                    </Box>
                    {
                        components.filter((comp) => comp.type == "footer_button").map((component, index) => {
                            return (
                                <Box key={'preview_' + index} sx={{position:"absolute", bottom:0, width:"100%", marginLeft:"-15px"}}>
                                    {Components[component.type].previewComponent(component, theme, blocks[component.type], customfont, blockstyles )}
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}
const ComponentBuilder = (props) => {
    const [editIndex, setEditComponent] = useState(-1)
    const [components, setComponents] = useState(sampleComponent)
    // const [componentLabel, setComponentLabel] = useState(components.label)
    const [addNewCard, setAddSection] = useState({ addSection: false, isEditCard: true, isAddButton: true });
    const [subdomain, setSubDomain] = useState("")
    const [isUniqueSubDomain, setIsUniqueSubDomain] = useState(false)
    const updateComponent = (index, data) => {
       
        let temp = deepCopy(components)
       
        temp[index] = data
        setComponents(temp);
       // setComponentState(temp);
    }

    const [blocks, setComponentDetails] = useState(Components);

    const [theme, setThemes] = React.useState(themes[0]);
    const [blockstyles, setBlockStyle] = React.useState(defaultblockstyles);
    const [defaultFonts, setDefaultFonts] = React.useState(customfonts);
   
    const [font, setFont] = React.useState(defaultFonts[0]);
   
    const [defaultThemes, setDefaultThemes] = React.useState(themes);




    const checkUniqueSubDomain = () => {
        setIsUniqueSubDomain(true);
    }

    useEffect(() => {
       // setComponentLabel();

    }, []);


    return (
        <Grid container spacing={1} >


            {/* component section */}
            <Grid item md={8} sx={{ p: 2, pb: 0 }}>
                
                {props.value == 0 &&
                <Box>
                   {editIndex === -1 && 
                    <Grid container>
                    <Grid item md={11} sm={12}>
                        <Box sx={{ mb: 4, background: '#fff', border: "solid 1px #ddd", display: "block", height: 48, borderRadius: "8px", width: "100%" }}>
                            <Box className="subdomain-text" sx={{ display: 'flex', width: "100%", height: "100%" }}>


                                <  input

                                    type="text"
                                    fullWidth
                                    id="brandname"
                                    label=""
                                    variant="standard"
                                    value="www.sprutme.in/"
                                    style={{ background: '#fff !important', border: "none", height: "100%", paddingLeft: "16px" }}
                                    size="small"
                                    disabled
                                />
                                <Box>
                                    <input
                                        type="text"
                                        fullWidth
                                        id="brandname"
                                        label="Choose a URL"
                                        variant="outlined"
                                        value={subdomain}
                                        //  onInput={(e) => { updateComponentDetails({ brand_name: e.target.value }) }}
                                        style={{ background: '#fff !important', border: "none", height: "100%", width: "100%", paddingLeft: 16, paddingRight: 16, background: "transparent" }}
                                        size="small"
                                        onChange={(e) => setSubDomain(e.target.value)}
                                        onBlur={() => checkUniqueSubDomain()}
                                    />
                                    {isUniqueSubDomain &&
                                        <Typography variant='caption' sx={{ color: "#45D845" }}>This URL is available </Typography>

                                    }
                                </Box>
                            </Box>


                        </Box>
                    </Grid>
                    
                    <Grid item md={1} sm={12} >
                        <Box>
                            <IconButton sx={{ height: "48px", fontSize: "48px", color: "#000" }} disableRipple >
                                <Icon.CheckCircle fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                }

                {addNewCard.isAddButton && editIndex === -1 &&
                    <Box sx={{ mb: 3, textAlign: "center" }}>
                        <Button variant="contained" sx={{ fontWeight: "bold", borderRadius: "8px" }} onClick={() => setAddSection({ addSection: true, isEditCard: true, isAddButton: true })}>+ Add section</Button>
                    </Box>
                }
                    {
                        editIndex === -1 ?

                            <ComponentListing
                                setEditComponent={setEditComponent}
                                updateComponent={(data) => { updateComponent(editIndex, data) }}
                                // component={components}
                                data={components}
                                setComponentDetails={setComponentDetails}
                                blocks={blocks}
                            />

                            :
                            <ComponentEditCard
                                backHandle={() => { setEditComponent(-1) }}
                                updateComponent={(data) => { updateComponent(editIndex, data) }}
                                component={components[editIndex]}
                                editIndex = {editIndex}
                                setAddSection={() => { setAddSection({ addSection: false, isEditCard: true, isAddButton: true }) }}
                                setComponentDetails={setComponentDetails}
                                blocks={blocks}
                                data={components}
                                setComponents={setComponents}
                                // itemList1={itemList1}
                                // updateBlockDetails={setComponentDetails}
                                // setItemList={setItemList}
                            />
                    }
                </Box>
                }
                {props.value == 1 &&
               <Design 
               selectedTheme={theme} 
               themes={defaultThemes}
               blockstyles={blockstyles}
               setThemes={setThemes}
               setBlockStyle={setBlockStyle}
               selectedFont={font}
               setFont={setFont}
               customfonts={defaultFonts}
               />
                }
                {props.value == 2 &&
               <div>Analytics</div>
                }
                {props.value == 3 &&
               <div>Settings</div>
                }
            </Grid>
            {/* preview */}
            <Grid item md={4}>
                <ComponentPreview components={components} theme={theme} blocks={blocks} blockstyles={blockstyles} customfont={font}  />
            </Grid>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={addNewCard.addSection}
                onClose={() => setAddSection({ addSection: false, isEditCard: true, isAddButton: true })}
                TransitionComponent={Transition} >

                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>Add a block</Typography>

                    <IconButton onClick={() => setAddSection({ addSection: false, isEditCard: true, isAddButton: true })}>
                        <Icon.Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ background: '#F7F8F3' }}>
                    <Box className="add_section_block">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ background: "#000", height: 48, width: 48, borderRadius: 40, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 16 }}>
                                <Icon.LinkRounded sx={{ color: '#fff' }} />
                            </div>
                            <div>
                                <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>Links</Typography>
                                <Typography variant='caption'>Add a link or group of links</Typography>
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" sx={{ fontWeight: "bold", borderRadius: "8px" }} onClick={() => { setEditComponent(0); setAddSection({ addSection: false, isEditCard: true, isAddButton: false }) }}>Add</Button>
                        </div>
                    </Box>
                    <Box className="add_section_block">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ background: "#000", height: 48, width: 48, borderRadius: 40, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 16 }}>
                                <Icon.ImageOutlined sx={{ color: '#fff' }} />
                            </div>
                            <div>
                                <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>Photos</Typography>
                                <Typography variant='caption'>Add images</Typography>
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" sx={{ fontWeight: "bold", borderRadius: "8px" }} onClick={() => { setEditComponent(3); setAddSection({ addSection: false, isEditCard: true, isAddButton: false }) }}>Add</Button>
                        </div>
                    </Box>
                    <Box className="add_section_block">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ background: "#000", height: 48, width: 48, borderRadius: 40, display: "flex", justifyContent: "center", alignItems: "center", marginRight: 16 }}>
                                <Icon.EditNoteOutlined sx={{ color: '#fff' }} />
                            </div>
                            <div>
                                <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>Heading + Text</Typography>
                                <Typography variant='caption'>Add custom heading and description</Typography>
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" sx={{ fontWeight: "bold", borderRadius: "8px" }} onClick={() => { setEditComponent(1); setAddSection({ addSection: false, isEditCard: true, isAddButton: false }) }}>Add</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </Grid>
    )

}


export default ComponentBuilder;