import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material'
import ImageGallery from "./component/ImageGallery";
import ContactUs from "./component/ContactUs";
import Links from "./component/links";
import FooterCalltoaction from "./component/FooterCalltoaction";
import Offer from "./component/offer";
import PageStyle from "./component/PageStyle";
import OfferForm from "./component/OfferForm";



const DummyBuilder = () => {
    return (
        <>
            <Grid container>
                <Grid item sx={6} sm={12} md={7}>
                    <ImageGallery />
                    <ContactUs />
                    <Links />
                    <FooterCalltoaction />
                    <Offer />
                    <PageStyle/>
                    <OfferForm/>
                </Grid>


                {/* mobile */}
                <Box display={{ md: 'none', xs: 'block', }} sx={{ margin: 'auto', position: 'fixed', bottom: '20px', left: '30%' }}>
                    <Button variant="contained" sx={{ color: '#fff' }} >
                        <Icon.RemoveRedEye sx={{ mr: 2 }} /> Preview
                    </Button>
                </Box>

                <Grid className="mobile_col" item xs={12} sm={12} md={5} sx={{ textAlign: 'center' }} display={{ md: 'block', xs: 'none' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>www.sprt.app/Plantsforyou</Typography>
                            <IconButton sx={{ mr: 2, ml: 2 }}>
                                <Icon.Edit />
                            </IconButton>
                        </Box>
                        <Box>
                            <Button variant="contained" size="small" sx={{ color: '#fff' }}>
                                Share
                            </Button>
                        </Box>
                    </Box>

                    <Box className="mobile_preview_wrapper">

                        <Box className="mob_border">
                            <Box className="mob_pg_wrapper" sx={{ position: 're' }}>
                                <Box className="mob_prev_hdr">
                                    <Box className="mob_prev_hdr_top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box className="mob_prev_hdr_logo" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box className="logo_img">
                                                <img src='/assets/images/dummybuilder/bake_logo.png' />
                                            </Box>

                                            <Typography className="brand_name" sx={{ ml: 2 }}>
                                                B.Cake
                                            </Typography>
                                        </Box>
                                        <IconButton>
                                            <Icon.MenuOutlined />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ mt: 1, mb: 2 }} />
                                    <Box className="mob_prev_shortcut">
                                        <img src='/assets/images/dummybuilder/phone_outline_img.svg' />
                                        <img src='/assets/images/dummybuilder/direction_outline_img.svg' />
                                    </Box>
                                </Box>
                                <Box className="mob_prev_heading" sx={{ mt: 3, mb: 3 }}>
                                    <Typography variant='h3' className='mob_prev_heading_h3' sx={{ mb: 1 }}>
                                        Welcome to our
                                        cake shop!
                                    </Typography>
                                    <Typography>
                                        Customized cakes Available
                                    </Typography>
                                </Box>


                                <Box className="mob_prev_image_gallery" sx={{ mt: 3, mb: 3 }}>
                                    <img src="/assets/images/dummybuilder/image_gallery_1.png" style={{ maxWidth: '100%' }} />
                                </Box>
                                <Box className="mob_prev_offer" sx={{ mt: 3, mb: 3 }}>
                                    <Box className="mob_lp_scard" sx={{ backgroundImage: 'url(/assets/images/dummybuilder/scratch_card_img.png)', width: '100%', height: '120px', backgroundSize: 'cover', borderRadius: '16px' }}>
                                        <Typography variant="h4" sx={{ color: '#fff' }}>OFFER</Typography>
                                        <Box sx={{ background: '#00000020' }}>
                                            <Typography sx={{ color: '#fff' }}>Scratch. Win. Pay</Typography>
                                        </Box>
                                    </Box>

                                </Box>

                                <Box className="mob_prev_contactus" sx={{ mt: 3, mb: 3 }}>
                                    <Typography variant='h3' className='mob_prev_heading_h3' sx={{ mb: 1 }}>
                                        Contact Us
                                    </Typography>
                                    <Typography>
                                        123 Main Street, Anytown, CA 12345,United States
                                    </Typography>

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387201.0952732999!2d-74.60350125305075!3d40.69580919350031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1688850322743!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ border: '0px', borderRadius: '16px', marginTop: '15px' }}></iframe>
                                    <Button variant="contained" fullWidth sx={{ color: '#fff', mt: 2, borderRadius: '16px' }}>Direction</Button>
                                </Box>

                                <Box className="mob_prev_links" sx={{ mt: 3, mb: 3, background: '#fff', p: 2, borderRadius: '16px' }}>
                                    <Box className="link_list_view" sx={{ display: 'flex' }}>
                                        <Box sx={{ mr: 2 }}>
                                            <img src="/assets/images/dummybuilder/instagram.svg" />
                                        </Box>
                                        <Box>
                                            <Typography variant="body1">Instagram</Typography>
                                            <Typography variant="body2">100k Followers</Typography>
                                        </Box>
                                        <Box sx={{ ml: 'auto' }}>
                                            <IconButton>
                                                <Icon.NavigateNextOutlined />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="mob_prev_ftr_links" sx={{ mt: 3, mb: 3, background: '#fff', p: 2, borderRadius: '16px', left: '0', bottom: '0', width: '100%' }}>
                                    <Box className="link_list_view" sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ mr: 2 }}>
                                            <Button variant="outlined" color="secondary">Offers</Button>
                                        </Box>
                                        <Box>
                                            <Button variant="contained" sx={{ color: '#fff' }}>Pay Bill</Button>
                                        </Box>
                                    </Box>
                                </Box>



                            </Box>
                        </Box>
                    </Box>

                </Grid>

            </Grid >


        </>
    )
}






export default DummyBuilder;