import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SidebarItems from "./SideBartems";

import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: "#F7F8F3",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      // [theme.breakpoints.up('sm')]: {
      //   width: theme.spacing(9),
      // },
      width: 0,
    }),
  },
}));

const Sidebar = (props) => {
  const location = useLocation();
  return (
    <Drawer anchor="left" variant="permanent" open={props.open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Box>
          <img src="/assets/images/spurtme_logo.svg" alt="SpurtMe" />
        </Box>
        <IconButton onClick={props.toggleDrawer}>
          {/* <MenuIcon /> */}
          <CloseOutlinedIcon />
        </IconButton>
      </Toolbar>
      <List component="nav">
        <SidebarItems currentItem={location.pathname} />
      </List>
    </Drawer>
  );
};

export default Sidebar;
