import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Paper, Switch, TextField, Typography } from "@mui/material";

import * as Icon from '@mui/icons-material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const FooterCalltoaction = () => {
    return (
        <>

            <Paper sx={{  mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{xs:2, md:3}}> 
                    <Box className="pagecomp_header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ mr: 2 }} >
                                <Icon.ArrowBack />
                            </IconButton>
                            Footer Buttons
                        </Box>
                        <Box>
                            {/* <Icon.MoreVert sx={{ ml: 2 }} /> */}
                            <Switch {...label} defaultChecked />
                            <IconButton sx={{ ml: 2 }} >
                                <Icon.DeleteOutline />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="pagecomp_content">







                        <TextField fullWidth id="footer_calltoaction_heading" label="Heading" variant="outlined" sx={{ background: '#fff !important' }} />

                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                                    <TextField fullWidth id="ftr_c2a_text_1" label="button name" variant="outlined" sx={{ background: '#fff !important' }} />
                                    <TextField fullWidth id="ftr_c2a_offerlink" label="link" variant="outlined" sx={{ background: '#fff !important' }} />
                                    <Switch {...label} defaultChecked />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                                    <TextField fullWidth id="ftr_c2a_text_2" label="Payment button" variant="outlined" sx={{ background: '#fff !important' }} />
                                    <TextField fullWidth id="ftr_c2a_upi" label="UPI number" variant="outlined" sx={{ background: '#fff !important' }} />
                                    <Switch {...label} defaultChecked />
                                </Grid>
                            </Grid>
                        </Paper>


                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>

        </>
    );
};


export default FooterCalltoaction;