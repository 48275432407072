import { Box, Button, ButtonGroup, Divider, Grid, IconButton, TextField, Typography, Paper, Switch, FormControl, InputLabel, MenuItem, ListItemText, Select, OutlinedInput } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { extractDataFromArray } from "../../../utils/common";
import { useState } from "react";
import { Link } from "react-router-dom";

const label = { inputProps: { 'aria-label': 'Size switch demo' } };


  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const socialIcons = [
    {value:"link", name:'Link' },
    {value:"popup", name:"Popup"}
  ]
  
const FooterButtons = ({ data, updateData }) => {
    const [componentDetails, setComponentDetails] = useState(data)
    const updateComponentDetails = (data) => {
        setComponentDetails({ ...componentDetails, ...data })
        updateData({ ...componentDetails, ...data })
    }
    const [socialIcon, setsocialIcon] = useState(null);

    const handleSelect = (event) => {
        const {
          target: { value },
        } = event;
        
        setsocialIcon(
         
          value.value
        );
        
      };
    return (
        <>
            <TextField
                fullWidth
                value={extractDataFromArray(componentDetails, ['title'], '')}
                onInput={(e) => { updateComponentDetails({ 'title': e.target.value }) }}
                id="brandname" 
                label="Heading" 
                variant="outlined"
                sx={{ mb: 1, background: '#fff !important' }} 
            />


            <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item md={12} sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                        <TextField
                            fullWidth
                            // value={extractDataFromArray(componentDetails.action_button, ['label'], '')}
                            value={componentDetails.action_button.label}
                            onInput={(e) => { updateComponentDetails({ ...componentDetails, action_button:{...componentDetails.action_button,'label': e.target.value }  }) }}
                            id="buttonname" 
                            label="button name" 
                            variant="outlined" 
                            sx={{ background: '#fff !important' }} 
                        />
                        <FormControl sx={{width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Offer type</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={false}
          value={socialIcon}
          onChange={handleSelect}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{background:"#fff"}}
        >
          
          {socialIcons.map((socialicon) => (
            <MenuItem key={socialicon} value={socialicon}>
              <ListItemText primary={socialicon.name} />
            </MenuItem>
          ))}
          
        </Select>
      </FormControl>
                        
                        <Switch {...label} defaultChecked />
                    </Grid>
                    {socialIcon == "link" && 
                    <Grid item md={12} sx={{ display: 'flex', mt:2}}>
                    <TextField
                            fullWidth
                            value={componentDetails.action_button.link}
                            onInput={(e) => { updateComponentDetails({ ...componentDetails, action_button:{...componentDetails.action_button,'link': e.target.value }  }) }}
                            id="link" 
                            label="Add link" 
                            variant="outlined" 
                            sx={{ mb: 1, background: '#fff !important' }} 
                        />
                        </Grid>
                        }
                </Grid>
            </Paper>

            <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mt: 2, mb: 2 }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item md={12} sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                        <TextField
                            fullWidth
                            value={componentDetails.Payment_Button.label}
                           
                            onInput={(e) => { updateComponentDetails({ ...componentDetails, Payment_Button:{...componentDetails.Payment_Button,'label': e.target.value }  }) }}
                            id="buttonname" 
                            label="Payment button" 
                            variant="outlined" 
                            sx={{ mb: 1, background: '#fff !important' }} 
                        />
                         <TextField
                            fullWidth
                            value={componentDetails.Payment_Button.upi}
                            onInput={(e) => { updateComponentDetails({ ...componentDetails, Payment_Button:{...componentDetails.Payment_Button,'upi': e.target.value }  }) }}
                            id="upi number" 
                            label="UPI number" 
                            variant="outlined" 
                            sx={{ mb: 1, background: '#fff !important' }} 
                        />
                        <Switch {...label} defaultChecked />
                    </Grid>
                </Grid>
            </Paper>


        </>
    )
}

const FooterButtonsPreview = ({ data, theme, component, customfont, blockstyles }) => {
    return (
       <Box>
        {!component.disable ?

<Box className="mob_prev_ftr_links" sx={{ background: "#fff", p: 2, boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0,0.19)", left: '0', bottom: '0', width: '100%' }}>
<Box className="link_list_view" sx={{ display: 'flex', justifyContent: 'center' }}>
<Link to={data.action_button.link} target="_blank">
    <Box sx={{ mr: 2 }}>
        <Button sx={{fontFamily:customfont.font_family, borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px`}} variant="outlined" color="secondary">{extractDataFromArray(data.action_button, ['label'], '')}</Button>
    </Box>
    </Link>
    <Box>
        <Button variant="contained" sx={{ color: '#fff', fontFamily:customfont.font_family, borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px` }}>{extractDataFromArray(data.Payment_Button, ['label'], '')}</Button>
    </Box>
</Box>
</Box>
: null}
</Box>
    )
}

export { FooterButtons, FooterButtonsPreview };