import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#121212",
      contrastText: "#fff",
    },
    secondary: {
      main: "#807dff",
      contrastText: "#fff",
    },
    highlight2: {
      main: "#e7ff56",
      contrastText: "#121212",
    },
    white: {
      main: "#fff",
      contrastText: "#121212",
    }
  },
  
      styleOverrides: {
        MuiContainer: {
          maxWidth: '1240px',
        },
      },
   
   
});

export default theme;
