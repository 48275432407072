import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ProfileSection from "./UserProfile";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - 0px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props) => {
  return (
    <AppBar
      sx={{ background: "#F7F8F3" }}
      position="absolute"
      open={props.open}
    >
      <Toolbar
        sx={{
          pr: "0px", // keep right padding when drawer closed
        }}
      >
        {" "}
        <Link to="/">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/assets/images/spurtme_logo.svg"
              alt="SprutMe"
              width={100}
            />
          </Box>
        </Link>
        <IconButton
          edge="start"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          sx={{
            marginRight: "24px",
            marginLeft: "24px",
            ...props.open,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ ml: "auto" }}>
          <Link to="/dashboard">
            {/* <Button variant="contained" sx={{ mr: 2 }}>Dashboard</Button> */}
          </Link>
        </Box>
        <ProfileSection />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
