import {
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import * as Icon from "@mui/icons-material";

import FileInput from "./fileInput";
import ImageCropper from "./imageCropper";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useState, useEffect, useRef } from "react";

let croppedImage = "";
let setCroppedImage = "";
const Links = ({ data, updateData }) => {
  const inputRef = useRef();
  const [componentDetails, setComponentDetails] = useState(data);
  const [addLink, setAddLink] = useState(false);
  // const [linkComponents, setLinkComponents] = useState([])
  // const [deleteLink, setDeleteLink] = useState(false)
  const [isFileInputOpen, setFileInputOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageCropperOpen, setImageCropperOpen] = useState(false);
  const [croppedImage2, setCroppedImage2] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  croppedImage = croppedImage2;
  setCroppedImage = setCroppedImage2;
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    // You can perform additional actions with the selected file here
  };
  const onChooseImg = () => {
    inputRef.current.click();
  };
  const handleUploadClick = () => {
    setFileInputOpen(true);
  };
  const handleImageSelected = (imageData) => {
    // setSelectedImage(imageData);
    // if (imageData) {
    //     setImageUrl(URL.createObjectURL(imageData));
    //   }
    console.log("Selected Image Data:", imageData);
    setSelectedImage(imageData);
    if (imageData) {
      console.log("Creating URL for Image Data");
      try {
        const imageUrl = URL.createObjectURL(imageData);
        console.log("Image URL:", imageUrl);
        setImageUrl(imageUrl);
      } catch (error) {
        console.error("Error creating URL for Image Data:", error);
      }
    }
    setFileInputOpen(false); // Close the file input dialog
    setImageCropperOpen(true);
  };
  const handleCropDone = (croppedImageData) => {
    console.log("croppedImageData", croppedImageData);
    setCroppedImage(croppedImageData);
    setImageCropperOpen(false); // Close the image cropper dialog
    console.log("croppedImage", croppedImage);
  };
  const onCropDone = (croppedImageData) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = croppedImageData.width;
    canvasEle.height = croppedImageData.height;
    const context = canvasEle.getContext("2d");
    let imageObj1 = new Image();
    imageObj1.src = selectedImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        croppedImageData.x,
        croppedImageData.y,
        croppedImageData.width,
        croppedImageData.height,
        0,
        0,
        croppedImageData.width,
        croppedImageData.height
      );
      const dataURL = canvasEle.toDataURL("image/jpeg");
      setCroppedImage(dataURL);
      //   console.log("Image url",dataURL)
      setImageCropperOpen(false);
      //  setCurrentPage("img-cropped");
      handleChange({ img_link: dataURL });
    };
  };
  //   console.log(croppedImage,"croppedImage")
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const [linkRows, setLinkRows] = useState({
    id: null,
    heading: "",
    description: "",
    account_link: "",
    img_link: "",
  });
  const initLinkData = {
    id: null,
    heading: "",
    description: "",
    account_link: "",
    img_link: "",
  };
  const addMoreLinks = () => {
    const updatedData = [...componentDetails.customLink];
    updatedData.push(linkRows);
    updateComponentDetails({ ...componentDetails, customLink: updatedData });
    setAddLink(false);
    setLinkRows(initLinkData);
  };
  const updateComponentDetails = (data) => {
    setComponentDetails({ ...componentDetails, ...data });
    updateData({ ...componentDetails, ...data });
  };
  const handleInputChange = (index, newValue, key) => {
    // Create a copy of the data array
    const updatedData = [...componentDetails.customLink];
    // Update the value for the specific item
    updatedData[index][key] = newValue;
    // Update the state with the new data
    updateComponentDetails({ ...componentDetails, customLink: updatedData });
  };
  const handleChange = (newValue) => {
    setLinkRows({ ...linkRows, ...newValue });
  };
  const deleteLink = (index) => {
    const updatedData = [...componentDetails.customLink];
    updatedData.splice(index, 1);
    updateComponentDetails({ ...componentDetails, customLink: updatedData });
  };

  const handleDrop = (droppedItem) => {
    console.log("css");
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...componentDetails.customLink];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
   // setFiles(updatedList);
    setComponentDetails({ customLink: updatedList });
    updateComponentDetails({ ...componentDetails, customLink: updatedList });

};

  return (
    <>

      <Box className="pagecomp_detail" p={{ xs: 2, md: 1 }}>
        <Box className="pagecomp_content">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {componentDetails.customLink.map((linkData, index) => (
                    <Draggable key={"component_" + index} draggableId={"component_" + index} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <Paper
                            elevation={0}
                            sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
                          >
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid
                                item
                                md={12}
                                sx={{ alignItems: "center", display: "flex" }}
                              >
                                <IconButton>
                                  <Icon.DragIndicatorOutlined />
                                </IconButton>
                                <Box sx={{ mr: 2, ml: 2 }}>
                                  <img
                                    src={linkData.img_link}
                                    style={{
                                      height: "3rem",
                                      aspectRatio: 1,
                                      borderRadius: "50%",
                                    }}
                                  />
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        fullWidth
                                        key={index}
                                        name="heading"
                                        id="heading"
                                        label="Heading"
                                        variant="outlined"
                                        value={linkData.heading}
                                        // value={linkData.heading}
                                        //  onInput={(e) => { updateComponentDetails({ heading: e.target.value }) }}
                                        // onChange= {updateData1(index)}
                                        onChange={(e) =>
                                          handleInputChange(index, e.target.value, "heading")
                                        }
                                        sx={{ background: "#fff !important" }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        variant="outlined"
                                        value={linkData.description}
                                        // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                                        sx={{ background: "#fff !important" }}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            e.target.value,
                                            "description"
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <TextField
                                        fullWidth
                                        id="link"
                                        label="Link"
                                        variant="outlined"
                                        value={linkData.account_link}
                                        onInput={(e) => {
                                          updateComponentDetails({
                                            account_link: e.target.value,
                                          });
                                        }}
                                        sx={{ background: "#fff !important" }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                                <IconButton sx={{ ml: 2 }} onClick={() => deleteLink(index)}>
                                  <Icon.DeleteOutline />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {addLink ? (
            <Paper
              elevation={0}
              sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
            >
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  md={12}
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  <IconButton>
                    <Icon.DragIndicatorOutlined />
                  </IconButton>

                  <FileInput
                    onImageSelected={handleImageSelected}
                    chooseImage={onChooseImg}
                    reference={inputRef}
                  />

                  {isImageCropperOpen && (
                    <ImageCropper
                      image={selectedImage}
                      onCropDone={onCropDone}
                      onCropCancel={() => setImageCropperOpen(false)}
                    />
                  )}
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          name="heading"
                          id="heading"
                          label="Heading"
                          variant="outlined"
                          value={linkRows.heading}
                          onChange={(e) =>
                            handleChange({ heading: e.target.value })
                          }
                          sx={{ background: "#fff !important" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="description"
                          label="Description"
                          variant="outlined"
                          value={linkRows.description}
                          // onInput={(e) => { updateComponentDetails({ description: e.target.value }) }}
                          sx={{ background: "#fff !important" }}
                          onChange={(e) =>
                            handleChange({ description: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="link"
                          label="Link"
                          variant="outlined"
                          value={linkRows.account_link}
                          //   onInput={(e) => { updateComponentDetails({ account_link: e.target.value }) }}
                          sx={{ background: "#fff !important" }}
                          onChange={(e) =>
                            handleChange({ account_link: e.target.value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <div>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ color: "#fff", mt: 2, borderRadius: "16px" }}
                  onClick={addMoreLinks}
                >
                  Add
                </Button>
              </div>
            </Paper>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <IconButton onClick={() => setAddLink(true)}>
                <Icon.AddCircle />
              </IconButton>
            </Box>
          )}

        </Box>
        <Box className="pagecomp_ftr"></Box>
      </Box>

    </>
  );
};
const LinksPreview = ({ data, setImageCropperOpen, theme, component, customfont, blockstyles }) => {

  const [hasCroppedImage, setHasCroppedImage] = useState(false);
  const handleCropDone = (croppedImageData) => {
    setCroppedImage(croppedImageData);
    setHasCroppedImage(true); // Set to true when cropping is done
    setImageCropperOpen(false);
  };

  return (
    <Box>
      {!component.disable ?
        <Box className="afsagfaerhsrhs">
          {data?.customLink.map((link) => (
            <Box
              className="mob_prev_links"
              sx={{ mt: 2, mb: 2, background: theme.colors.link.background_color, p: 1, borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px` }}

            >
              <Link
                className="gsdfgsf"
                to={link.account_link}
                target="_blank"
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
              >
                <Box className="link_list_view" sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1, height: "48px", width: "48px" }}>

                    <img
                      src={hasCroppedImage ? croppedImage : link.img_link}
                      alt="Placeholder"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />

                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: theme.colors.link.text_color, fontFamily: customfont.font_family }}>{link.heading}</Typography>
                    <Typography variant="caption" sx={{ color: theme.colors.link.text_color, fontFamily: customfont.font_family }}>{link.description}</Typography>
                  </Box>
                  <Box sx={{ ml: "auto" }}>
                    <IconButton>
                      <Icon.NavigateNextOutlined />
                    </IconButton>
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
        : null}
    </Box>
  );
};
export { Links, LinksPreview };

