import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import * as Icon from "@mui/icons-material";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const OfferForm = () => {
  return (
    <>
      <Paper sx={{ mt: 2, mb: 2 }}>
        <Box className="pagecomp_detail" p={{ xs: 2, md: 3 }}>
          <Box
            className="pagecomp_header"
            sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ mr: 2 }}>
                <Icon.ArrowBack />
              </IconButton>
              Form
            </Box>
          </Box>
          <Box className="pagecomp_content">
            <TextField
              fullWidth
              id="description"
              label="Description"
              variant="outlined"
              sx={{ background: "#fff !important", mb: 3 }}
            />

            <Paper
              elevation={0}
              sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
            >
              <Box
                container
                sx={{ alignItems: "center", display: "flex", gap: "20px" }}
              >
                <IconButton>
                  <Icon.DragIndicatorOutlined />
                </IconButton>

                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  variant="outlined"
                  sx={{ background: "#fff !important" }}
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Applicable for"
                  >
                    <MenuItem value={10}>Name</MenuItem>
                    <MenuItem value={20}>Email</MenuItem>
                    <MenuItem value={30}>Phone</MenuItem>
                    <MenuItem value={30}>Custom</MenuItem>
                  </Select>
                </FormControl>

                <IconButton sx={{ ml: 2 }}>
                  <Icon.Settings />
                </IconButton>

                <IconButton sx={{ ml: 2 }}>
                  <Icon.DeleteOutline />
                </IconButton>
              </Box>
            </Paper>

           
           
            <Box sx={{ textAlign: 'center', mb:2 }}>
                            <IconButton>
                                <Icon.AddCircle fontSize="large" />
                            </IconButton>
                        </Box>
           
            
            <TextField
              fullWidth
              id="description"
              label="Form action name"
              defaultValue="Continue"
              variant="outlined"
              sx={{ background: "#fff !important", mb: 3 }}
            />
          </Box>
          <Box className="pagecomp_ftr"></Box>
        </Box>
      </Paper>
    </>
  );
};

export default OfferForm;
