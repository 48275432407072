import * as React from "react";
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from "react-router-dom";
import CommonTemplates from "../../common/components/templates";

const Home = () => {
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Box className="hm_head_comp">
                            <Container className="maxwidthlg">
                                <Box className="hm_head_comp_wrapper" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="h1" className="sph_heading">Showcase <br />
                                            <span style={{ fontFamily: 'georgia', fontStyle: 'italic', color: 'var(--highlight2)', fontWeight: 500, display: 'none' }}>your</span> your Business..

                                        </Typography>
                                    </Box>
                                    <Box sx={{ maxWidth: '424px' }}>
                                        <Typography sx={{ fontSize: '18px', mt: 2, mb: 3, color: '#fff' }}>🚀 Supercharge Your Business Growth
                                            with 40+ Unique Templates and QR Codes! </Typography>
                                        <Link to="/builder/content">
                                            <Button
                                                className="get_start_homepage"
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mb: 1, boxShadow: 0, mr: 3 }}
                                                size="large"

                                            >
                                                Get Started for Free!
                                            </Button>
                                        </Link>
                                        <Link color="hightlight2" style={{ color: 'var(--highlight2)', fontSize: '1.1rem' }} to="/builder/content">How it works?</Link>



                                    </Box>
                                </Box>




                            </Container>


                            <Grid container component="main" sx={{ m: 'auto' }}>
                                <Box
                                    className="sph_mobile_wrapper"
                                    sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Box className="sph_mobile_page">
                                        <img src="/assets/images/homepage/restaurant.png" />
                                    </Box>
                                    <Box className="sph_mobile_page">
                                        <img src="/assets/images/homepage/qrcode-stand.png" />
                                    </Box>
                                    <Box className="sph_mobile">
                                        <img
                                            src="/assets/images/homepage/mobile_hand.png"
                                            style={{ position: "relative", zIndex: 1 }}
                                        />

                                        <video
                                            muted
                                            autoPlay
                                            loop
                                            style={{
                                                position: "absolute",
                                                top: "12.7%",
                                                left: "14.7%",
                                                width: "50%",
                                            }}
                                        >
                                            <source
                                                src="/assets/images/homepage/mobile_3.webm"
                                                type="video/webm"
                                            />
                                            <source
                                                src="/assets/images/homepage/mobile_3.mp4"
                                                type="video/mp4"
                                            />
                                            Sorry, your browser doesn't support videos.
                                        </video>

                                        {/* <video src="/assets/images/homepage/mobile_3.mp4" playsinline="" autoplay="true" muted="" loop=""></video> */}
                                    </Box>
                                    <Box className="sph_mobile_page">
                                        <img src="/assets/images/homepage/optical_store.png" />
                                    </Box>
                                    <Box className="sph_mobile_page">
                                        <img src="/assets/images/homepage/cake_shop_1.png" />
                                    </Box>
                                </Box>
                            </Grid>

                            <Box className="marquee" sx={{ display: 'none' }}>
                                <ul className="track" style={{ display: "flex" }}>
                                    <li>
                                        <img src="/assets/images/homepage/plant_shop_qrcode.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/business_pages.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/sratchcard_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/whatsapp_marketing_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/plant_shop_qrcode.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/business_pages.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/sratchcard_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/whatsapp_marketing_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/plant_shop_qrcode.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/business_pages.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/sratchcard_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/whatsapp_marketing_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/plant_shop_qrcode.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/business_pages.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/sratchcard_1.png" />
                                    </li>
                                    <li>
                                        <img src="/assets/images/homepage/whatsapp_marketing_1.png" />
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Grid container className="maxwidthlg" sx={{ display: 'none' }}>
                <Box sx={{ mt: { xs: 6, md: 6 }, borderRadius: '16px' }}>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            className="hm_featurs_header"
                            sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                        >

                            <Typography variant="h4" sx={{ fontWeight: 600 }}>My business needs</Typography>
                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                                <Select
                                    value={age}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="business page">Business Page</MenuItem>
                                    <MenuItem value="offers qr code">Offers QR Code</MenuItem>
                                    <MenuItem value="payment qr code">Payment QR Code</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                        <Grid item xs={12} md={4}>
                            <Box className="hm_feature_box">
                                <Typography variant="h6" sx={{ fontWeight: '600', mb: { xs: 1, md: 3 } }}>Page / QR Code Builder</Typography>
                                <ul className="hm_feature_list">
                                    <li><Icon.VerticalSplitOutlined /> Drag Drop Builder</li>
                                    <li><Icon.QrCodeOutlined /> QR Code Builder</li>
                                    <li><Icon.StarHalfOutlined /> Reviews</li>
                                    <li><Icon.InsertPhotoOutlined /> Photos</li>
                                    <li><Icon.Inventory2Outlined /> Products Catlog</li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="hm_feature_box">
                                <Typography variant="h6" sx={{ fontWeight: '600', mb: { xs: 1, md: 3 } }}>Connect Customers</Typography>
                                <ul className="hm_feature_list">
                                    <li><Icon.LocalOfferOutlined /> Offer Templates</li>
                                    <li><Icon.CardGiftcardOutlined /> Scratch Cards</li>
                                    <li><Icon.LoyaltyOutlined /> Loyalty Points</li>
                                    <li><Icon.FeedOutlined /> Forms</li>
                                    <li><Icon.InterestsOutlined /> Social Media</li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="hm_feature_box" sx={{ borderRight: '0px !important' }}>
                                <Typography variant="h6" sx={{ fontWeight: '600', mb: { xs: 1, md: 3 } }}>Manage Customers</Typography>
                                <ul className="hm_feature_list">
                                    <li><Icon.ContactsOutlined /> Lead Managment</li>
                                    <li><Icon.InterestsOutlined /> Automation</li>
                                    <li><Icon.ContactEmergencyOutlined /> Contact Center</li>
                                    <li><Icon.CampaignOutlined /> WhatsApp Marketing</li>
                                    <li><Icon.MarkEmailUnreadOutlined /> Email Marketing</li>
                                </ul>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>


            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Box className="sp_solutions">
                    <Grid container>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h5" sx={{ mb: 2, fontWeight: '500', color: '#fff' }}>Built for any small business</Typography>
                                    <Typography variant="h3" sx={{ color: '#fff', fontWeight: '500', mb: 5 }}>Simplified Solutions for Business Growth:<br />
                                        Easy-to-Use Tools</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box className="sp_features_box sp_f_box_1" sx={{ background: 'var(--dark)' }}>
                                    <Typography variant="h4">Link<br /> in bio</Typography>
                                    <Typography variant="body1">
                                        Showcase your business in one link
                                    </Typography>

                                    <Box sx={{ textAlign: "left", mt: 3 }}>
                                        <Chip
                                            className="chips_space"
                                            label="Business Page"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Personal Page"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Sell online products"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Social Links"
                                        />
                                    </Box>

                                    <Box className="spf_image" sx={{ mb: 5, mr: 5 }}>
                                        <img src="/assets/images/homepage/link_in_bio.png" />
                                    </Box>
                                    <Box className="sp_f_box_overlay">
                                        <Link to="/builder/content" style={{ cursor: 'pointer' }} >
                                            <Button
                                                className=""
                                                variant="contained"
                                                color="highlight2"
                                                sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                                size="large"

                                            >
                                                Create Now <Icon.ArrowForward sx={{ ml: 1, mr: '-8px' }} />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className="sp_features_box sp_f_box_2">
                                    <Typography variant="h4">QR<br />CODES</Typography>
                                    <Typography variant="body1">
                                        Solutions for any business
                                    </Typography>

                                    <Box sx={{ textAlign: "left", mt: 3 }}>
                                        <Chip
                                            className="chips_space"
                                            label="Shop QR Code"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Offers QR Code"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Payment QR Code"
                                        />

                                    </Box>

                                    <Box className="spf_image" sx={{ mb: 5, mr: 5 }}>
                                        <img src="/assets/images/homepage/qrcode_for_shop.png" />
                                    </Box>
                                    <Box className="sp_f_box_overlay">
                                        <Link to="/builder/content" style={{ cursor: 'pointer' }} >
                                            <Button
                                                className=""
                                                variant="contained"
                                                color="highlight2"
                                                sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                                size="large"

                                            >
                                                Create Now <Icon.ArrowForward sx={{ ml: 1, mr: '-8px' }} />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className="sp_features_box sp_f_box_3">
                                    <Typography variant="h4">MANAGE/<br />MARKETING</Typography>
                                    <Typography variant="body1">
                                        Send messages your customers
                                    </Typography>

                                    <Box sx={{ textAlign: "left", mt: 3 }}>
                                        <Chip
                                            className="chips_space"
                                            label="Contact Center"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="WhatsApp Marketing"
                                        />
                                        <Chip
                                            className="chips_space"
                                            label="Email Marketing"
                                        />

                                    </Box>

                                    <Box className="spf_image" sx={{ mb: 5, mr: 5 }}>
                                        <img src="/assets/images/homepage/whatsapp_marketin.png" />
                                    </Box>
                                    <Box className="sp_f_box_overlay">
                                        <Link to="/builder/content" style={{ cursor: 'pointer' }} >
                                            <Button
                                                className=""
                                                variant="contained"
                                                color="highlight2"
                                                sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                                size="large"

                                            >
                                                Create Now <Icon.ArrowForward sx={{ ml: 1, mr: '-8px' }} />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
            </Container>

            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Box sx={{ pt: 10, pb: 10 }}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{}}>
                                <Typography variant="h5" sx={{ mb: 2, fontWeight: '500' }}>Empower Your Growth</Typography>
                                <Typography variant="h3" sx={{ color: 'var(--dark)', fontWeight: '500', mb: 5 }}>The Ultimate Microsite Builder <br />
                                    Engineered for Success</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Box sx={{ borderBottom: 'solid 4px #ddd', mt: 0, mb: 8 }}></Box>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                            <Box className="hm_builder_f_img">
                                <img src="/assets/images/homepage/getspurt_builder.png" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box className="hm_builder_f_box hmbf_box_1">
                                        <Icon.Tune />
                                        <Typography variant="h5">More customizable</Typography>
                                        <Typography variant="body">More options to design your page, and have it match your brand</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className="hm_builder_f_box hmbf_box_2">
                                        <Icon.ElectricBolt />
                                        <Typography variant="h5">More powerful</Typography>
                                        <Typography variant="body">More options to design your page, and have it match your brand</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box className="hm_builder_f_box hmbf_box_3">
                                        <Icon.AdsClickOutlined />
                                        <Typography variant="h5">Simple and easy</Typography>
                                        <Typography variant="body">More options to design your page, and have it match your brand</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box className="hm_builder_f_box hmbf_box_4">
                                        <Icon.MonetizationOnOutlined />
                                        <Typography variant="h5">More affordable</Typography>
                                        <Typography variant="body">More options to design your page, and have it match your brand</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>






            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Box className="sp_templates">
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={12}>
                            <CommonTemplates />
                        </Grid>
                    </Grid>
                </Box>
            </Container>


            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Box className="sp_pricing">
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{}}>
                                <Typography variant="h3" sx={{ fontWeight: '500', mb: 2 }}>Plans for All Business </Typography>
                                <Typography variant="h5" sx={{ fontWeight: '500', mb: 0 }}><Link to="/pricing" style={{ cursor: 'pointer', color: 'var(--primary-dark)' }} >See Pricing and plans </Link></Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box className="sp_pricing_plan">
                                <Typography variant="h5" sx={{ fontWeight: '700', mb: 1 }}>Free</Typography>
                                <Typography variant="body1">Create, edit, and share.</Typography>
                                <Typography variant="h3"><span style={{ fontSize: '16px', verticalAlign: 'super' }}>$</span>0<span style={{ fontSize: '16px' }}>/ mo</span></Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>Free forever</Typography>
                                <Link to="/login" style={{ cursor: 'pointer' }} >
                                    <Button
                                        className=""
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                        size="large"

                                    >
                                        Get Started
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="sp_pricing_plan" sx={{ background: 'var(--primary-dark) !important', color: '#fff' }}>
                                <Typography variant="h5" sx={{ fontWeight: '700', mb: 1 }}>👑 Premium</Typography>
                                <Typography variant="body1">Unlock all premium features </Typography>
                                <Typography variant="h3"><span style={{ fontSize: '16px', verticalAlign: 'super' }}>$</span>8<span style={{ fontSize: '16px' }}>/ mo</span></Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>$76.8 billed yearly</Typography>
                                <Link to="/login" style={{ cursor: 'pointer' }} >
                                    <Button
                                        className=""
                                        variant="contained"
                                        color="highlight2"
                                        sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>


                </Box>
            </Container>



           
            <Container className="maxwidthlg" sx={{ m: 'auto' }}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Box className="sp_key_features">
                            <Typography variant="h2" className="sp_key_f_title">
                                Build and get spurt growth
                            </Typography>
                            <Box sx={{ textAlign: "center" }}>
                                <Chip
                                    className="chips_space chips_green"
                                    label="Time-saving"
                                    variant="outlined"
                                />
                                <Chip
                                    className="chips_space chips_green"
                                    label="Cost-effective"
                                    variant="outlined"
                                />
                                <Chip
                                    className="chips_space chips_green"
                                    label="User-friendly"
                                    variant="outlined"
                                />
                                <Chip
                                    className="chips_space chips_green"
                                    label="Professional appearance"
                                    variant="outlined"
                                />
                                <Chip
                                    className="chips_space chips_green"
                                    label="Customizability"
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                className="sph_calltoaction"
                                sx={{ alignContent: "center", textAlign: "center" }}
                            >
                                <Button
                                    className="button_dark"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 1, boxShadow: 0 }}
                                >
                                    Try for free <Icon.ArrowForward sx={{ ml: 2 }} />
                                </Button>
                                <Typography variant="caption">Build in Minutes</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Home;
