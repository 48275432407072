import { Box, Button, Chip, Container, Grid, Typography } from "@mui/material";
import "./style.css";
import { useState } from 'react';
import { Link } from "react-router-dom";


const tempList = [
    {
        type: 1,
        title: "Business Page",
        templates: [
            {
                id: 1,
                url: "/assets/images/templates/temp_1_restaurant.png",
            },
            {
                id: 2,
                url: "/assets/images/templates/temp_2_plant_shop.png",
            },
            {
                id: 3,
                url: "/assets/images/templates/temp_3_optical_store.png",
            },
            {
                id: 4,
                url: "/assets/images/templates/temp_4_cake_shop.png",
            },
            {
                id: 5,
                url: "/assets/images/templates/temp_5_fashion_store.png",
            }
        ]
    },
    {
        type: 2,
        title: "Sell Online",
        templates: [
            {
                id: 1,
                url: "/assets/images/templates/temp_3_optical_store.png",
            },
            {
                id: 2,
                url: "/assets/images/templates/temp_5_fashion_store.png",
            },
            {
                id: 3,
                url: "/assets/images/templates/temp_1_restaurant.png",
            },
            {
                id: 4,
                url: "/assets/images/templates/temp_2_plant_shop.png",
            },
            {
                id: 5,
                url: "/assets/images/templates/temp_4_cake_shop.png",
            }
        ]
    },
    {
        type: 3,
        title: "QR Code",
        templates: [
            {
                id: 1,
                url: "/assets/images/templates/temp_qr_1.png",
            },
            {
                id: 2,
                url: "/assets/images/templates/temp_qr_2.png",
            },
            {
                id: 3,
                url: "/assets/images/templates/temp_qr_3.png",
            },
            {
                id: 4,
                url: "/assets/images/templates/temp_qr_4.png",
            },
            {
                id: 5,
                url: "/assets/images/templates/temp_qr_5.png",
            }
        ]
    },
    {
        type: 4,
        title: "Affiliate Links",
        templates: [
            {
                id: 1,
                url: "/assets/images/templates/temp_affi_1.png",
            },
            {
                id: 2,
                url: "/assets/images/templates/temp_affi_2.png",
            },

        ]
    },
]






const Templates = () => {
    const [templateList, settemplateList] = useState(tempList);

    const [templatetype, setTemplateType] = useState(1);


    return (
        <>
            <Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h3" sx={{ fontWeight: "500", mb: 1 }}>What do you want to make?</Typography>
                    <Typography variant="h6" sx={{ mb: 0 }}>Select right template for your business</Typography>
                </Box>

                <Box sx={{ textAlign: 'center',  mb: 6 }}>

                    <Box sx={{ mt: 6, mb: 6 }}>
                        <Chip variant="outlined" label="Business Page" className={`template_preview ${templatetype == 1 ? "selected_template" : ""}`} sx={{ fontSize: '1.1rem', mb: 2, p: 2.5, mr: 2, borderRadius:'24px' }} onClick={() => setTemplateType(1)} />
                        <Chip variant="outlined" label="Sell Online" className={`template_preview ${templatetype == 2 ? "selected_template" : ""}`} sx={{ fontSize: '1.1rem', mb: 2, p: 2.5, mr: 2, borderRadius:'24px' }} onClick={() => setTemplateType(2)} />
                        <Chip variant="outlined" label="QR Code" className={`template_preview ${templatetype == 3 ? "selected_template" : ""}`} sx={{ fontSize: '1.1rem', mb: 2, p: 2.5, mr: 2, borderRadius:'24px' }} onClick={() => setTemplateType(3)} />
                        <Chip variant="outlined" label="Affiliate Links" className={`template_preview ${templatetype == 4 ? "selected_template" : ""}`} sx={{ fontSize: '1.1rem', mb: 2, p: 2.5, mr: 2, borderRadius:'24px' }} onClick={() => setTemplateType(4)} />
                    </Box>
                </Box>


                <ul className="temp_list">
                    {templatetype == 1 &&
                        templateList.filter((temp) => temp.type == 1)[0].templates.map((template) => (



                            <li>
                                <img src={template.url}></img>
                                <Link to="/builder">
                                <Button variant="contained">Start Editing</Button>
                                </Link>
                                <Button variant="contained">Preview</Button>
                            </li>


                        ))
                    }

                    {templatetype == 2 &&
                        templateList.filter((temp) => temp.type == 2)[0].templates.map((template) => (



                            <li>
                                <img src={template.url}></img>
                                <Button variant="contained">Start Editing</Button>
                                <Button variant="contained">Preview</Button>
                            </li>


                        ))
                    }

                    {templatetype == 3 &&
                        templateList.filter((temp) => temp.type == 3)[0].templates.map((template) => (



                            <li>
                                <img src={template.url}></img>
                                <Button variant="contained">Start Editing</Button>
                                <Button variant="contained">Preview</Button>
                            </li>


                        ))
                    }

                    {templatetype == 4 &&
                        templateList.filter((temp) => temp.type == 4)[0].templates.map((template) => (



                            <li>
                                <img src={template.url}></img>
                                <Button variant="contained">Start Editing</Button>
                                <Button variant="contained">Preview</Button>
                            </li>


                        ))
                    }
                </ul>
            </Box>
        </>
    );
};

export default Templates;
