import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import ProfileSection from "./UserProfile";
import { UserAuth } from "../../../store/context/user";
import { empty } from "../../../utils/common";
import Signup from "../../../common/components/authComponents/signup";
import Signin from "../../../common/components/authComponents/signin";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - 0px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props) => {
  const [openSignUp, setOpenSignup] = useState(false);
  const [openSignIn, setOpenSignin] = useState(false);
  const { user } = UserAuth;
  const navItems = [
    {
      label: "Templates",
      href: "/templates",
      onClick: null,
    },
    {
      label: "Pricing",
      href: "/pricing",
      onClick: null,
    },
    {
      label: "About Us",
      href: "/aboutus",
      onClick: null,
    }
  ];

  const openSignUpHandler = () => {
    setOpenSignup(true);
  };
  const openSignInHandler = () => {
    setOpenSignin(true);
  };

  if (empty(user)) {
    navItems.push({
      label: "Sign In",
      onClick: openSignInHandler,
      href: "#",
      type: "button",
    });
    navItems.push({
      label: "Sign Up",
      onClick: openSignUpHandler,
      href: "#",
      type: "button",
    });
  }

  return (
    <AppBar
      className="sp_appbr"
      position="fixed"
      color="default"
      elevation={0}
      sx={{ borderBottom: "1px solid #00000009" }}
      open={props.openSignUp}
    >
      <Toolbar className="maxwidthlg"  sx={{ flexWrap: "wrap",  width:'100%', justifyContent:'space-between', margin:'auto', paddingLeft:'16px !important', paddingRight:'16px !important' }}>
        <Typography
          className="sphhdr_logo"
          variant="h6"
          color="inherit"
        >
          <Link to="/">
            <img
              src="/assets/images/homepage/getspurt.svg"
              alt="getspurt"
            />
          </Link>
        </Typography>

        <nav className="sph_nav">
          {navItems.map((item, index) => {
            return item.type == "button" ? (
              <Link
                to={item.href}
                key={"header_" + index}
                onClick={item.onClick}
              >
                <Button
				color="highlight2"
                  variant="contained"
                  size="large"
                  style={{
                   // backgroundColor: "#000",
                   // backgroundColor: "#000",
                    //color: "#fff",
                    borderRadius: 32,
                  }}
                >
                  {item.label}
                </Button>
              </Link>
            ) : (
              <Link
                to={item.href}
                key={"header_" + index}
                onClick={item.onClick}
              >
                {item.label}
              </Link>
            );
          })}
        </nav>
        {!empty(user) ? <ProfileSection /> : <></>}
      </Toolbar>
      {/* <Signup {...{ open, setOpen }} /> */}
      <Signin {...{ openSignIn, setOpenSignin, setOpenSignup }} />
      <Signup {...{ openSignUp, setOpenSignup }} />
    </AppBar>
  );
};

export default Header;
