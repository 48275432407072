import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as Icon from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Typography,
  Button,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PhoneTemplateExample from "../../assets/images/business/phoneTemplateExample.svg";
import Template1 from "../../assets/images/business/template1.svg";
import Template2 from "../../assets/images/business/template2.svg";
import Template3 from "../../assets/images/business/template3.svg";
import StoreBanner from "../../assets/images/business/storeBanner.svg";
import Scanner from "../../assets/images/business/scanner.svg";
import GetStartedModal from "./getStartedModal";

const headerData = [
  {
    heading: "Business Page",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    icon: <Icon.ArrowBack />,
  },
];

const templateImages = [
  {
    image: Template1,
  },
  {
    image: Template2,
  },
  {
    image: Template3,
  },
];

const listData = [
  {
    data: "Create beautiful content with hundreds of templates",
  },
  {
    data: "Edit your photos and videos with filters and effects",
  },
  {
    data: " Plan your Instagram feed in advance",
  },
  {
    data: "Create a Bio Site for your link in Bio",
  },
];

const ScannerData = [
  {
    heading1: "Scan for ",
    heading2: "Offer / Payment",
    storeImg: StoreBanner,
    scannerImg: Scanner,
    shopName: "B. Cake",
  },
];

const Business = (props) => {
  const [show, setShow] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const showScanner = () => {
    setShow(!show);
  };
  const openGetStartedModalHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={7}>
        <Grid item xs={12} sm={8} md={8}>
          <Box container>
            {headerData.map((item) => {
              return (
                <Box>
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      marginBottom: 4,
                    }}
                  >
                    <Grid md={1}>
                      <IconButton aria-label="back" sx={{ pl: 0 }}>
                        {item.icon}
                      </IconButton>
                    </Grid>

                    <Grid md={6}>
                      <Typography variant="h5">{item.heading}</Typography>
                    </Grid>
                  </Grid>
                  <Container
                    sx={{
                      marginBottom: 4,
                      paddingLeft: "0px !important",
                    }}
                  >
                    <Typography variant="body1">{item.para}</Typography>
                  </Container>
                  <Divider sx={{ mb: 4 }}></Divider>
                </Box>
              );
            })}
          </Box>
          <Box container>
            <Typography variant="h6" sx={{ mb: "10px" }}>
              Templates
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                mb: 5,
                // "& :nth-of-type(2) ": {
                //   paddingInline: "1rem",
                // },
              }}
            >
              {templateImages.map((template) => {
                return (
                  <Grid xs={12} sm={4} md={4} item position="relative">
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover .overlay": {
                          opacity: 1,
                        },
                      }}
                    >
                      <img
                        src={template.image}
                        style={{
                          width: "100%",
                          borderRadius: 16,
                        }}
                        alt="Template"
                      ></img>

                      <Grid
                        className="overlay"
                        container
                        sx={{
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          transition: "opacity 0.3s ease",
                          borderRadius: 4,
                          padding: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "272px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="large"
                            onClick={openGetStartedModalHandler}
                            sx={{
                              // marginInline: "auto",
                              color: "#fff",
                              textTransform: "capitalize",
                              padding: "11px 16px",
                              marginBottom: "7px",
                              width: "196px",
                              height: "60px",
                              "&:hover": {
                                backgroundColor: "#ddf04b",
                                border: "1px solid black",
                                color: "#000",
                              },
                            }}
                          >
                            Use Template
                          </Button>
                          <Button
                            variant="contained"
                            size="large"
                            sx={{
                              // marginInline: "auto",
                              color: "#000",
                              textTransform: "capitalize",
                              padding: "11px 16px",
                              background: "#fff",
                              width: "196px",
                              height: "60px",
                              "&:hover": {
                                backgroundColor: "#ddf04b",
                                border: "1px solid black",
                                color: "#000",
                              },
                            }}
                          >
                            Preview Template
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Divider sx={{ mb: 4 }}></Divider>
          </Box>
          <Box>
            <Typography variant="h6">All in one</Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
              }}
            >
              {listData.map((item) => {
                return (
                  <ListItem
                    sx={{ display: "list-item", pl: 1, paddingBlock: 0 }}
                  >
                    <ListItemText variant="body1" primary={item.data} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Grid>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                showScanner();
              }}
              style={{
                borderRadius: 8,
                marginBottom: 28,
              }}
              startIcon={show ? <Icon.QrCode /> : <Icon.RemoveRedEyeOutlined />}
            >
              <Typography
                variant="subtitle1"
                style={{ textTransform: "capitalize" }}
              >
                {show ? "Experience on mobile" : "Preview"}
              </Typography>
            </Button>
          </Grid>
          {show ? (
            <Box>
              <img
                src={PhoneTemplateExample}
                style={{ width: "100%" }}
                alt="Phone Template Example"
              ></img>
            </Box>
          ) : (
            <Box>
              {ScannerData.map((item) => {
                return (
                  <Box sx={{ position: "relative" }}>
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={item.storeImg}
                        alt="Store banner"
                        style={{ width: "100%" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          "& > *": {
                            fontWeight: "bold",
                          },
                        }}
                      >
                        <Typography variant="h6">{item.heading1}</Typography>
                        <Typography variant="h6">{item.heading2}</Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          width: "100%",
                          padding: "28px",
                        }}
                      >
                        <img
                          src={item.scannerImg}
                          alt="Scanner"
                          style={{ marginInline: "auto" }}
                        ></img>
                      </Box>
                      <Button
                        size="large"
                        variant="outlined"
                        fullWidth
                        sx={{
                          background: "#EAFF00",
                          color: "#000",
                          paddingBlock: "12px",
                          borderRadius: "16px",
                          border: "none",
                          "&:hover": {
                            backgroundColor: "#EAFF00",
                            color: "#000",
                            border: "none",
                            cursor: "default",
                          },
                        }}
                      >
                        <Typography variant="h4" textTransform="capitalize">
                          {item.shopName}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Grid>
      </Grid>
      <GetStartedModal {...{ open, handleClose }} />
    </>
  );
};

export default Business;
