import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Box, Button, Chip, Container, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
const Pricing = () => {
    return (
        <>
            <Container
                disableGutters
                maxWidth="lg"
                component="main"
                sx={{ pt: 8, pb: 2, px: 5 }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h3" sx={{ fontWeight: "500", mb: 3 }}>Simple plans for everyone</Typography>
                    <Box sx={{ background: 'var(--highlight2)', borderRadius: '60px', margin: 'auto', maxWidth: '500px' }}>
                        <Typography variant="body1" sx={{ p: 1 }} > 💥🎉 Get 30% OFF our early plan during this callenging time.</Typography>
                    </Box>

                </Box>
                <Box sx={{ textAlign: 'center', mt: 10, mb: 10 }}>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Box className="sp_pricing_plan" sx={{ background: 'var(--lighter) !important' }}>
                                <Typography variant="h5" sx={{ fontWeight: '700', mb: 1 }}>Free</Typography>
                                <Typography variant="body1">Create, edit, and share.</Typography>
                                <Typography variant="h3"><span style={{ fontSize: '16px', verticalAlign: 'super' }}>$</span>0<span style={{ fontSize: '16px' }}>/ mo</span></Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>Free forever</Typography>
                                <Link to="/login" style={{ cursor: 'pointer' }} >
                                    <Button
                                        className=""
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                        size="large"

                                    >
                                        Get Started
                                    </Button>
                                </Link>

                                <ul className="tickmark">
                                    <li>Create 1 Page</li>
                                    <li>Monthly Scan 1000</li>
                                    <li>50 MB Colud Storage</li>
                                    <li>Free Components </li>
                                    <li>1000 Contacts </li>
                                    <li>100 QR Codes </li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className="sp_pricing_plan" sx={{ background: 'var(--primary-dark) !important', color: '#fff' }}>
                                <Typography variant="h5" sx={{ fontWeight: '700', mb: 1 }}>👑 Premium</Typography>
                                <Typography variant="body1">Unlock all premium features </Typography>
                                <Typography variant="h3"><span style={{ fontSize: '16px', verticalAlign: 'super' }}>$</span>8<span style={{ fontSize: '16px' }}>/ mo</span></Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>$76.8 billed yearly</Typography>
                                <Link to="/login" style={{ cursor: 'pointer' }} >
                                    <Button
                                        className=""
                                        variant="contained"
                                        color="highlight2"
                                        sx={{ mb: 1, boxShadow: 0, borderRadius: '32px' }}
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </Link>

                                <ul className="tickmark">
                                    <li>Create 5 Pages</li>
                                    <li>Monthly Scan 20,000</li>
                                    <li>500 MB Colud Storage</li>
                                    <li>All Premium Components</li>
                                    <li>100,000 Contacts </li>
                                    <li>200 QR Codes </li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{display:'flex', gap:8, justifyContent:'space-around'}}>
                                <Typography variant="caption" sx={{display:'flex', alignItems:'center'}}>
                                    <Icon.GppGood color="secondary"  />Your information is protected by 256-bit SSL encryption.
                                </Typography>
                                <Typography variant="caption" sx={{display:'flex', alignItems:'center'}}>
                                    <Icon.MonetizationOnOutlined />Prices in US dollars and exclude local taxes
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box sx={{ mb: 10 }}>
                    <Accordion defaultExpanded >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h5">Do you offer custom plans?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            Yes, you just need to send us a note with your requirements and questions at sales [at] getspurt [dot] com. You will hear back from us within 24 hours.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h5">Is my data safe?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            We’re fervent about keeping your data safe and secure. Our network security system employs the latest
                            encryption and intrusion detection and prevention technologies.
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h5">What types of payment do you accept?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            We accept all major debit/credit cards for online payment.
                        </AccordionDetails>
                    </Accordion>



                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h5">Have more questions?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            You can connect with us instantly through live chat (24x7) or by writing to us at   <Link to='javascript:void(0)' style={{ cursor: 'pointer' }}
                                onClick={() => window.location = 'mailto:yourmail@domain.com'}>
                                sales@getspurt.com</Link>
                        </AccordionDetails>
                    </Accordion>
                </Box>



            </Container >
        </>
    );
};

export default Pricing;
