import * as React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Verified from "../../assets/images/verified_black_24dp 1.svg";
import ColoredTemplate from "../../assets/images/colored_template.svg";
import PlainTemplate1 from "../../assets/images/plain_template1.svg";
import PlainTemplate2 from "../../assets/images/plain_template2.svg";
import PlainTemplate3 from "../../assets/images/plain_template3.svg";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const templates = [
  {
    image: ColoredTemplate,
    imgcaption: "Business Page",
  },
  {
    image: PlainTemplate1,
    imgcaption: "Sell online",
  },
  {
    image: PlainTemplate2,
    imgcaption: "QR Code for business",
  },
  {
    image: PlainTemplate3,
    imgcaption: "Affiliate Links",
  },
];

const Getstarted = () => {
  return (
    <>
      <Grid item xs={12} sm={8}>
        <ThemeProvider theme={theme}>
          <Typography variant="h3">Welcome to SpurtMe! 👋</Typography>
        </ThemeProvider>
      </Grid>

      <Box
        container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexGrow: 1,
          height: 80,
          borderRadius: "16px",
          backgroundColor: "#fff",
          mt: 1.6,
          pl: 5,
          alignItems: "center",
        }}
      >
        <Grid container spacing={0} alignItems="center" gap="3rem">
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Starter Guide</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
          >
            <img src={Verified} alt="" />
            <Typography variant="subtitle1">
              Congratulations, you've completed all your tasks!
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        flexDirection="column"
        alignItems="flex-start"
        sx={{ mt: 3, mb: 1 }}
      >
        <Typography variant="h4">Templates</Typography>
        <Typography variant="body1">
          Select right template for your business
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        {templates.map((temp) => {
          return (
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={0} style={{ padding: 16, borderRadius: 16 }}>
                <img src={temp.image} alt="" style={{ width: "100%" }} />
                <Typography variant="h6" sx={{ mt: "1.2rem" }}>
                  {temp.imgcaption}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Getstarted;
