import * as React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import PhoneTemplateExample from "../../../assets/images/business/phoneTemplateExample.svg";

const getStartedModal = ({ open, handleClose }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: "#000",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="subtitle1" sx={{ marginLeft: "20px" }}>
            Back To Template
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} sm={8} md={8}>
                <TextField
                  required
                  margin="dense"
                  id="text"
                  label="https://www.sptme.in/"
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginBottom: 20,
                    color: "#7D7D7D",
                  }}
                />

                <Button
                  fullWidth
                  size="large"
                  sx={{
                    color: "#fff",
                    background: "#000",
                    marginBottom: "24px",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    textTransform="capitalize"
                    sx={{ marginRight: 1, paddingBlock: "6px" }}
                  >
                    Get Started
                  </Typography>
                  <ArrowForwardTwoToneIcon />
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <img
                  src={PhoneTemplateExample}
                  alt="Template example"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default getStartedModal;
