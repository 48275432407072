import * as React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Slider,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import InputColor from "react-input-color";
import * as Icon from "@mui/icons-material";
const label = { inputProps: { "aria-label": "Size switch demo" } };



const Design = (props, blockstyles, setBlockStyle) => {
 

  const [color, setColor] = React.useState({});
  
  const [selectedTheme, setSelectedThemes] = React.useState(props.selectedTheme);
  const [selectedThemesColors, setSelectedThemesColors] = React.useState(selectedTheme.colors)
  const updateCustomTheme = (type, typekey, color) => {
    const colorSelected = color.hex;
    setSelectedThemesColors(current => {
      current[type][typekey] = colorSelected;
      return current;
    })
    setSelectedThemes({ ...selectedTheme, colors: selectedThemesColors })
    props.setThemes(selectedTheme);
  }

  const [cornerRadius, setCornerRadius] = React.useState(0);

  const [selectedFont, setSelectedFont] = React.useState(props.selectedFont);


  const [checkedshadow, setCheckedShadow] = React.useState(true);

  const [checkedoutline, setCheckedOutine] = React.useState(true);
  const handleCheck = (event, key) => {
    if(key == "block_shadow") {
      setCheckedShadow(event.target.checked);
      props.setBlockStyle({...props.blockstyles, block_shadow:event.target.checked  });
  } else {
    setCheckedOutine(event.target.checked);
      props.setBlockStyle({...props.blockstyles, outline:event.target.checked  });
  }
  };

  const handleChangeDiscount = (event, newValue) => {
    setCornerRadius(newValue);
   props.setBlockStyle({...props.blockstyles, corner_radius:newValue})
    // setNewOffer({...newOffer, discount:newValue})
  };

  

  

  const handleChange = (value) => {
  
   props.setBlockStyle({...props.blockstyles, type:value  });
 
  };

  const applyTheme = (themeid) => {
    const selectedTheme = props.themes.find(theme => theme.id == themeid)
    setSelectedThemes(selectedTheme);

    props.setThemes(selectedTheme);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={12}>
          <Paper sx={{ p: 3, mb: 2 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>Theme</Typography>
              <Box sx={{ display: "flex", gap: "16px", justifyContent: 'space-between' }}>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 1 ? "solid 1px #000" : "solid 1px #F7F8F3" }}>
                  <Box

                    sx={{ background: "#fff", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }}
                    onClick={() => applyTheme(1)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 2 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#000", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }}
                    onClick={() => applyTheme(2)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 3 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#F74C4C", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }}
                    onClick={() => applyTheme(3)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 4 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#F6934C", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(4)}></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 5 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#4CCDF6", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(5)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 6 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#4C5DF6", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(6)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 7 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >

                  <Box

                    sx={{ background: "#824CF6", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(7)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 8 ? "solid 1px #000" : "solid 1px #F7F8F3" }}  >
                  <Box

                    sx={{ background: "#FFDB21", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(8)}
                  ></Box>
                </Box>
                <Box className="color_selector" sx={{ border: selectedTheme.id == 9 ? "solid 1px #000" : "solid 1px #F7F8F3" }} >
                  <Box

                    sx={{ background: "#FFD0E1", border: "solid 1px #000", height: "48px", width: "48px", borderRadius: "30px", cursor: "pointer" }} onClick={() => applyTheme(9)}
                  ></Box>
                </Box>
                <Box
                  className="color_selector"
                  onClick={() => applyTheme("custom")}
                //sx={{ background: "#fff", height: 48, width: 48, borderRadius: 40 }}
                >

                  <img src='/assets/images/builder/multi_color_circle.png' />
                </Box>
              </Box>
              {selectedTheme.id == "custom" &&
                <Box>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 3, mb: 1 }}>Page background color</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>

                      <Box
                        className="color_circle"
                        sx={{ mr: "16px" }}
                      >


                        <InputColor
                          initialValue={selectedTheme.colors.main.background_color}
                          onChange={(e) => { setColor(e); updateCustomTheme("main", "background_color", e); }}
                          placement="right"
                          style={{
                            width: 46,
                            height: 46,
                            borderRadius: 40,
                            border: "none",
                            padding: 0
                          }}
                          className="color-picker-component"
                        />


                      </Box>
                      <Typography variant="subtitle1">Background color</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 3, mb: 1 }}>Heading color</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>

                      <Box
                        className="color_circle"
                        sx={{ mr: "16px" }}
                      >


                        <InputColor
                          initialValue={selectedTheme.colors.heading.text_color}
                          //initialValue="#000"
                          onChange={(e) => { setColor(e); updateCustomTheme("heading", "text_color", e); }}
                          placement="right"
                          style={{
                            width: 46,
                            height: 46,
                            borderRadius: 40,
                            border: "none",
                            padding: 0
                          }}
                          className="color-picker-component"
                        />


                      </Box>
                      <Typography variant="subtitle1">Heading text color</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 3, mb: 1 }}>Link Block colors</Typography>
                    <Grid container sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.link.background_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("link", "background_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Link background</Typography>
                      </Grid>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.link.text_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("link", "text_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Link text</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 3, mb: 1 }}>Block colors</Typography>
                    <Grid container sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.block.background_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("block", "background_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Block background</Typography>
                      </Grid>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.block.text_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("block", "text_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Block text </Typography>
                      </Grid>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.block.button_background_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("block", "button_background_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Button background</Typography>
                      </Grid>
                      <Grid item sm={12} md={6} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Box
                          className="color_circle"
                          sx={{ mr: "16px" }}
                        >
                          <InputColor
                            initialValue={selectedTheme.colors.block.button_text_color}
                            onChange={(e) => { setColor(e); updateCustomTheme("block", "button_text_color", e); }}
                            placement="right"
                            style={{
                              width: 46,
                              height: 46,
                              borderRadius: 40,
                              border: "none",
                              padding: 0
                            }}
                            className="color-picker-component"
                          />
                        </Box>
                        <Typography variant="subtitle1">Button text </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              }
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Background Image</Typography>
              <Box sx={{ display: "flex", gap: "30px", mb: 4, mt: 3 }}>
                <Box className="PictureButton selected">
                  <img src="/assets/images/dummybuilder/backgorund_img_icon.svg" />
                </Box>
                <Box className="PictureButton">
                  <img src="/assets/images/dummybuilder/backgorund_video_icon.svg" />
                </Box>
              </Box>

              <Box className="img_thumb active">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    right: "-8px",
                    background: "#000",
                    color: "#fff",
                  }}
                >
                  <Icon.CameraAltOutlined />
                </IconButton>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Block style</Typography>
              <Box sx={{ display: "flex", gap: "30px", mb: 4, mt: 3 }}>
                <Box className={props.blockstyles.type == 1 ? "PictureButton selected" : "PictureButton"} onClick={() => handleChange(1)} >
                  <img src="/assets/images/dummybuilder/block_style_square.svg" />
                </Box>
                 <Box className={props.blockstyles.type == 2 ? "PictureButton selected" : "PictureButton"} onClick={() => handleChange(2)} >
                  <img src="/assets/images/dummybuilder/block_style_round.svg" />
                </Box>
               <Box className={props.blockstyles.type == 3 ? "PictureButton selected" : "PictureButton"} onClick={() => handleChange(3)} >
                  <img src="/assets/images/dummybuilder/block_style_full.svg" />
                </Box>
                <Box className={props.blockstyles.type == 4 ? "PictureButton selected" : "PictureButton"} onClick={() => handleChange(4)} >
                  <img src="/assets/images/dummybuilder/block_style_radius.svg" />
                </Box>
              </Box>
              {props.blockstyles.type == 4 && 
              <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Corner radius</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  value={cornerRadius}
                  onChange={handleChangeDiscount}
                  valueLabelDisplay="auto"
                // getAriaValueText={valuetext}
                />
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">{cornerRadius}PX</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  sx={{ width: "100px", marginLeft: 2 }}
                />
              </Box>
              </Box>
              }

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Block shadow</Typography>
                <Switch
                  checked={props.blockstyles.block_shadow}
                  onChange={(e) => handleCheck(e, "block_shadow")}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Outline border</Typography>
                <Switch
                  checked={props.blockstyles.outline}
                  onChange={(e) => handleCheck(e, "outline")}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Choose Font</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "30px",
                  mb: 4,
                  mt: 3,
                }}
              >
                <Box
                  className={props.selectedFont.id == 1 ? "PictureButton selected" : "PictureButton" }
                  sx={{ maxWidth: "130px" }}
                  onClick={() => props.setFont(props.customfonts[0])}
                >
                   <div style={{ mb: 1, fontFamily:"Poppins, sans-serif" }}>Poppins</div>
                </Box>
                <Box className={props.selectedFont.id == 2 ? "PictureButton selected" : "PictureButton" } sx={{ maxWidth: "130px" }}  onClick={() => props.setFont(props.customfonts[1])}>
                <div style={{ mb: 1, fontFamily:"Lemon, sans-serif" }}>Lemon</div>
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                <div style={{ mb: 1, fontFamily:"Inter, sans-serif" }}>Inter</div>
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                <div style={{ mb: 1, fontFamily:"Comic Neue, sans-serif" }}>Comic+Neue</div>
                 
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>

      </Grid>

    </>
  );
};


export default Design;
