import { Box, Divider, IconButton, TextField, Typography, Paper, Grid, Button, FormControl, InputLabel, MenuItem, ListItemText, Select, OutlinedInput } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { extractDataFromArray } from "../../../utils/common";
import { useState, useRef } from "react";
import FileInput from "./fileInput";
import ImageCropper from "./imageCropper";
import { Link } from "react-router-dom";

let croppedImage = "";
let setCroppedImage = "";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const socialIcons = [
  {value:"facebook", icon:"/assets/images/builder/phone_outline_img.svg"}
]

const Header = ({ data, updateData }) => {
  const [socialIcon, setsocialIcon] = useState(null);

  const [shortcutRows, setshortcutRows] = useState({
    id: null,
    type: "" ,
    shortcut_link: "",
    img_link: "",
  });
  const newsocial =  {
    id: null,
    type: socialIcon ,
    shortcut_link: "",
    img_link: "",
  };

  const setIcons = () => {

  }

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    // setsocialIcon(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setsocialIcon(
     
      value.value
    );
    shortcutRows.type =  value.value;
    setshortcutRows(shortcutRows);
    console.log("shortcutRows1", shortcutRows);
    addMoreShortcuts(shortcutRows);
  };

    const [componentDetails, setComponentDetails] = useState(data)
    const updateComponentDetails = (data) => {
        setComponentDetails({ ...componentDetails, ...data })
        updateData({ ...componentDetails, ...data })
    }

    const [addShortcut, setAddShortcut] = useState(false);


    const getIcons = (shortcut) => {
switch (shortcut.type) {
  case "facebook": return <img src="/assets/images/builder/phone_outline_img.svg"></img>;
  case "instagram": return <img src="/assets/images/builder/phone_outline_img.svg"></img>;
  case "twitter": return <img src="/assets/images/builder/phone_outline_img.svg"></img>;
  case "email": return <img src="/assets/images/builder/phone_outline_img.svg"></img>;
    

    // break;

  default:
    break;
}
    }
   

      

      const initShortCutData = {
        id: null,
        type: "",
        shortcut_link: "",
        img_link: "",
      };

      

      const addMoreShortcuts = (row) => {
        console.log("row", row);
        const updatedData = [...componentDetails.shortcuts];
      const newsocial =  {
          id: null,
          type: row.type ,
          shortcut_link: "",
          img_link: "",
        };
        newsocial.type = socialIcon;
        console.log("newsocial",newsocial)
        // setshortcutRows({...shortcutRows, newsocial});
        updatedData.push(shortcutRows);
        console.log("updatedDatashortcut",updatedData)
        updateComponentDetails({ ...componentDetails, shortcuts: updatedData });
        setAddShortcut(false);
       setshortcutRows(initShortCutData);
      };

      const handleInputChange = (index, newValue, key) => {
        // Create a copy of the data array
        const updatedData = [...componentDetails.shortcuts];
        // Update the value for the specific item
        updatedData[index][key] = newValue;
        // Update the state with the new data
        updateComponentDetails({ ...componentDetails, shortcuts: updatedData });
      };

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const inputRef = useRef();
    const [isFileInputOpen, setFileInputOpen] = useState(false);
    const [isImageCropperOpen, setImageCropperOpen] = useState(false);
    const [croppedImage2, setCroppedImage2] = useState(null);
    croppedImage = croppedImage2;
    setCroppedImage = setCroppedImage2;

    const [files, setFiles] = useState([]);

    const handleImageSelected = (imageData) => {


        setSelectedImage(imageData);

        if (imageData) {
            console.log("Creating URL for Image Data");
            try {
                const imageUrl = URL.createObjectURL(imageData);
                console.log("Image URL:", imageUrl);
                setImageUrl(imageUrl);

            } catch (error) {
                console.error("Error creating URL for Image Data:", error);
            }
        }

        setFileInputOpen(false);
        setImageCropperOpen(true);

    };

    const onChooseImg = () => {
        inputRef.current.click();
    };

    const onCropDone = (croppedImageData) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = croppedImageData.width;
        canvasEle.height = croppedImageData.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = selectedImage;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                croppedImageData.x,
                croppedImageData.y,
                croppedImageData.width,
                croppedImageData.height,
                0,
                0,
                croppedImageData.width,
                croppedImageData.height
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");

            const uploadedImage = dataURL 
            console.log("uploadedImage", uploadedImage);
            const thumbFiles = []
            thumbFiles.push(uploadedImage);
            console.log("setFiles", setFiles);
            const backUpTempFiles = []
            backUpTempFiles.push(uploadedImage);

            setCroppedImage(dataURL);
            setFiles(thumbFiles);

            updateComponentDetails({ ...componentDetails, brand_logo: uploadedImage });

            setImageCropperOpen(false);


        };
    };

    const handleChange = (newValue) => {
        setshortcutRows({ ...shortcutRows, ...newValue });
      };
      const deleteLink = (index) => {
        const updatedData = [...componentDetails.shortcuts];
        updatedData.splice(index, 1);
        updateComponentDetails({ ...componentDetails, shortcuts: updatedData });
      };



    
    return (<>
        <Box className="img_uploader_1" sx={{ mb: "24px !important", backgroundImage: 'url(' + extractDataFromArray(componentDetails, ['brand_logo'], '/assets/images/builder/brand_logo_blank_1.png') + ')' }}>
            {/* <IconButton className='img_uploader_1_upload'>
                <Icon.CameraAltOutlined />
            </IconButton> */}
            <div style={{position: "absolute",
    bottom: "10px",
    right: "0px"}}>
            <FileInput
                        onImageSelected={handleImageSelected}
                        chooseImage={onChooseImg}
                        reference={inputRef}
                    
                    />
                    </div>

                    {isImageCropperOpen && (
                        <ImageCropper
                            image={selectedImage}
                            onCropDone={onCropDone}
                            onCropCancel={() => setImageCropperOpen(false)}
                        />
                    )}
        </Box>
        <TextField
            fullWidth
            id="brandname"
            label="Brand Name"
            variant="outlined"
            value={extractDataFromArray(componentDetails, ['brand_name'], '')}
            onInput={(e) => { updateComponentDetails({ brand_name: e.target.value }) }}
            sx={{ mb: 4, background: '#fff !important' }}
        />

        <Typography variant='subtitle2' sx={{fontWeight:"bold", mb:1}} >Logo shape</Typography>
        <Box sx={{ display: 'flex', gap: '30px' }}>
            <Box className={`${componentDetails.logo_shape == "circle" ? "PictureButton selected" : "PictureButton"}`}  onClick={() => { updateComponentDetails({ logo_shape: "circle" }) }}>
                <img src='/assets/images/builder/circle_shape.svg' />
            </Box>
            <Box className={`${componentDetails.logo_shape == "square" ? "PictureButton selected": "PictureButton"}`}  onClick={() => { updateComponentDetails({ logo_shape: "square" }) }}>
                <img src='/assets/images/builder/square_shape.svg' />
            </Box>
        </Box>
        <Divider sx={{ mt: 4, mb: 4 }} />
        <Typography variant='subtitle2' sx={{fontWeight:"bold", mb:1}}>Format</Typography>
        <Box sx={{ display: 'flex', gap: '30px' }}>
            <Box className={`${componentDetails.format == "inline" ? "PictureButton selected" : "PictureButton"}`}  onClick={() => { updateComponentDetails({ format: "inline" }) }} >
                <img src='/assets/images/builder/format_1.svg' />
            </Box>
            <Box className={`${componentDetails.format == "block" ? "PictureButton selected" : "PictureButton"}`} onClick={() => { updateComponentDetails({ format: "block" }) }}>
                <img src='/assets/images/builder/format_2.svg' />
            </Box>
        </Box>
        <Divider sx={{ mt: 4, mb: 4 }} />
        <Typography variant='subtitle2' sx={{fontWeight:"bold", mb:1}}>Shortcuts</Typography>
        {/* {componentDetails.shortcuts && componentDetails.shortcuts.map((shortcut) => (
 <Box className="grey_bg" sx={{ display: 'flex', gap: '10px', alignItems: 'center', p: 3, mb: 2, borderRadius: '8px' }}>
 <IconButton>
     <Icon.DragIndicatorOutlined />
 </IconButton>
 <img src='/assets/images/builder/phone_outline_img.svg' />
 <TextField fullWidth id="phonenumber" label="phone number" variant="outlined"  sx={{ background: "#fff !important" }}/>

 <IconButton>
     <Icon.DeleteOutline />
 </IconButton>
</Box>
        ))} */}

{componentDetails.shortcuts && componentDetails.shortcuts.map((shortcut, index) => (
            <Paper
              elevation={0}
              sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
            >
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  md={12}
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  <IconButton>
                    <Icon.DragIndicatorOutlined />
                  </IconButton>
                  <Box sx={{ mr: 2, ml: 2 }}>
                   
                    <div  style={{
                        height: "3rem",
                        aspectRatio: 1,
                        borderRadius: "50%",
                      }}>
                       {getIcons(shortcut)}
             
              </div>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          key={index}
                          name="heading"
                          id="heading"
                          label="url"
                          variant="outlined"
                          value={shortcut.shortcut_link}
                          // value={linkData.heading}
                          //  onInput={(e) => { updateComponentDetails({ heading: e.target.value }) }}
                          // onChange= {updateData1(index)}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value, "shortcut_link")
                          }
                          sx={{ background: "#fff !important" }}
                        />
                      </Grid>
                     
                     
                    </Grid>
                  </Box>
                  <IconButton sx={{ ml: 2 }} onClick={() => deleteLink(index)}>
                    <Icon.DeleteOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}


      

        {addShortcut  ? 
    <Paper
    elevation={0}
    sx={{ p: 2, background: "#F7F8F3", borderRadius: "8px", mb: 2 }}
  >
    <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={false}
          value={socialIcon}
          onChange={handleSelect}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
        >
          {socialIcons.map((socialicon) => (
            <MenuItem key={socialicon} value={socialicon}>
              {/* <Checkbox checked={socialIcon.indexOf(name) > -1} /> */}
              
              <img src={getIcons(socialicon)} style={{width:"24px", height:"24px", marginRight:"16px"}}></img>
              <ListItemText primary={socialicon.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    {/* <Grid container sx={{ alignItems: "center" }}>
      <Grid
        item
        md={12}
        sx={{ alignItems: "center", display: "flex" }}
      >
       
        <FileInput
          onImageSelected={handleImageSelected}
          chooseImage={onChooseImg}
          reference={inputRef}
        />

        {isImageCropperOpen && (
          <ImageCropper
            image={selectedImage}
            onCropDone={onCropDone}
            onCropCancel={() => setImageCropperOpen(false)}
          />
        )}
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                name="heading"
                id="heading"
                label=""
                variant="outlined"
                value={shortcutRows.shortcut_link}
                onChange={(e) =>
                  handleChange({ shortcut_link: e.target.value })
                }
                sx={{ background: "#fff !important" }}
              />
            </Grid>
          
           
          </Grid>
        </Box>
      </Grid>
    </Grid> */}
    <div >
      <Button
        variant="contained"
        fullWidth
        sx={{ color: "#fff", mt: 2, borderRadius: "16px" }}
        onClick={addMoreShortcuts}
      >
        Add
      </Button>
    </div>
  </Paper>
 :<Box sx={{ textAlign: 'center' }}>
 <IconButton className='black_btn' onClick={() => setAddShortcut(true)}>
     <Icon.Add />
 </IconButton>
</Box>
    }

        
    </ >
    )
}

const HeaderPreview = ({ data, theme, component, customfont, blockstyles }) => {

  const getSocialIcons = (shortcut) => {
    switch (shortcut.type) {
      case "email": return <Icon.EmailOutlined />;
      case "twitter": return <Icon.Twitter />;
      case "instagram": return <Icon.Instagram />;
      case "facebook": return <Icon.FacebookOutlined />;
        
    
        // break;
    
      default:
        break;
    }
        }
    
    return (
<Box>
{!component.disable ?
        <Box className="mob_prev_hdr">
            <Box className="mob_prev_hdr_top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: data.format == "inline" ? "row" : "column-reverse" }}>
                <Box className="mob_prev_hdr_logo" sx={{ display: 'flex', alignItems: 'center', flexDirection: data.format == "inline" ? "row" : "column" }}>
                    <Box className="logo_img" sx={data.logo_shape == "circle" ? {borderRadius:"100px"} : {borderRadius:"0px"}}>
                        <img src={data.brand_logo} style={{width:"100%", height:"100%", borderRadius: data.logo_shape == "circle" ? "100px" : "0px"}} />
                    </Box>

                    <Typography className="brand_name" sx={{ ml: data.format == "inline" ? 2 : 0 , color: theme.colors.heading.text_color, fontFamily:customfont.font_family}}>
                        {extractDataFromArray(data, ['brand_name'], '')}
                    </Typography>
                </Box>
                <Box sx={ data.format == "inline" ? {} : {justifyContent:"flex-end", width:"100%", textAlign:"right"}}>
                <IconButton>
                    <Icon.MenuOutlined />
                </IconButton>
                </Box>
            </Box>
            <Divider sx={{ mt: 1, mb: 2, borderColor: theme.colors.heading.text_color }} />
            <Box className="mob_prev_shortcut">
                {data.shortcuts && data.shortcuts.map((shortcut) => (
                  <Link to={shortcut.shortcut_link}>
                    {shortcut.shortcut_link && 
               <div>   {getSocialIcons(shortcut)} 
               </div>
                }
                  </Link>
                ))}
                
               
            </Box>
        </Box>
        : null }
        </Box>
    )
}

export { Header, HeaderPreview };