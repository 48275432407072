import { Box, Button, ButtonGroup, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { extractDataFromArray } from "../../../utils/common";
import { useState } from "react";
const AboutUs = ({ data, updateData }) => {
    const [componentDetails, setComponentDetails] = useState(data)
    const updateComponentDetails = (data) => {
        setComponentDetails({ ...componentDetails, ...data })
        updateData({ ...componentDetails, ...data })
    }
    return (
        <>
            <TextField
                fullWidth
                value={extractDataFromArray(componentDetails, ['title'], '')}
                onInput={(e) => { updateComponentDetails({ 'title': e.target.value }) }}
                id="brandname" label="Heading" variant="outlined" sx={{ mb: 4, background: '#fff !important' }} />
            <TextField
                multiline
                rows={3}
                value={extractDataFromArray(componentDetails, ['desc'], '')}
                onInput={(e) => { updateComponentDetails({ 'desc': e.target.value }) }}
                fullWidth id="brandname" label="Description" variant="outlined" sx={{ mb: 4, background: '#fff !important' }} />


            <Divider sx={{ mb: 4 }} />
            <Grid container>
                <Grid item md={6}>
                    Text Alignment
                </Grid>
                <Grid item md={6} sx={{ textAlign: 'right' }}>
                    <ButtonGroup variant="outlined">
                        <Button><Icon.FormatAlignLeftOutlined onClick={() => updateComponentDetails({ 'format': "left" }) } /></Button>
                        <Button><Icon.FormatAlignCenterOutlined onClick={() => updateComponentDetails({ 'format': "center" })} /></Button>
                        <Button><Icon.FormatAlignRightOutlined onClick={() => updateComponentDetails({ 'format': "right" })} /></Button>
                    </ButtonGroup></Grid>
            </Grid>
        </>
    )
}

const AboutUsPreview = ({ data, theme, component, customfont, blockstyles }) => {
    return (
        <Box>
            {!component.disable ?
        <Box className="mob_prev_heading" sx={{ mt: 3, mb: 3, p:2, borderRadius: blockstyles.type == 1 ? "0px" : blockstyles.type == 2 ? "16px" : blockstyles.type == 3 ? "0px" : `${blockstyles.corner_radius}px`, background: theme.colors.block.background_color }}>
            <Typography variant='h3' className='mob_prev_heading_h3' sx={{ mb: 2, textAlign:data.format, color: theme.colors.block.text_color, fontFamily:customfont.font_family }}>
                {extractDataFromArray(data, ['title'], '')}
            </Typography>
            <Typography sx={{textAlign:data.format, color: theme.colors.block.text_color, fontFamily:customfont.font_family}}>
                {extractDataFromArray(data, ['desc'], '')}
            </Typography>
        </Box>
        : null 
        }
        </Box>
    )
}

export { AboutUs, AboutUsPreview };