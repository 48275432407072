import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Paper, Switch, Typography } from "@mui/material";

import * as Icon from '@mui/icons-material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const ImageGallery = () => {
    return (
        <>

            <Paper sx={{ mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{xs:2, md:3}}>
                    <Box className="pagecomp_header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ mr: 2 }} >
                                <Icon.ArrowBack />
                            </IconButton>
                            Photo Gallery
                        </Box>
                        <Box>
                            {/* <Icon.MoreVert sx={{ ml: 2 }} /> */}
                            <Switch {...label} defaultChecked />
                            <IconButton sx={{ ml: 2 }} >
                                <Icon.DeleteOutline />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="pagecomp_content">

                        <Box className="file_uplaoder" sx={{ mb: 3 }}>
                            <IconButton>
                                <Icon.FileUploadOutlined />
                            </IconButton>
                        </Box>
                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item fullWidth sx={{ alignItems: 'center', display: 'flex' }}>
                                    <IconButton>
                                        <Icon.DragIndicatorOutlined />
                                    </IconButton>
                                    <Box sx={{ width: '48px', height: '48px', backgroundImage: 'url(/assets/images/dummybuilder/image_gallery_1.png)', backgroundSize: 'cover', borderRadius: '8px' }}></Box>

                                    <Typography sx={{ ml: 2 }}>filname.png</Typography>
                                </Grid>
                                <Grid item sx={{ ml: 'auto' }}>
                                    <IconButton sx={{ ml: 2 }} >
                                        <Icon.DeleteOutline />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item fullWidth sx={{ alignItems: 'center', display: 'flex' }}>
                                    <IconButton>
                                        <Icon.DragIndicatorOutlined />
                                    </IconButton>
                                    <Box sx={{ width: '48px', height: '48px', backgroundImage: 'url(/assets/images/dummybuilder/image_gallery_1.png)', backgroundSize: 'cover', borderRadius: '8px' }}></Box>
                                    <Typography sx={{ ml: 2 }}>filname.png</Typography>
                                </Grid>
                                <Grid item sx={{ ml: 'auto' }}>
                                    <IconButton sx={{ ml: 2 }} >
                                        <Icon.DeleteOutline />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Typography variant='h6'>Format</Typography>
                        <Box sx={{ display: 'flex', gap: '30px' }}>
                            <Box className="PictureButton selected">
                                <img src='/assets/images/dummybuilder/photo_gallery_format_1.svg' />
                            </Box>
                            <Box className="PictureButton">
                                <img src='/assets/images/dummybuilder/photo_gallery_format_2.svg' />
                            </Box>
                        </Box>



                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>

        </>
    );
};


export default ImageGallery;