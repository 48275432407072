import { useRoutes } from 'react-router-dom';
import BaseRoutes from './LandingPageRoutes';

// routes
import DashboardRoutes from './DashboardRoutes';
import BasicRoutes from './BasicRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([BaseRoutes, DashboardRoutes, BasicRoutes ]);
}