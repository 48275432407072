import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { UserAuth } from "../../../../store/context/user";

// project imports
import * as Icon from "@mui/icons-material";
import MainCard from "./PopupCard";
import Transitions from "./Transition";
import { extractDataFromArray } from "../../../../utils/common";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  let { user } = UserAuth;

  const theme = useTheme();

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Logout");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "18px",
          background: "#FFFFFF",
          borderRadius: 70,
          paddingLeft: 0,
          paddingRight: "16px",
          cursor: "pointer",
        }}
        onClick={handleToggle}
      >
        <Avatar
          src={extractDataFromArray(user, ["pro_pic"], "")}
          sx={{
            ...theme.typography.mediumAvatar,
            margin: "8px 0 8px 8px !important",
            cursor: "pointer",
          }}
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          color="inherit"
        />
        <SettingsOutlinedIcon />
      </div>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1">Hi,</Typography>
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{ fontWeight: 400 }}
                        >
                          {extractDataFromArray(user, ["name"], "Danny")}
                        </Typography>
                      </Stack>
                      {/* <Typography variant="subtitle2">Project Admin</Typography> */}
                    </Stack>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2, paddingTop: 0 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 250,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) =>
                          handleListItemClick(event, 0, "/settings")
                        }
                      >
                        <ListItemIcon>
                          <Icon.Settings stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              Account Settings
                            </Typography>
                          }
                        />
                      </ListItemButton>

                      <ListItemButton
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <Icon.LogoutOutlined stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Logout</Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
