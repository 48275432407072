import { ProtectedRoute } from "./protectedRoutes";
//layout
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/dashboard";
import Getstarted from "../pages/getstarted";
import Business from "../pages/business";
import DummyBuilder from "../pages/dummybuilder";


const DashboardRoutes = {
  path: "/",
  element: (
    <ProtectedRoute>
      <DashboardLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
      ],
    },

    {
      path: "/getstarted",
      children: [
        {
          path: "",
          element: <Getstarted />,
        },
      ],
    },
    {
      path: "/business",
      children: [
        {
          path: "",
          element: <Business />,
        },
      ],
    },

    {
      path: "/dummybuilder",
      children: [
        {
          path: "",
          element: <DummyBuilder />,
        },
      ],
    },
  ],
};

export default DashboardRoutes;
