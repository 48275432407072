import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Paper, Switch, TextField, Typography } from "@mui/material";

import * as Icon from '@mui/icons-material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const Links = () => {
    return (
        <>

            <Paper sx={{ mt: 2, mb: 2 }}>
                <Box className="pagecomp_detail" p={{xs:2, md:3}}>
                    <Box className="pagecomp_header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ mr: 2 }} >
                                <Icon.ArrowBack />
                            </IconButton>
                            Links
                        </Box>
                        <Box>
                            {/* <Icon.MoreVert sx={{ ml: 2 }} /> */}
                            <Switch {...label} defaultChecked />
                            <IconButton sx={{ ml: 2 }} >
                                <Icon.DeleteOutline />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="pagecomp_content">




                        <Paper elevation={0} sx={{ p: 2, background: '#F7F8F3', borderRadius: '8px', mb: 2 }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={12} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <IconButton>
                                        <Icon.DragIndicatorOutlined />
                                    </IconButton>
                                    <Box sx={{ mr: 2, ml: 2 }}>
                                        <img src="/assets/images/dummybuilder/instagram.svg" />
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth id="heading" label="Heading" variant="outlined" sx={{ background: '#fff !important'}} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField fullWidth id="description" label="Description" variant="outlined" sx={{ background: '#fff !important'}} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField fullWidth id="link" label="Link" variant="outlined" sx={{ background: '#fff !important' }} />
                                            </Grid>

                                        </Grid>
                                        {/* <Box sx={{ display: 'flex', gap: 2 }}>
                                            <TextField fullWidth id="heading" label="Heading" variant="outlined" sx={{ background: '#fff !important', mb: 2 }} />
                                            <TextField fullWidth id="description" label="Description" variant="outlined" sx={{ background: '#fff !important', mb: 2 }} />
                                        </Box>
                                        <TextField fullWidth id="link" label="Link" variant="outlined" sx={{ background: '#fff !important' }} /> */}
                                    </Box>
                                    <IconButton sx={{ ml: 2 }} >
                                        <Icon.DeleteOutline />
                                    </IconButton>

                                </Grid>
                            </Grid>
                        </Paper>
                        <Box sx={{ textAlign: 'center' }}>
                            <IconButton>
                                <Icon.Add />
                            </IconButton>
                        </Box>

                        <Divider sx={{ mt: 4, mb: 4 }} />
                        <Typography variant='h6'>Format</Typography>
                        <Box sx={{ display: 'flex', gap: '30px' }}>
                            <Box className="PictureButton selected">
                                <img src='/assets/images/dummybuilder/link_format_1.svg' style={{maxWidth:'100%'}} />
                            </Box>
                            <Box className="PictureButton">
                                <img src='/assets/images/dummybuilder/link_format_2.svg' style={{maxWidth:'100%'}} />
                            </Box>
                            <Box className="PictureButton">
                                <img src='/assets/images/dummybuilder/link_format_3.svg' style={{maxWidth:'100%'}} />
                            </Box>
                        </Box>





                    </Box>
                    <Box className="pagecomp_ftr"></Box>
                </Box>
            </Paper>

        </>
    );
};


export default Links;