
import LandingPageLayout from "../layouts/LandingPageLayout";
import ContactUs from "../pages/contactus";
import AboutUs from "../pages/aboutus";
import Home from "../pages/home";
import Templates from "../pages/templates";
import Pricing from "../pages/pricing";

const LandingPageRoutes = {
    path: '/',
    element: <LandingPageLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/aboutus',
            element: <AboutUs />
        },
        {
            path: '/pricing',
            element: <Pricing />
        },
        {
            path: '/contactus',
            element: <ContactUs />
        },
        {
            path: '/templates',
            element: <Templates />
        },
        
     ]
};

export default LandingPageRoutes;


