import { Box, Button, Chip, Container, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
const AboutUs = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 2, px:5 }}
      >
      <Box sx={{textAlign:'center'}}>
      <Typography variant="h3" sx={{fontWeight:"500", mb:3}}>We’re passionate<br/> about business growth</Typography>
      <Typography variant="h5" sx={{mb:5}} >We are dedicated to a mission of enabling both individuals and organizations to confidently express their unique identities and expand their online presence.</Typography>
      <Box sx={{mb:10}}><img src="/assets/images/aboutus/aboutus_spurtme.jpg" style={{maxWidth:'100%'}}/></Box>
      </Box>
      <Box sx={{textAlign:'center', mb:10}}>
      <Typography variant="h3" sx={{fontWeight:'500', mb:3}} >Spurt Growth is at the core of our mentality.</Typography>
      <Typography variant="h6">
      Our aim is to revolutionize the way people achieve success online, beginning with their business-driven growth. Yet, for us, this is just the beginning.<br/><br/>

We nurture our own growth through our website and foster a culture that inspires individuals to advance their careers by embracing fresh challenges.

      </Typography>
      </Box>
      <Box sx={{textAlign:'center', mb:10}}>
      <Typography variant="h3" sx={{fontWeight:'500', mb:3}} >
      What we’re building
      </Typography>
      <Typography variant="h6">
      We are creating a new standard for bio link websites — a tool that empowers teams to rapidly build 
      and iterate on stunning business pages, QR Codes, without compromising on performance. How? By building two interconnected tools that work together to change the way developers build and marketers create.
      </Typography>
      </Box>
      <Box sx={{background:"#F7F8F3", p:10, textAlign:'center', mb:10, borderRadius:4}}>
        <Typography variant="h3" sx={{fontWeight:'500', mb:4}}>
         We believe all business owner <br/>deserve spurt growth
        </Typography>
        <Typography variant="h6" sx={{mb:4}}>Ready to power up your growth engine?<br/>
You’re just one click away – try The SpurtMe for free.</Typography>
        <Button  className="button_dark" variant="contained">Get Started</Button>
      </Box>
      <Box></Box>
      <Box></Box>


      </Container>
    </>
  );
};

export default AboutUs;
