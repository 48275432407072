import * as React from 'react';

import { Box, Grid, Toolbar } from "@mui/material";
import Header from "./Header";
import ComponentBuilder from "./components";
import { Outlet } from "react-router-dom";

const Builder = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
   
    return (
        <>
            <Header value={value} handleChange={handleChange}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    backgroundColor: "#F7F8F3"
                }}
            >
                <Toolbar />
                <Grid container maxWidth="lg" sx={{ m: "auto", }}>
                    <Grid item md={12} sm={12} xs={12} sx={{  paddingTop: 5,  }}>
                        <ComponentBuilder value={value} />
                        
                    </Grid>
                </Grid>    
            </Box>
        </>
    )
}

export default Builder;