
import BasicLayout from "../layouts/BasicLayout";
import Builder from "../pages/builder";
import ComponentBuilder from "../pages/builder/components"
// import Design from "../pages/builder/design";

const BasicRoutes = {
    path: '/',
    element: <BasicLayout />,
    children: [
        {
            path: '/builder',
            element: <Builder />,
            children: [
                {
                    path: 'content',
                    element: <ComponentBuilder/>,
                },
               
             ]
        },
     ]
};

export default BasicRoutes;


