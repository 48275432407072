const LocalStorage = {
    set : (key, value) =>{
        if(typeof value === 'object'){
            value = JSON.stringify(value)
        }
        window.localStorage.setItem(key, value)
    },
    get : (key, type = "string") =>{
        let value = window.localStorage.getItem(key)

        if (type === 'object'){
            return JSON.parse(value)
        }

        return value
    },

}

export default LocalStorage;