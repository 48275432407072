import * as React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Slider,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import InputColor from "react-input-color";

import * as Icon from "@mui/icons-material";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const PageStyle = () => {
  const [value, setValue] = React.useState(0);

  const [color, setColor] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Paper sx={{ mt: 2, mb: 2 }}>
        <Box className="pagecomp_detail" p={{ xs: 2, md: 3 }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Page" />
            <Tab label="QR Code" />
          </Tabs>
        </Box>
      </Paper>

      {value == 0 && (
        <>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6">Themes</Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box className="img_thumb">
                  <img src="/assets/images/dummybuilder/scratchcard_2.png" />
                </Box>
                <Box className="img_thumb">
                  <img src="/assets/images/dummybuilder/scratchcard_3.png" />
                </Box>
                <Box className="img_thumb active">
                  <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                </Box>
                <Box className="img_thumb">
                  <img src="/assets/images/dummybuilder/scratchcard_4.png" />
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6">Colors</Typography>
              <Box sx={{ mt: 3, display: "flex", flexWrap: "wrap" }}>
                <Box className="color_list">
                  ;
                  <Box
                    className="color_circle"
                    sx={{ background: "#000" }}
                  ></Box>
                  <Typography variant="caption">Header text</Typography>
                </Box>
                <Box className="color_list">
                  <Box className="color_circle"></Box>
                  <Typography variant="caption">
                    Block <br />
                    Backgorund
                  </Typography>
                </Box>
                <Box className="color_list">
                  <Box
                    className="color_circle"
                    sx={{ background: "#000" }}
                  ></Box>
                  <Typography variant="caption">
                    Block <br />
                    Text
                  </Typography>
                </Box>
                <Box className="color_list">
                  <Box
                    className="color_circle"
                    sx={{ background: "#000" }}
                  ></Box>
                  <Typography variant="caption">
                    Link <br />
                    Backgorund
                  </Typography>
                </Box>
                <Box className="color_list">
                  <Box className="color_circle"></Box>
                  <Typography variant="caption">
                    Link <br />
                    Text
                  </Typography>
                </Box>
                <Box className="color_list">
                  <Box className="color_circle"></Box>
                  <Typography variant="caption">
                    Button <br />
                    Backgorund
                  </Typography>
                </Box>

                <Box className="color_list">
                  <Box className="color_circle"></Box>
                  <Typography variant="caption">
                    Button <br />
                    Text
                  </Typography>
                </Box>

                <Box className="color_list">
                  <Box
                    className="color_circle"
                    sx={{ background: "#EAFF00" }}
                  ></Box>
                  <Typography variant="caption">
                    Background <br />
                    Color
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6">Background Image</Typography>
              <Box sx={{ display: "flex", gap: "30px", mb: 4, mt: 3 }}>
                <Box className="PictureButton selected">
                  <img src="/assets/images/dummybuilder/backgorund_img_icon.svg" />
                </Box>
                <Box className="PictureButton">
                  <img src="/assets/images/dummybuilder/backgorund_video_icon.svg" />
                </Box>
              </Box>

              <Box className="img_thumb active">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    right: "-8px",
                    background: "#000",
                    color: "#fff",
                  }}
                >
                  <Icon.CameraAltOutlined />
                </IconButton>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6">Block style</Typography>
              <Box sx={{ display: "flex", gap: "30px", mb: 4, mt: 3 }}>
                <Box className="PictureButton selected">
                  <img src="/assets/images/dummybuilder/block_style_square.svg" />
                </Box>
                <Box className="PictureButton">
                  <img src="/assets/images/dummybuilder/block_style_round.svg" />
                </Box>
                <Box className="PictureButton">
                  <img src="/assets/images/dummybuilder/block_style_full.svg" />
                </Box>
                <Box className="PictureButton">
                  <img src="/assets/images/dummybuilder/block_style_radius.svg" />
                </Box>
              </Box>
              <Typography variant="h6">Corner radius</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Slider
                  defaultValue={50}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  sx={{ mr: 2 }}
                />
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">PX</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography variant="h6">Block shadow</Typography>
                <Switch {...label} defaultChecked />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography variant="h6">Outline border</Typography>
                <Switch {...label} defaultChecked />
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6">Fonts</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "30px",
                  mb: 4,
                  mt: 3,
                }}
              >
                <Box
                  className="PictureButton selected"
                  sx={{ maxWidth: "130px" }}
                >
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
                <Box className="PictureButton" sx={{ maxWidth: "130px" }}>
                  <img src="/assets/images/dummybuilder/font_1.svg" />
                </Box>
              </Box>
            </Box>
          </Paper>
        </>
      )}
      {value == 1 && (
        <>
        
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{mb:3}}>QR code title</Typography>
              <TextField
                fullWidth
                type="number"
                id="offer_heading"
                label="Scan for Offer / Payment"
                variant="outlined"
                sx={{ background: "#fff !important" }}
              />
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Brand color</Typography>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 40,
                    //   backgroundColor: color.rgba,
                    border: "solid 1px #000",
                  }}
                >
                  <InputColor
                    initialValue="#5e72e4"
                    onChange={setColor}
                    placement="right"
                    style={{
                      width: 38,
                      height: 38,
                      borderRadius: 40,
                      border:"none",
                      padding:0
                    }}
                    className="color-picker-component"
                  />
                </div>
              </div>
            </Box>
            
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Text color</Typography>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 40,
                    //   backgroundColor: color.rgba,
                    border: "solid 1px #000",
                  }}
                >
                  <InputColor
                    initialValue="#5e72e4"
                    onChange={setColor}
                    placement="right"
                    style={{
                      width: 38,
                      height: 38,
                      borderRadius: 40,
                      border:"none",
                      padding:0
                    }}
                    className="color-picker-component"
                  />
                </div>
              </div>
            </Box>
            </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
            <Typography variant="h6" sx={{mb:3}}>Brand logo</Typography>
            <Box className="img_thumb active">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    right: "-8px",
                    background: "#000",
                    color: "#fff",
                  }}
                >
                  <Icon.CameraAltOutlined />
                </IconButton>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, p: 3, mb: 2 }}>
            <Box>
            <Typography variant="h6" sx={{mb:3}}>Backgorund Image</Typography>
            <Box className="img_thumb active">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    right: "-8px",
                    background: "#000",
                    color: "#fff",
                  }}
                >
                  <Icon.CameraAltOutlined />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", mb: 4, mt: 3 }}>
                <Box className="img_thumb">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                </Box>
                <Box className="img_thumb">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                </Box>
                <Box className="img_thumb">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                </Box>
                <Box className="img_thumb">
                <img src="/assets/images/dummybuilder/scratchcard_1.png" />
                </Box>
              </Box>
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

export default PageStyle;
