import { Box, Button, Chip, Container, Grid, Link, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
const ContactUs = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 2, px:5 }}
      >
      <Box sx={{textAlign:'center', mb:10}}>
      <Typography variant="h3" sx={{fontWeight:"bold", mb:3}}>Contact SpurtMe</Typography>
      <Typography variant="h5" sx={{mb:5}} >Questions? Inquiries? Just wanna say hey? Anytime! Fill out the form below or email us at <Link href="mailto:mailto:llo@spurtme.com">hello@spurtme.com</Link>. Either way, we’ll send back a friendly message ASAP.</Typography>
      

      </Box>
      <Box sx={{textAlign:'center', mb:10}}>
        <img src="/assets/images/contacus/contactus.jpg" alt="contactus" style={{maxWidth:'100%'}}/>
        </Box>
      
      


      </Container>
    </>
  );
};

export default ContactUs;
