import { createContext, useContext, useState } from 'react';
import { empty } from '../../utils/common';
import LocalStorage from '../../utils/localStorage';

const defaultUser = {
    user : null,
    login : () => null,
    logout : () => null,
    register : () => null,
    isLoggedIn : () => null
}

const userContext = createContext(defaultUser);

const UserProvider = ({children}) =>{
    let userData = LocalStorage.get("userData")
    if(empty(userData)){
        LocalStorage.set("userData", defaultUser)
        userData = defaultUser
    }

    const [user, setUser] = useState(userData)

    const isLoggedIn = () => {

    }

    const login = () => {

    }

    const logout = () => {
        setUser(null)
    }

    const register = () => {

    }

    const value = {
        user,
        login,
        logout,
        register,
        isLoggedIn
    }

    return (<userContext.Provider value={value}>{children}</userContext.Provider>)
}

const UserAuth = () => {
    return useContext(userContext);
}

export {UserAuth, UserProvider}